<template>
    <div class="row margin-remove">
        <div class="col-4 text-center">
            <div><label class="margin-bottom-remove">Akunting 1 </label></div>
            <div><label>{{akunting1 }} </label></div>
            <a>
                <img v-bind:src="'/img/approved.png'" style="width:100%;max-width:150px;" alt="">
            </a>
            <div><label>{{tgl_approveakt1 }} </label></div>
        </div>
        <div class="col-4 text-center">
            <div><label class="margin-bottom-remove">Akunting 2</label></div>
            <div><label>{{akunting2 }}  </label></div>
            <a>
                <img v-bind:src="'/img/approved.png'" style="width:100%;max-width:150px;" alt="">
            </a>
            <div><label>{{tgl_approveakt2 }} </label></div>
        </div>
        <div class="col-4 text-center" v-if="this.status_pesanan==8 || this.status_pesanan==17">
            <div><label class="margin-bottom-remove">Approval </label></div>
            <div><label>{{approval }}  </label></div>
            <a  v-if="this.status_pesanan==8">
                <img v-bind:src="'/qrcode/'+this.id_sp+'.svg'" style="width:100%;max-width:100px;" alt="">
            </a>
            <a  v-if="this.status_pesanan==17">
                <img v-bind:src="'/img/reject.png'" style="width:100%;max-width:150px;" alt="">
            </a>
            <div><label>{{tgl_approvebos }}  </label></div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'tandatangan',
        props: ['id_sp','status_pesanan'],
        data () {
            return {  
                akunting1       : null,
                akunting2       : null,
                approval        : null,
                tgl_approveakt1 : null,
                tgl_approveakt2 : null,
                tgl_approvebos  : null,
            }
        },
        computed:{
            
        },
        methods: {   
            loadTTD() {
                            console.log("Load TTD "+this.id_sp+" status:"+this.status_pesanan)
                            //var id_sp   = id;
                            axios.get("../api/gettandatangan", { params: { id_sp: this.id_sp }}).then(({ data }) => {
                                            var Resp                = data.data[0];
                                            this.akunting1          = Resp.akunting1 
                                            this.akunting2          = Resp.akunting2 
                                            this.approval           = Resp.approval 
                                            this.tgl_approveakt1    = Resp.tgl_approveakt1 
                                            this.tgl_approveakt2    = Resp.tgl_approveakt2
                                            this.tgl_approvebos     = Resp.tgl_approvebos
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });   
                        },
                    },
        mounted() {
                this.loadTTD()
        }
    }
</script>
