<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
                <div class="card minh-65" v-show="!isBlock">
                    <div class="card-header">
                        <h3 class="card-title">SPPB Admin</h3>
                    </div>
                    <div class="row margin-remove">
                            <div class="col-6 p-5px">
                                <div id="TableSp_filter" class="dataTables_filter">
                                    <label>Cari :<input ref="myInput" v-model="myInputtext" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="no SPPB/no SP/toko" aria-controls="TableSp"></label>
                                    
                                </div>
                            </div>
                            <div class="col-6 p-5px text-right">
                                <label class="margin-remove">Tampil :</label>
                                <div>
                                        <select ref="optampil" @change="Cari" v-model="perrow">
                                            <option value="10">10</option>
                                            <option value="25" >25</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                        </select>
                                </div>
                            </div>
                        </div>
                    <!-- /.card-header -->
                    <div class="card-body padding-remove">
                        <table class="table table-striped table-bordered" id="TableSp">
                        <thead>
                            <tr>
                                <th data-priority="1">No. SP <a href="#" @click="short('no_sp',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                <th data-priority="4">Toko <a href="#" @click="short('nm_toko',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                <th>Tgl.SP</th>
                                <th data-priority="3">No. SPPB <a href="#" @click="short('no_sppb',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                <th>Status</th>
                                <th data-priority="2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sppbs in sppbs.data" :key="sppbs.id_sppb">
                                <td>{{sppbs.no_sp}}</td>
                                <td class="text-capitalize">{{sppbs.nm_toko}}</td>
                                <td>{{sppbs.tgl_pesanan}}</td>
                                <td>{{sppbs.no_sppb}}</td>
                                <td>{{StatusSP(sppbs.status_pesanan)}}</td>
                                <td>
                                    <router-link v-if="akses_create || akses_update" :to="{path:'/sppbtambah',query:{id: sppbs.id_sp}}" class="btn-menu"
                                        data-toggle="tooltip" data-placement="top" title="SPPB">
                                        <i class="fas fa-file-alt blue"></i>
                                    </router-link>
                                    <router-link v-show="sppbs.status_pesanan>=4 && sppbs.status_pesanan!=14 " :to="{path:'/pengiriman/'+sppbs.id_sp}" class="btn-menu"
                                        data-toggle="tooltip" data-placement="top" title="Pengiriman">
                                        <i class="fas fa-truck-moving navy"></i>
                                    </router-link>
                                    <a v-if="akses_approve && (sppbs.status_pesanan==2 || sppbs.status_pesanan==14)" class="btn-menu" @click="TolakPesanan(sppbs.id_sp,sppbs.nm_toko)" 
                                        data-toggle="tooltip" data-placement="top" title="Cancel Order">
                                        <i class="fas fa-times-circle red"></i>
                                    </a>
                                </td>
                            
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer p-5px">                   
                            <vue-paginate-al :totalPage="totalpage" :currentPage="PageAdmin" @btnClick="Cari"></vue-paginate-al>
                            <div>           
                                    <a @click="ToDashboard" type="button" class="btn btn-back">
                                                <i class="fas fa-chevron-left"></i> Kembali
                                    </a>
                            </div>
                    </div>
                </div>
                
                <div v-show="isBlock">
                    <no-akses></no-akses>
                </div>
          </div>
        </div>       
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - SPPB';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'pesanans.created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                sppbs       : {},
                akses_read  : 0,
                akses_create: 0,
                akses_update: 0,
                akses_delete: 0,
                akses_approve: 0,
                PageAdmin   : 1,
                myInputtext :"",
                isBlock         : false,
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = this.PageAdmin){
                let textuser    = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;        
                
                window.DTPageAdmin   = page; 
                window.DTrowAdmin    = perrow; 
                window.DTcariAdmin   = textuser;               
                
                axios.get('api/sppbadmin?page='+page+'&cari='+textuser+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.sppbs = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            }).catch(function(error) {
                                                        console.log(error)
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                                    }
                                                });
                    
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableSp' ) ) {
                        $('#TableSp').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    var tabledata = $("#TableSp").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,     
                    });  
            },
            loadAkses(){  
                        if (typeof aksessppbadmin != 'undefined') {
                                        this.akses_read     = aksessppbadmin.akses_read;
                                        this.akses_create   = aksessppbadmin.akses_create;
                                        this.akses_update   = aksessppbadmin.akses_update;
                                        this.akses_delete   = aksessppbadmin.akses_delete; 
                                        this.akses_approve  = aksessppbadmin.akses_approve; 
                                        if(this.akses_read == '0'){ this.isBlock=true;}  
                        }
                        else{ 
                                     axios.get("api/getakses", { params: { slug: 'sppb-admin' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        this.akses_approve  = data.akses.akses_approve; 
                                        window.aksessppbadmin = data.akses;        
                                        if(this.akses_read == '0'){ this.isBlock=true;}                       
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                            }
            },
            loadsppbs(){
                        if (typeof DTPageAdmin != 'undefined') { if(DTPageAdmin=="[object KeyboardEvent]"){this.PageAdmin=1} else{this.PageAdmin   = DTPageAdmin;}  }
                        else{ this.PageAdmin = 1;  }
                        
                        if (typeof DTrowAdmin != 'undefined') {this.perrow   = DTrowAdmin; }
                        else{ this.perrow = 10;  }

                        if (typeof DTcariAdmin != 'undefined') {var textcari   = DTcariAdmin;this.myInputtext = DTcariAdmin;}
                        else{ var textcari="";  }
                        axios.get("api/sppbadmin?page="+this.PageAdmin+"&limit="+this.perrow+"&cari="+textcari).then(({ data }) => {
                                    this.CekDatatable();
                                    this.sppbs = data.data;
                                    
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                    })
                                    this.totalpage = data.data.last_page;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
            },        
            TolakPesanan(id_D,namaD){
                Swal.fire({
                    title: "Tolak Pesananan dari "+namaD,
                    text: "Tulis alasan menolak pesanan ",
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Tolak',
                    cancelButtonText: 'Close',
                    customClass: {
                        validationMessage: 'my-validation-message'
                    },
                    preConfirm: (value) => {
                        if (!value) {
                        Swal.showValidationMessage(
                            '<i class="fa fa-info-circle"></i>Alasan harus diisi'
                        )
                        }
                    }
                    }).then((result) => {
                           // console.log(result.value)
                         if (result.value) {
                                axios.post('api/sppb-admintolak',{  id_sp: id_D, alasan : result.value })
                                        .then((response)=>{                        
                                                if(response.data.type=='sukses'){
                                                        Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.message,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                        this.Cari();
                                                }
                                                else{
                                                        Swal.fire({
                                                            icon    : 'error',
                                                            title   : response.data.message,
                                                        })
                                                }
                                            })
                                        .catch((data)=> {
                                            Swal.fire("Failed!", data.message, "warning");
                                        });
                         }
                    })
            },  
            ToDashboard(){
                window.DTPageAdmin   = 1; 
                window.DTrowAdmin    = 10; 
                window.DTcariAdmin   = ""; 
                this.$router.push('/dashboard')
            }

        },
        mounted() {
        },
        created() {
            
            this.$Progress.start();
            this.loadAkses();
            this.loadsppbs();
            this.$Progress.finish();
        },
    }
</script>
