<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row">
  
            <div class="col-12 padding-remove">
          
              <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Product List</h3>
    
                    <div class="card-tools">                    
                            <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                <i class="fa fa-plus-square"></i>
                                Tambah
                            </button>
                    </div>
                </div>
                <div class="row margin-remove">
                    <div class="col-6 p-5px">
                        <div id="TableData_filter" class="dataTables_filter">
                            <label>Cari :<input ref="myInput" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="" aria-controls="TableData"></label>
                        </div>
                    </div>
                    <div class="col-6 p-5px text-right">
                        <label class="margin-remove">Tampil :</label>
                        <div>
                                <select ref="optampil" @change="Cari" v-model="perrow">
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                        </div>
                    </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body padding-remove">
                  <table class="table table-striped table-bordered" id="TableData">
                    <thead>
                      <tr>
                        <th>ID <a id="btnsid" href="#" @click="short('id',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Name <a id="btnsname" href="#" @click="short('name',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Description</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr v-for="product in products.data" :key="product.id">
  
                        <td>{{product.id}}</td>
                        <td>{{product.name}}</td>
                        <td>{{product.category.name}}</td>
                        <td>{{product.photo}}</td>
                        <td>{{product.price}}</td>
                        <!-- <td><img v-bind:src="'/' + product.photo" width="100" alt="product"></td> -->
                        <td>
                            
                                <a v-if="akses_update" href="#" @click="editModal(product)">
                                    <i class="fa fa-edit blue"></i>
                                </a>
                                
                                <a v-if="akses_delete" href="#" @click="deleteProduct(product.id)">
                                    <i class="fa fa-trash red"></i>
                                </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
                <div class="card-footer p-5px">                   
                        <vue-paginate-al :totalPage="totalpage" @btnClick="Cari"></vue-paginate-al>
                        <div>           
                                <router-link to="/dashboard" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>
                        </div>
                </div>
              </div>
              
            </div>
          </div>
  
          <!-- Modal -->
          <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" v-show="!editmode">Create New Product</h5>
                      <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <form @submit.prevent="editmode ? updateProduct() : createProduct()">
                      <div class="modal-body">
                          <div class="form-group">
                              <label>Name</label>
                              <input v-model="form.name" type="text" name="name"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                              <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Description</label>
                              <input v-model="form.description" type="text" name="description"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                              <has-error :form="form" field="description"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Price</label>
                              <input v-model="form.price" type="text" name="price"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                              <has-error :form="form" field="price"></has-error>
                          </div>
                          <div class="form-group">
  
                              <label>Category</label>
                              <select class="form-control" v-model="form.category_id">
                                <option 
                                    v-for="(cat,index) in categories" :key="index"
                                    :value="index"
                                    :selected="index == form.category_id">{{ cat }}</option>
                              </select>
                              <has-error :form="form" field="category_id"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Tags</label>
                              <vue-tags-input
                                v-model="form.tag"
                                :tags="form.tags"
                                :autocomplete-items="filteredItems"
                                @tags-changed="newTags => form.tags = newTags"
                              />
                              <has-error :form="form" field="tags"></has-error>
                          </div>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                          <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
    </section>
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import VuePaginateAl from 'vue-paginate-al'
  
      export default {
        components: {
            VueTagsInput,            
            VuePaginateAl,
          },
          data () {
              return {
                  shortby   : 'created_at',
                  shorting  : 'desc',
                  shortc    : 'desc',
                  totalpage : 1,
                  perrow    : 10,
                  editmode  : false,
                  products  : {},
                  akses_read: 0,
                  akses_create: 0,
                  akses_update: 0,
                  akses_delete: 0,
                  form: new Form({
                      id : '',
                      category : '',
                      name: '',
                      description: '',
                      tags:  [],
                      photo: '',
                      category_id: '',
                      price: '',
                      photoUrl: '',
                      textcari: '',
                      text: '',
                  }),
                  categories    : [],
                
                  tag         :  '',
                  autocompleteItems: [],
              }
          },
          methods: {
            
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    console.log(shorting+" - "+shortby);
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    console.log(shorting+" - "+shortby);
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = 1){
                let textproduct = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;
                /*
                    if(page>0){}
                    else{
                        console.log("reset page 1");
                        this.totalPage       = 1;
                        this.page           = 1;
                    }
                */
                this.$Progress.start();
                axios.get('api/product3?page='+page+'&cari='+textproduct+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.products = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            });
                    
                this.$Progress.finish();
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableData' ) ) {
                        $('#TableData').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    $("#TableData").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,
                    });  
            },
            getResults(page = 1) {  
                    this.$Progress.start();
                    let textproduct = this.$refs.myInput.value;

                    axios.get('api/product3?page=' + page+'&cari='+textproduct).then(({ data }) => {
                        this.CekDatatable();
                        this.products = data.data;
                        
                        this.$nextTick(() => {
                            this.MakeDatatable();
                        })
                        /*
                        setTimeout(()=> {
                                this.MakeDatatable();
                        }, 500)
                        */
                        this.totalpage = data.data.last_page;

                    });

                    this.$Progress.finish();
            },
            loadAkses(){  
                    axios.get("api/getakses", { params: { slug: 'products3' }}).then(({ data }) => {
                        this.akses_read     = data.akses.akses_read;
                        this.akses_create   = data.akses.akses_create;
                        this.akses_update   = data.akses.akses_update;
                        this.akses_delete   = data.akses.akses_delete;
                    });
            },
            loadProducts(){  
                // if(this.$gate.isAdmin()){

                    axios.get("api/product3").then(({ data }) => {
                        this.CekDatatable();
                        this.products = data.data;
                        
                        this.$nextTick(() => {
                            this.MakeDatatable();
                        })
                        /*
                        setTimeout(()=> {
                                this.MakeDatatable();
                        }, 500)
                        */
                       
                        this.totalpage = data.data.last_page;
                    });
                    
                // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product3')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product3/'+this.form.id)
                    .then((response) => {
                        // success
                        $('#addNew').modal('hide');
                        Toast.fire({
                        icon: 'success',
                        title: response.data.message
                        });
                        this.$Progress.finish();
                            //  Fire.$emit('AfterCreate');
    
                        this.getResults();
                    })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deleteProduct(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product3/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.getResults();
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                    }
                                });

                                
                          }
                    })
            },
  
          },
          mounted() { 

          },
          created() {
                this.$Progress.start();
    
                this.loadAkses();
                this.loadProducts();
                this.loadCategories();
                this.loadTags();
    
                this.$Progress.finish();
          },
          filters: {
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
                filteredItems() {
                    return this.autocompleteItems.filter(i => {
                        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
                    });
                },
          },
      }
  </script>
  