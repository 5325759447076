<template>
  <section class="content">
    <div class="container-fluid padding-remove">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">Daftar Pegawai</h3>
            
                            <div class="card-tools">
                                
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                                <table class="table table-hover">
                                    <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Type</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Email Verified?</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                            <tr v-for="employees in employees.data" :key="employees.id_emp">
                        
                                                <td>{{employees.id_emp}}</td>
                                                <td class="text-capitalize">{{employees.nm_emp}}</td>
                                                <td class="text-capitalize">{{employees.factory_id}}</td>
                                                <td>{{employees.email_emp}}</td>
                                                <td>{{employees.hp_emp}}</td>
                                                <td>{{employees.created_at}}</td>
                        
                                                <td>
                        
                                                <a href="#" @click="editModal(employees)">
                                                    <i class="fa fa-edit blue"></i>
                                                </a>
                                                /
                                                <a href="#" @click="Hapus(employees.id_emp, employees.nm_emp)">
                                                    <i class="fa fa-trash red"></i>
                                                </a>
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination :data="employees" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
            </div>
        </div>        
    </div>

        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Tambah Pegawai</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Pegawai</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateData() : createUser()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>NIK</label>
                                    <input v-model="form.id_emp" type="text" name="id_emp"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('id_emp') }">
                                    <has-error :form="form" field="id_emp"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Name</label>
                                    <input v-model="form.nm_emp" type="text" name="nm_emp"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('nm_emp') }">
                                    <has-error :form="form" field="nm_emp"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input v-model="form.email" type="text" name="email"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                    <has-error :form="form" field="email"></has-error>
                                </div>
                            
                                <div class="form-group">
                                    <label>Password</label>
                                    <input v-model="form.password" type="password" name="password"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                                    <has-error :form="form" field="password"></has-error>
                                </div>
                            
                                <div class="form-group">
                                    <label>User Role</label>
                                    <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                        <option value="">Select User Role</option>
                                        <option value="admin">Admin</option>
                                        <option value="user">Standard User</option>
                                    </select>
                                    <has-error :form="form" field="type"></has-error>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                  </form>
                </div>
            </div>
        </div>
  </section>
</template>

<script>

    export default {
        name: 'App',
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Pegawai';
                }
            },
        },
        data () {
            return {
                editmode: false,
                employees : {},
                form: new Form({
                        id_emp : '',
                        nm_emp: '',
                        email: '',
                        password: '',
                        type : '',
                        email_verified_at: '',
                })
            }
        },
        methods: {

                getResults(page = 1) {

                    this.$Progress.start();
                    
                    axios.get('api/pegawai?page=' + page).then(({ data }) => (this.employees = data.data));

                    this.$Progress.finish();
                },

                updateData(){
                        this.$Progress.start();
                        // console.log('Editing data');
                        this.form.put('api/pegawai/'+this.form.id_emp)
                            .then((response) => {
                                
                                if(response.data.data=="gagal"){
                                    Toast.fire({
                                        icon: 'error',
                                        title: response.data.message
                                    });
                                    this.$Progress.fail();
                                }
                                else{
                                    $('#addNew').modal('hide');
                                    Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                    });
                                    this.$Progress.finish();
                                        //  Fire.$emit('AfterCreate');

                                    this.loadEmployees();
                                }
                                
                            })
                            .catch(error => {
                                Toast.fire({
                                        icon: 'error',
                                        title: "GAGAL : "+error.response.data.message
                                    });
                                    this.$Progress.fail();
                            });

                    },

                editModal(employees){
                    this.editmode = true;
                    this.form.reset();
                    $('#addNew').modal('show');
                    this.form.fill(employees);
                },

                newModal(){
                    this.editmode = false;
                    this.form.reset();
                    $('#addNew').modal('show');
                },

                Hapus(id,nama){
                    Swal.fire({
                        title: 'Yakin ingin hapus '+nama+' ?',
                        text: "Setelah dihapus tidak dapat dikembalikan!",
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Hapus!',
                        cancelButtonText: 'Batal'
                        }).then((result) => {

                            // Send request to the server
                            if (result.value) {
                                    this.form.delete('api/pegawai/'+id).then(()=>{
                                            Swal.fire(
                                            'Deleted!',
                                            'Your file has been deleted.',
                                            'success'
                                            );
                                        // Fire.$emit('AfterCreate');
                                        this.loadEmployees();
                                    }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                            }
                        })
                },

                loadEmployees(){
                    this.$Progress.start();

                    if(this.$gate.isAdmin()){
                    axios.get("api/pegawai").then(({ data }) => (this.employees = data.data));
                    }

                    this.$Progress.finish();
                },
          
                createUser(){

                    this.form.post('api/pegawai')
                    .then((response)=>{
                        $('#addNew').modal('hide');

                        Toast.fire({
                                icon: 'success',
                                title: response.data.message
                        });

                        this.$Progress.finish();
                        this.loadEmployees();

                    })
                    .catch(()=>{

                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                    })
                }

        },
        mounted() {
            //console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadEmployees();
            this.$Progress.finish();
        }
    }
</script>
