<template>
  
    <section class="content">
        <div class="container-fluid">
          <div class="row margin-remove">  
            <div class="col-12 padding-remove">
              <div class="card">
                <div class="card-header">
                      <h3 class="card-title">Data ProdukS</h3>
  
                      <div class="card-tools">
                        
                        <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                            <i class="fa fa-plus-square"></i>
                            Add New
                        </button>
                      </div>
                </div>
                <!-- /.card-header -->
                  <div class="card-body padding-remove">
                      <table ref="table" class="table table-striped table-bordered"  id="TableData">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Nama</th>
                              <th>Username</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Website</th>
                            </tr>
                          </thead>
                      </table>
                    </div>
                    
              </div>
            </div>
          </div>
        </div>  
  
        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Tambah Produk</h5>
                        <h5 class="modal-title" v-show="editmode">Edit Produk</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
    
                    <form @submit.prevent="editmode ? updateProduct() : createProduct()">
                        <div class="modal-body">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input v-model="form.name" type="text" name="name"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                    <has-error :form="form" field="name"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Description</label>
                                    <input v-model="form.description" type="text" name="description"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                                    <has-error :form="form" field="description"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Price</label>
                                    <input v-model="form.price" type="text" name="price"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                    <has-error :form="form" field="price"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Category</label>
                                    <select class="form-control" v-model="form.category_id">
                                    <option 
                                        v-for="(cat,index) in categories" :key="index"
                                        :value="index"
                                        :selected="index == form.category_id">{{ cat }}</option>
                                    </select>
                                    <has-error :form="form" field="category_id"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Tags</label>
                                    <vue-tags-input
                                        v-model="form.tag"
                                        :tags="form.tags"
                                        :autocomplete-items="filteredItems"
                                        @tags-changed="newTags => form.tags = newTags"
                                    />
                                    <has-error :form="form" field="tags"></has-error>
                                </div>
                        </div>                        
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                            <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
      </section>
  
  
  </template>
  
  <script>
  
  import VueTagsInput from '@johmun/vue-tags-input';
  export default {
          components: {
              VueTagsInput,
            },
            data () {
                return {
                    editmode: false,
                    products : {},
                    form: new Form({
                        id : '',
                        category : '',
                        name: '',
                        description: '',
                        tags:  [],
                        photo: '',
                        category_id: '',
                        price: '',
                        photoUrl: '',
                    }),
                    categories: [],
                  
                    tag:  '',
                    autocompleteItems: [],
                }
            },
    methods: {
                    loadProducts(){
                           this.$Progress.start();
                            //axios.get("https://jsonplaceholder.typicode.com/users").then((response) => {
                            axios.get("api/product").then((response) => {
                                  //console.log(response.data.DataTable);
                                  $("#TableData").DataTable().destroy();
                                  $("#TableData").DataTable({
                                      data: response.data.data,
                                      pageLength: 10,
                                      processing: true,
                                      responsive: true,
                                      language: {
                                          },
                                      columns: [            
                                          { data: "id" ,"searchable": true},
                                          { data: "name","searchable": true },
                                          { data: "name" ,"searchable": true},
                                          { data: "photo","searchable": true },
                                          { data: "id","searchable": false, "render": function(data){
  
                                                  return '<a href="javascript:void(0);" onclick="newModal"> <i class="fa fa-trash red"></i> Delete</a>';
  
                                              }
                                          },
                                          {
                                                  data: "id","searchable": false,
                                                  render: function (data, type,row) {
                                                      return `<button data-id="${data}" class="btn btn-info" id="btnedit"> Edit </button>`+
                                                             `<button data-id="${data}" class="btn btn-danger" id="btndelete"> Delete </button>`;
                                                  },
                                              },
                                      ],
                                          "language": {
                                                      "lengthMenu": "Tampil _MENU_",
                                                      "info"      : "Tampil _START_ sampai _END_ dari _TOTAL_ Data - klik <i class='fas fa-angle-right'></i> untuk lanjut",
                                                      'paginate'  : {
                                                                      'previous': '<i class="fas fa-angle-left"></i>',
                                                                      'next': '<i class="fas fa-angle-right"></i>'
                                                                      },
                                                      "search"    : "cari:"
                                                  }
                                  });
                                  this.$Progress.finish();
  
                            });   
                    },
                    loadCategories(){
                        axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
                    },
                    loadTags(){
                        axios.get("/api/tag/list").then(response => {
                            this.autocompleteItems = response.data.data.map(a => {
                                return { text: a.name, id: a.id };
                            });
                        }).catch(() => console.warn('Oh. Something went wrong'));
                    },
                    editModal(id){;                        
                            console.log("edit id :"+id);
                            this.editmode = true;
                            this.form.reset();

                            axios.get('api/product/'+id).then(response => {
                                $('#addNew').modal('show');
                                this.form.fill(response.data.data);
                            })
                            .catch(() => {
                                this.$Progress.fail();
                            });
                    },
                    newModal(){
                        this.editmode = false;
                        this.form.reset();
                        $('#addNew').modal('show');
                    },
                    createProduct(){
                        this.$Progress.start();
  
                        this.form.post('api/product')
                        .then((data)=>{
                          if(data.data.success){
                            $('#addNew').modal('hide');
  
                            Toast.fire({
                                  icon: 'success',
                                  title: data.data.message
                              });
                            this.$Progress.finish();
                            this.loadProducts();
  
                          } else {
                            Toast.fire({
                                icon: 'error',
                                title: 'Some error occured! Please try again'
                            });
  
                            this.$Progress.failed();
                          }
                        })
                        .catch(()=>{
  
                            Toast.fire({
                                icon: 'error',
                                title: 'Some error occured! Please try again'
                            });
                        })
                    },
                    updateProduct(){
                        this.$Progress.start();
                        this.form.put('api/product/'+this.form.id)
                        .then((response) => {
                            // success
                            $('#addNew').modal('hide');
                            Toast.fire({
                              icon: 'success',
                              title: response.data.message
                            });
                            this.$Progress.finish();
                                //  Fire.$emit('AfterCreate');
  
                            this.loadProducts();
                        })
                        .catch(() => {
                            this.$Progress.fail();
                        });
  
                    },
                    deleteProduct(id){
                        Swal.fire({
                            title: 'Are you sure?',
                            text: "You won't be able to revert this!",
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Yes, delete it!'
                            }).then((result) => {
  
                                // Send request to the server
                                  if (result.value) {
                                        this.form.delete('api/product/'+id).then(()=>{
                                                Swal.fire(
                                                'Deleted!',
                                                'Your file has been deleted.',
                                                'success'
                                                );
                                            // Fire.$emit('AfterCreate');
                                            $('#TableData').DataTable().destroy();
                                            this.loadProducts();
                                        }).catch((data)=> {
                                            Swal.fire("Failed!", data.message, "warning");
                                        });
                                  }
                            })
                    },
  
        },
    mounted() {
                this.loadProducts();
                const self = this

                $(document).on('click','#btndelete',function(){
                                let id = $(this).data("id")
                                self.deleteProduct(id)
                        });
                $(document).on('click','#btnedit',function(){
                                let id = $(this).data("id")
                                self.editModal(id)
                        });
    },
    
    created() {           
                                        
                    this.loadCategories();
                    this.loadTags();
              
          },
    filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
    computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    
  };
  </script>
  
  <style>
  </style>