<template>
    <div>
        <div class="card-header">
            <h3 class="card-title">Komisi</h3>
                <div class="card-tools">     
                    <button v-if="akses_create && isEditable" type="button" class="btn btn-sm btn-primary" @click="ModalKomisi">
                        <i class="fa fa-plus-square"></i>
                        Tambah
                    </button>
                    <!--
                        <button v-if="akses_approve" type="button" class="btn btn-sm btn-danger" @click="ModalKomisi">
                            <i class="fas fa-times"></i>
                            Tolak
                        </button>             
                        <button v-if="akses_approve" type="button" class="btn btn-sm btn-success" @click="ModalKomisi">
                            <i class="fas fa-check"></i>
                            Approve
                        </button>
                    -->
            </div>
        </div>
        <div v-if="fees.length !==0" class="card-body padding-remove">
            <div class="padding-10">
                <table class="table table-bordered border-bottom" id="TableKomisi">
                  <thead>
                    <tr>
                        <th>Sales</th>
                        <th>Komisi %</th>
                        <th>PPN %</th>
                        <th v-if="total_pesanans>0">Rupiah</th>
                        <th v-if="isEditable">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(fees,index) in fees" :key="index">                        
                        <td>{{fees.name}}</td>
                        <td>{{fees.komisi_ps}}</td>
                        <td>{{fees.pajak_pesanan}}</td>
                        <td v-if="total_pesanans>0">{{rupiah_komisi(fees.komisi_ps,fees.pajak_pesanan,total_pesanans,fees.rupiah_fee)  | formatNumber}}</td>
                        <td v-if="isEditable">
                            <a v-if="akses_update" href="#" class="btn-menu" @click="editModal(index,fees,fees.id_komisi)">
                                <i class="fa fa-edit blue"></i>
                            </a>
                            
                            <a v-if="akses_delete" href="#" class="btn-menu" @click="deletefees(fees.id_komisi , fees.name)">
                                <i class="fa fa-trash red"></i>
                            </a>
                        </td>
                      
                    </tr>
                  </tbody>
                </table>
            </div>
            
        </div>
        <!-- Modal Komisi-->
        <div class="modal fade" id="ModalKomisi" role="dialog" aria-labelledby="ModalKomisi" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 v-show="!EditKomisi" class="modal-title" >Tambah</h5>
                    <h5 v-show="EditKomisi" class="modal-title" >Edit</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="EditKomisi ? UpdateKomisi() : TambahKomisi()">
                    <div class="modal-body">                        
                            <div class="form-group row">
                                        <label class="col-4">Pilih Sales  <code>*</code></label>
                                        <div class="col-8">
                                            <Select2 id="user_id" v-model="form.user_id"  class="full-width" required
                                                :options="sales" 
                                                :settings="{ settingOption: sales.id, settingOption: sales.name }" 
                                                :class="{ 'is-invalid': form.errors.has('user_id') }"
                                                :disabled=EditKomisi
                                                 />
                                            <has-error :form="form" field="user_id"></has-error>
                                        </div>
                            </div>                       
                            <div class="form-group row">
                                        <label class="col-4">Fee & PPN  <code>*</code></label>
                                        <div class="col-8 row padding-right-remove">
                                            <div class="col-6 padding-right-remove input-group mb-3">
                                                <input v-model="form.komisi_ps" type="number" name="komisi_ps" step="0.01"
                                                    placeholder="fee..."
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('komisi_ps') }"
                                                    autocomplete="off">   
                                                <div class="input-group-append"><span class="input-group-text">%</span></div>                            
                                                <has-error :form="form" field="komisi_ps"></has-error>
                                            </div>
                                            <div class="col-6 padding-right-remove input-group mb-3">
                                                <input v-model="form.pajak_pesanan" type="text" name="pajak_pesanan"
                                                    placeholder="PPN..."
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('pajak_pesanan') }"
                                                    autocomplete="off">                
                                                <div class="input-group-append"><span class="input-group-text">%</span></div>                             
                                                <has-error :form="form" field="pajak_pesanan"></has-error>
                                            </div>
                                        </div>
                            </div>                       
                            <div class="form-group row">
                                        <label class="col-4">Fee Rupiah  <code>*</code></label>
                                        <div class="col-8">
                                                <input v-model="form.rupiah_fee" type="number" name="rupiah_fee"
                                                    placeholder="fee (rupiah)..."
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('rupiah_fee') }"
                                                    autocomplete="off">                              
                                                <has-error :form="form" field="rupiah_fee"></has-error>
                                        </div>
                            </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success" :disabled='isDisabled'>Simpan</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>

    </div>
        
        
</template>


<script>
    import Select2 from 'v-select2-component';
    export default {
        name: 'komisi',
        props: ['id_sp','total_pesanans','isEditable'],
        components:{
                Select2,
        },
        data () {
            return {  
                EditKomisi  : false,
                isDisabled  : false,
                akses_read      : 0,
                akses_create    : 0,
                akses_update    : 0,
                akses_delete    : 0,
                akses_approve   : 0,
                sales       : [],
                fees        : [],
                form        : new Form({
                                        index                 : '',
                                        id_komisi             : '',
                                        user_id               : '',
                                        komisi_ps             : '',
                                        pajak_pesanan         : '0',
                                        rupiah_fee            : '0',
                                  }),
            }
        },
        computed:{
            
        },
        methods: {   
            rupiah_komisi(komisi,ppn,total_pesanans,rupiah_fee) {
                if(rupiah_fee>0){
                    return rupiah_fee;
                }
                else{
                        var nppn            = ( (100+Number(ppn))/100)
                        var dpp             = (total_pesanans/nppn)
                        var rupiah_komisi   = Math.round(dpp*(komisi/100))
                        return rupiah_komisi;
                }
            },
            loadppndefault(){
                        if (typeof ppndefault != 'undefined') {
                        }
                        else{ 
                            this.Loadsettingppn()
                        }
            }, 
            loadAkses(){  
                          if (typeof akseskomisi != 'undefined') {
                                          this.akses_read     = akseskomisi.akses_read;
                                          this.akses_create   = akseskomisi.akses_create;
                                          this.akses_update   = akseskomisi.akses_update;
                                          this.akses_delete   = akseskomisi.akses_delete; 
                                          this.akses_approve  = akseskomisi.akses_approve;
                          }
                          else{ 
                                       axios.get("../api/getakses", { params: { slug: 'komisi' }}).then(({ data }) => {
                                          this.akses_read     = data.akses.akses_read;
                                          this.akses_create   = data.akses.akses_create;
                                          this.akses_update   = data.akses.akses_update;
                                          this.akses_delete   = data.akses.akses_delete; 
                                          this.akses_approve  = data.akses.akses_approve; 
                                          window.akseskomisi  = data.akses;                                                                    
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });
                              }
              },         
            ModalKomisi(){
                $(".form-control").removeClass('is-invalid');
                this.EditKomisi = false;
                this.form.reset();
                this.form.pajak_pesanan = ppndefault;
                $('#ModalKomisi').modal('show');
            },
            TambahKomisi(){
                this.isDisabled=true;
                for(var i=0; i < this.fees.length; i++){
                    if( this.fees[i].user_id  == this.form.user_id ){
                        Toast.fire({
                                        icon: 'warning',
                                        title: 'Sales sudah terdaftar sebagai penerima komisi'
                                });
                        this.isDisabled=false;    
                        return true;
                    }
                }

                                this.$Progress.start();
                                this.form.post('../api/komisi', { params: { sp_id: this.id_sp }})
                                .then((response)=>{                        
                                    if(response.data.type=='sukses'){
                                            Toast.fire({
                                                icon: 'success',
                                                title: response.data.message
                                            });
                                            this.loadFees()
                                            $('#ModalKomisi').modal('hide');
                                    }
                                    else{
                                            Swal.fire({
                                                icon    : 'error',
                                                title   : response.data.message,
                                            })
                                    }
                                    this.isDisabled=false;
                                    this.$Progress.finish();
                                })
                                .catch(()=>{
                                    Toast.fire({
                                        icon: 'error',
                                        title: 'Gagal! harap isi form dengan benar'
                                    });
                                    this.isDisabled=false;
                                    this.$Progress.finish();
                                })            
            },
            UpdateKomisi(){
                var index           = this.form.index;
                var komisi_ps       = this.form.komisi_ps;
                var pajak_pesanan   = this.form.pajak_pesanan;
                var rupiah_fee      = this.form.rupiah_fee;
                this.$Progress.start();
                this.form.put('../api/komisi/'+this.form.id_komisi, { params: { sp_id: this.id_sp }})
                .then((response)=>{
                        if(response.data.type=='sukses'){
                                Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                });

                                this.fees[index].komisi_ps        = komisi_ps;
                                this.fees[index].pajak_pesanan    = pajak_pesanan;
                                this.fees[index].rupiah_fee       = rupiah_fee;
                                this.EditKomisi = false;
                                $('#ModalKomisi').modal('hide');
                                this.$Progress.finish();
                        }
                        else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                    })
                .catch(function(error) {
                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                        });

                            
            },
            loadSales(){
                        axios.get("../api/user/getsales").
                                then(({ data }) => {
                                    this.sales = data.data;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });

            },            
            loadFees(){
                        axios.get('../api/komisi?sp_id='+this.id_sp).
                                then(({ data }) => {
                                    this.fees = data.data;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
            },
            editModal(index,fees){
                this.EditKomisi = true;
                $(".form-control").removeClass('is-invalid');
                this.form.reset();
                this.form.fill(fees);
                $('#ModalKomisi').modal('show');
                this.form.index             = index;
            },
            deletefees(id_komisi,nm_sales){
                Swal.fire({
                    title: 'Apakah anda Yakin ?',
                    text: "Hapus "+nm_sales,
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, Hapus',
                    cancelButtonText: 'Close'
                    }).then((result) => {
                            if (result.value) {
                                this.form.delete('../api/komisi/'+id_komisi)
                                .then((response)=>{
                                    if(response.data.type=='sukses'){
                                            Toast.fire({
                                                    icon: 'success',
                                                    title: response.data.message
                                            });
                                            let afees = this.fees
                                            this.fees = afees.filter(afees => afees.id_komisi != id_komisi);
                                    }
                                    else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
                            }
                            else{}
                        })
            },
        },
        mounted() {
                this.loadAkses()
                this.$nextTick(() => {
                    this.loadSales()
                    this.loadFees()
                    this.loadppndefault()
                })
        }
    }
</script>
