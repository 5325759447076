<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row" v-show="!isBlock">
  
              <div class="col-12 padding-remove">          
                    <div class="card">
                        <div class="card-header"><h3 class="card-title">Approval</h3></div>
                        <!-- /.card-header -->
                        
                            <form style="padding-top: 10px;" @submit.prevent="Approve()">
                                <div class="card-body padding-remove minh-65">
                                    <div class="row margin-remove">
                                        <div class="col-md-6 form-horizontal">
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">No. Surat Pesanan</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.no_sp}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">No. SPPB</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.no_sppb}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Customer</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.nm_toko}}</label>
                                                    <input v-model="form.customer_id" hidden>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Tanggal Pesanan</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.tgl_pesanan}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Jatuh tempo</label>                                                    
                                                    <div class="col-7">
                                                        : <label class="margin-bottom-remove" :class="BgDatePelunasan(form.tgl_jatuhtempo,form.is_lunas)">{{form.tgl_jatuhtempo}}</label>
                                                    </div>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Pembayaran</label>                                                    
                                                    <div class="col-7"> :
                                                        <router-link :to="{path:'/cpayment/'+form.sp_id}" >
                                                            {{form.is_lunas | isLunas}}
                                                        </router-link>
                                                    </div>
                                                </div>
                                        </div> 
                                        <div class="col-md-6 form-horizontal">
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Sales</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_sales}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Admin</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_admin}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Akunting 1</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_akt1}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Akunting 2</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_akt2}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Approval</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_bos}}</label>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="card-header">
                                            <h3 class="card-title">Item</h3>
                                    </div>
                                    <div class="div-tabel">
                                        <table class="table table-striped table-bordered margin-bottom-remove" id="TableDproduk">
                                            <thead>
                                                <tr>
                                                    <th data-priority="1" class="min-200px">Nama Produk</th>
                                                    <th>Qty</th>
                                                    <th>Qty Open</th>
                                                    <th>UOM</th>
                                                    <th>@Harga</th>
                                                    <th>@Diskon</th>
                                                    <th>%Diskon</th>
                                                    <th>Jumlah</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(details,index) in details" :key="index">
                                                    <td class="text-capitalize">
                                                            {{details.nm_product}}
                                                            <br v-if="details.note_sales !== null">
                                                            <label v-if="details.note_sales !== null" class="text-italic">Catatan : {{details.note_sales}}</label>
                                                    </td>
                                                    <td>{{details.qty_pesanan}}</td>
                                                    <td>{{details.qty_open}}</td>
                                                    <td>{{details.nm_uom}}</td>
                                                    <td>{{details.hrg_item | formatNumber}}</td>
                                                    <td>{{details.diskonrp_item | formatNumber}}</td>
                                                    <td>{{details.diskonps_item}}%</td>
                                                    <td>{{details.totalhrg_item | formatNumber}}</td>
                                                
                                                </tr>
                                            </tbody>                                            
                                            <tfoot>
                                                <tr>
                                                    <td class="text-center">TOTAL</td>
                                                    <td>{{sum_qty}}</td>
                                                    <td>{{sum_qtyopen}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><strong>Rp {{form.subtotal_pesanans | formatNumber}}</strong></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>    

                                    <div class="col-md-6 form-horizontal padding-top-10">                                  
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-4">SubTotal </label>
                                                    <label class="col-8" hidden>: {{ sumTotalItem}}</label>
                                                    <label class="col-8">: {{ form.subtotal_pesanans | formatNumber}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-4">Diskon Nota</label>   
                                                    <div class="col-8 padding-right-remove">
                                                        : 
                                                        <label class="padding-right-remove"> {{form.diskon_text}}</label> 
                                                    </div>                                        
                                                </div>                                       
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-4">Total </label>
                                                    <label class="col-8">: {{ form.total_pesanans | formatNumber}}</label>
                                                </div>   
                                                
                                    </div>

                                    <komisi v-if="this.form.sp_id" 
                                        :id_sp="this.form.sp_id" 
                                        :isEditable=this.isEditable 
                                        :total_pesanans="this.form.total_pesanans">
                                    </komisi>
                                    
                                    <tandatangan v-if="this.form.status_pesanan==7 || this.form.status_pesanan==8 || this.form.status_pesanan==17" 
                                        :key="this.form.status_pesanan"
                                        :id_sp          ="this.form.sp_id" 
                                        :status_pesanan ="this.form.status_pesanan">
                                    </tandatangan>
                                    
                                </div>
                                <div class="card-footer">      
                                        <router-link to="/approval" type="button" class="btn btn-back">
                                                    <i class="fas fa-chevron-left"></i> Kembali
                                        </router-link>              
                                        <button v-if="akses_approve && isEditable" type="submit" class="btn btn-success" 
                                            :disabled='isDisabled'>
                                            <i class="fas fa-check"></i>
                                            Approve
                                        </button>       
                                        <button v-if="akses_approve && isEditable" type="button" class="btn btn-danger" 
                                            @click="RejectPesanan(form.sp_id,form.nm_toko)"
                                            :disabled='isDisabled'>
                                            <i class="fas fa-times"></i>
                                            Tolak
                                        </button>     
                                </div>
                            </form>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
          </div>
          <div v-show="isBlock">
                    <no-akses></no-akses>
            </div>
                  
          <!-- <history v-if="editmode" :datahistory="historis"></history> -->
      </div>
    </section>
  </template>
  
  <script>
  
        import Select2 from 'v-select2-component';
        import VuePaginateAl from 'vue-paginate-al';
        import history from '../history.vue'
        import komisi from '../komisi.vue'
        import tandatangan from '../tandatangan.vue'
        import moment from "moment";
  
      export default {
          watch: {
              $route: {
                  immediate: true,
                  handler(to, from) {
                      document.title = to.meta.title || 'Sales Order - Approve Akunting 1';
                  }
              },
          },
          components:{
                  Select2,
                  VuePaginateAl,
                  history,
                  komisi,
                  tandatangan,
          },
          data () {
              return {  
                  editmode        : false,
                  isDisabled      : false,
                  isEditable      : false,
                  akses_read      : 0,
                  akses_create    : 0,
                  akses_update    : 0,
                  akses_delete    : 0,
                  akses_approve   : 0,
                  isBlock         : false,
                  pesanans        : [],
                  items           : [],
                  details         : [],
                  sum_qty         : 0,
                  sum_qtyopen     : 0,
                  form            : new Form({
                                        sp_id               : '',
                                        id_sppb             : '',
                                        customer_id         : '',
                                        no_sp               : '',
                                        no_sppb             : '',
                                        nm_toko             : '',
                                        tgl_pesanan         : '',
                                        catatan_sales       : '',
                                        catatan_admin       : null,
                                        catatan_akt1        : null,
                                        catatan_akt2        : null,
                                        catatan_bos         : null,
                                        diskonps_pesanans   : 0,
                                        diskonrp_pesanans   : 0,
                                        diskon_text         : 0,
                                        dppn_pesanans       : 0,
                                        subtotal_pesanans   : 0,
                                        total_pesanans      : 0,
                                        status_pesanan      : 0, //2
                                        tgl_jatuhtempo      : null,
                                        is_lunas            : 0,
                                  }),
                  formItem        : new Form({
                                        index               : '',
                                        sp_id               : '',
                                        product_id      : '',
                                        kd_product      : '',
                                        nm_product      : '',
                                        qty_pesanan     : '0',
                                        qty_kirim       : '0',
                                        qty_open        : '0',
                                        nm_uom          : '',
                                        hrg_item        : '0',
                                        Thrg_item       : '0',
                                        diskonrp_item   : '0',
                                        diskonps_item   : '0',
                                        totalhrg_item   : '0',
                                        Ttotalhrg_item  : '0',
                                        note_sales      : null,
                                  })
              }
          },          
        computed: {
            sumTotalItem() {
                var subtotal = this.details.reduce((sum, details) => {
                    const tharga_item = Number(details.totalhrg_item)
                    return sum += tharga_item;
                }, 0);

                var sumQ = this.details.reduce((sumqty, details) => {
                    const qty = Number(details.qty_pesanan)
                    return sumqty += qty;
                }, 0);
                this.sum_qty = sumQ 

                var sumQopen = this.details.reduce((sumqtyopen, details) => {
                    const qtyopen = Number(details.qty_open)
                    return sumqtyopen += qtyopen;
                }, 0);
                this.sum_qtyopen = sumQopen
                
                var diskon                  = this.form.diskonps_pesanans;
                var percent1                = diskon.toString().replace("%", "")
                var percent                 = percent1.replace(/\,/g, '.')
                var diskonrp_pesanans       = '0';

                if(percent  >0) {
                    diskonrp_pesanans       = (subtotal*percent)/100;
                    this.form.diskonrp_pesanans = diskonrp_pesanans; 
                    this.form.diskon_text   = this.form.diskonps_pesanans+" %" 
                }
                else {
                    diskonrp_pesanans       = this.form.diskonrp_pesanans;
                    //this.form.diskonrp_pesanans = '0';                    
                    this.form.diskon_text   = diskonrp_pesanans.toLocaleString('en-US')
                }
                                
                this.form.subtotal_pesanans = subtotal
                this.form.total_pesanans    = subtotal - diskonrp_pesanans 
                return subtotal;
            }
        },
        methods: {
              loadAkses(){  
                          if (typeof aksesapproval != 'undefined') {
                                          this.akses_read     = aksesapproval.akses_read;
                                          this.akses_create   = aksesapproval.akses_create;
                                          this.akses_update   = aksesapproval.akses_update;
                                          this.akses_delete   = aksesapproval.akses_delete; 
                                          this.akses_approve  = aksesapproval.akses_approve;
                          }
                          else{ 
                                       axios.get("../api/getakses", { params: { slug: 'sppb-admin' }}).then(({ data }) => {
                                          this.akses_read     = data.akses.akses_read;
                                          this.akses_create   = data.akses.akses_create;
                                          this.akses_update   = data.akses.akses_update;
                                          this.akses_delete   = data.akses.akses_delete; 
                                          this.akses_approve  = data.akses.akses_approve; 
                                          window.aksesapproval = data.akses;                                                                    
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });
                              }
              },
              LoadDetails(id){
                  var id_sp   = id;
                  //var no_sp   = text;
                  axios.get("../api/getsppbdetails", { params: { id_sp: id_sp }}).then(({ data }) => {
                                            var ResDetails                = data.data[0];
                                            this.form.sp_id               = id_sp;
                                            this.form.id_sppb             = ResDetails.id_sppb;
                                            this.form.no_sppb             = ResDetails.no_sppb;
                                            this.form.no_sp               = ResDetails.no_sp;
                                            this.form.customer_id         = ResDetails.customer_id;
                                            this.form.nm_toko             = ResDetails.nm_toko;
                                            this.form.tgl_pesanan         = ResDetails.tgl_pesanan;
                                            this.form.catatan_sales       = ResDetails.catatan_sales;
                                            this.form.catatan_admin       = ResDetails.catatan_admin;
                                            this.form.catatan_akt1        = ResDetails.catatan_akt1;
                                            this.form.catatan_akt2        = ResDetails.catatan_akt2;
                                            this.form.catatan_bos         = ResDetails.catatan_bos;
                                            this.form.status_pesanan      = ResDetails.status_pesanan;
                                            this.form.diskonps_pesanans   = ResDetails.diskonps_pesanans;
                                            this.form.diskonrp_pesanans   = ResDetails.diskonrp_pesanans;
                                            this.form.is_lunas            = ResDetails.is_lunas;
                                            if(ResDetails.tgl_jatuhtempo != null)
                                                {this.form.tgl_jatuhtempo      = moment(ResDetails.tgl_jatuhtempo, "YYYY-MM-DD").format("DD-MM-YYYY");}

                                            var ResItems                = data.data[1];                 
                                            this.details                = [];
                                                  for(var i=0; i < ResItems.length; i++){
                                                      let qty_open  = ResItems[i].qty_pesanan - ResItems[i].qty_kirim
                                                      this.details.push({
                                                                          sp_id             : ResItems[i].sp_id ,
                                                                          product_id        : ResItems[i].product_id ,
                                                                          kd_product        : ResItems[i].kd_product ,
                                                                          nm_product        : ResItems[i].kd_product+' - '+ResItems[i].nm_product,
                                                                          note_sales        : ResItems[i].note_sales,
                                                                          qty_pesanan       : ResItems[i].qty_pesanan,
                                                                          nm_uom            : ResItems[i].nm_uom,
                                                                          qty_kirim         : ResItems[i].qty_kirim,
                                                                          qty_open          : qty_open,  
                                                                          hrg_item          : ResItems[i].hrg_item,  
                                                                          Thrg_item          : ResItems[i].hrg_item,  
                                                                          diskonrp_item     : ResItems[i].diskonrp_item,  
                                                                          diskonps_item     : ResItems[i].diskonps_item,  
                                                                          totalhrg_item     : ResItems[i].totalhrg_item,  
                                                                          Ttotalhrg_item     : ResItems[i].totalhrg_item,  
                                                      })
                                                  }
                                            if(ResDetails.status_pesanan == 7){
                                                this.isEditable = true;
                                            }      
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });                
              },
              Approve: function(event) {
                    this.isDisabled=true;
                    Swal.fire({
                        title: 'Apakah yakin ingin Approve ?',
                        text: "setelah approve tidak dapat diubah lagi",
                        showCancelButton: true,
                        confirmButtonColor: '#38c172',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Approve!',
                        input: 'text',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$Progress.start();
                                this.form.post('../api/appapprove',{ params: { level:"approval",catatan:result.value}})
                                    .then((response)=>{                        
                                        if(response.data.type=='sukses'){
                                                                        Swal.fire({
                                                                            icon    : 'success',
                                                                            title   : response.data.message,
                                                                            showConfirmButton: false,
                                                                            timer   : 1500
                                                                        })
                                                                        this.form.catatan_bos       = result.value;
                                                                        this.form.status_pesanan    = 8;
                                                                        this.isEditable = false;
                                        }
                                        else{
                                                Swal.fire({
                                                    icon    : 'error',
                                                    title   : response.data.message,
                                                })
                                        }
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })
                                    .catch(function(error) {
                                                        if (error.response && error.response.status === 401) {                                            
                                                            window.location.href = "/dashboard";
                                                        } else {
                                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");                                            
                                                        }
                                                        
                                    }); 
                                    
                                    this.$Progress.finish();  
                            }
                            else{this.isDisabled=false;}
                        })
                        /*
                  
                         
                      */         
              },
              RejectPesanan(id_D,namaD){
                Swal.fire({
                    title: "Tolak Pesananan dari "+namaD,
                    text: "Tulis alasan menolak pesanan ",
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Tolak',
                    cancelButtonText: 'Close',
                    customClass: {
                        validationMessage: 'my-validation-message'
                    },
                    preConfirm: (value) => {
                        if (!value) {
                        Swal.showValidationMessage(
                            'Alasan harus diisi'
                        )
                        }
                    }
                    }).then((result) => {
                           // console.log(result.value)
                         if (result.value) {
                                axios.post('../api/apptolak',{  id_sp: id_D, alasan : result.value , level:"approval"})
                                        .then((response)=>{                        
                                                if(response.data.type=='sukses'){
                                                        Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.message,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                        this.form.status_pesanan    = 17;
                                                        this.isEditable = false;
                                                }
                                                else{
                                                        Swal.fire({
                                                            icon    : 'error',
                                                            title   : response.data.message,
                                                        })
                                                }
                                            })
                                        .catch((data)=> {
                                            Swal.fire("Failed!", data.message, "warning");
                                        });
                         }
                    })
            },

          },
        mounted() {            
                  //this.loadItems();
          },
        created() {
              this.$Progress.start();
              this.loadAkses();
              this.$nextTick(() => {
                            var id              = this.$route.params.id_sp;
                            this.LoadDetails(id)
                            this.form.id_sp     = id;
                          
                })
              this.$Progress.finish();
          }
      }
  </script>
  