<template>
    <div style="padding-top:20px;padding-bottom:20px;">
            <h5 class="col-lg-12">History Pengiriman : </h5>    
            <label class="col-lg-12" v-if="!datahistory.length">Belum ada pengiriman</label>    
            <div class="history-box" v-for="(datahistory,index) in datahistory" :key="datahistory.id_pengiriman">
                <label class="history-date">{{datahistory.tgl_pengiriman}}</label>
                <router-link v-if="akses_update && (status_pesanan==3 || status_pesanan==4 || status_pesanan==5 || status_pesanan==15) " :to="{path:'/pengiriman/'+datahistory.sp_id+'/'+datahistory.id_pengiriman}" type="button" class="btn btn-sm btn-primary">
                                    Edit
                </router-link>
                <router-link :to="{path:'/pengirimancetak/'+datahistory.id_pengiriman}" v-if="akses_update || akses_create" target="_blank" type="button" class="btn  btn-sm btn-primary">
                                            <i class="fas fa-print"></i> Cetak
                </router-link>
                
                <a v-if="akses_delete && (status_pesanan==3 || status_pesanan==4 || status_pesanan==5 || status_pesanan==15) " 
                                type="button" class="btn btn-sm btn-danger"
                                @click="hapus_pengiriman(datahistory.id_pengiriman,index,datahistory.sp_id)" 
                                >
                                Hapus
                </a>
                <div>
                    <label class="text-uppercase"><strong>{{datahistory.no_pengiriman}} ({{datahistory.supir_kirim}} - {{datahistory.vehicle_number}})</strong></label>
                </div>              
                <div v-for="details in datahistory.details" class="row">
                    <label class="col-8">{{details.kd_product}} - {{details.nm_product}}</label>
                    <label class="col-4">{{details.qty_kirim}} {{details.nm_uom}}</label>
                </div>
                <div v-show="datahistory.catatan_gudang">
                    <label class="text-italic">catatan gudang: {{datahistory.catatan_gudang}}</label> 
                </div> 
            </div>
    </div>
        
</template>

<script>
    export default {
        name: 'history',
        props: ['datahistory','akses_create','akses_update','akses_delete','status_pesanan'],
        methods: {
            hapus_pengiriman(id_pengiriman,index,id_sp){                  
                            Swal.fire({
                                title: "Yakin hapus "+this.datahistory[index].no_pengiriman+"? ",
                                text: "Tulis alasan :",
                                input: 'text',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Ya, Hapus',
                                cancelButtonText: 'Close',
                                customClass: {
                                    validationMessage: 'my-validation-message'
                                },
                                preConfirm: (value) => {
                                    if (!value) {
                                    Swal.showValidationMessage(
                                        '<i class="fa fa-info-circle"></i>Alasan harus diisi'
                                    )
                                    }
                                }
                                }).then((result) => {
                                    // console.log(result.value)
                                    if (result.value) {
                                        axios.post('../api/pengirimanhapus',{  id_pengiriman: id_pengiriman, id_sp:id_sp , alasan : result.value})
                                        .then((response)=>{                        
                                                if(response.data.type=='sukses'){
                                                        Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.message,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                        let adatahistory = this.datahistory
                                                        let newdatahistory = adatahistory.filter(adatahistory => adatahistory.id_pengiriman != id_pengiriman);
                                                        this.$emit('update:datahistory',  newdatahistory)
                                                }
                                                else{
                                                        Swal.fire({
                                                            icon    : 'error',
                                                            title   : response.data.message,
                                                        })
                                                }
                                            })
                                        .catch((data)=> {
                                            Swal.fire("Failed!", data.message, "warning");
                                        });
                                    }
                                    else{}
                                })
              },
        },
        mounted() {
        }
    }
</script>
