<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Role User</h3>

                <div class="card-tools">                    
                        <router-link to="/roles/tambah" type="button" class="btn btn-sm btn-primary">
                                    <i class="fa fa-plus-square"></i> Tambah
                        </router-link>
                </div>
              </div>
              <div class="row margin-remove">
                    <div class="col-6 p-5px">
                        <div id="TableRole_filter" class="dataTables_filter">
                            <label>Cari :<input ref="myInput" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="nik/nama/menu" aria-controls="TableRole"></label>
                            
                        </div>
                    </div>
                    <div class="col-6 p-5px text-right">
                        <label class="margin-remove">Tampil :</label>
                        <div>
                                <select ref="optampil" @change="Cari" v-model="perrow">
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                        </div>
                    </div>
                </div>
              <!-- /.card-header -->
              <div class="card-body padding-remove">
                <table class="table table-striped table-bordered" id="TableRole">
                  <thead>
                    <tr>
                        <th data-priority="1">NIK <a id="btnsid" href="#" @click="short('id_role',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Nama <a id="btnsname" href="#" @click="short('name',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>No.hp</th>
                        <th>Menu</th>
                        <th>Aktif</th>
                        <th data-priority="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="roles in roles.data" :key="roles.id_role">
                        <td>{{roles.emp_id}}</td>
                        <td class="text-capitalize">{{roles.name}}</td>
                        <td>{{roles.hp_emp}}</td>
                        <td>{{roles.menu}}</td>
                        <td :inner-html.prop="roles.status_aktif | isAktif"></td>
                        <td>
                            <router-link :to="{path:'/roles/tambah',query:{emp: roles.id}}" data-toggle="tooltip" data-placement="top" title="Edit">
                                <i class="fa fa-edit blue"></i>
                            </router-link>
                            /
                            <a href="#" @click="deleteRole(roles.emp_id,roles.name)" data-toggle="tooltip" data-placement="top" title="Hapus">
                                <i class="fa fa-trash red"></i>
                            </a>
                        </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer p-5px">                   
                   <vue-paginate-al :totalPage="totalpage" @btnClick="Cari"></vue-paginate-al>
                   <div>           
                                <router-link to="/dashboard" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>
                        </div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Tambah User</h5>
                    <h5 class="modal-title" v-show="editmode">Edit User</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateRole() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>NIK  <code>*</code></label>
                            <input v-model="form.emp_id" type="text" name="emp_id"
                                placeholder="input nomor induk karyawan . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('emp_id') }">
                            <has-error :form="form" field="emp_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Nama  <code>*</code></label>
                            <input v-model="form.name" type="text" name="name"
                                placeholder="input nama . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>No.HP <code>*</code></label>
                            <input v-model="form.hp_emp" type="number" name="hp_emp"
                                placeholder="input nomor hp . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('hp_emp') }">
                            <has-error :form="form" field="hp_emp"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email <code>*</code></label>
                            <input v-model="form.email" type="text" name="email"
                                placeholder="input email . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Password <code v-show="!editmode">*</code></label>
                            <input v-model="form.password" type="password" name="password"
                                placeholder="input password..."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>                    
                        <div class="form-group">
                            <label>User Role</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select User Role</option>
                                <option value="admin">Admin</option>
                                <option value="user">Standard User</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>                  
                        <div class="form-group">
                            <label>Status Aktif</label>
                            <select name="status_aktif" v-model="form.status_aktif" id="status_aktif" class="form-control" :class="{ 'is-invalid': form.errors.has('status_aktif') }">
                                <option value="1">Aktif</option>
                                <option value="0">Non-aktif</option>
                            </select>
                            <has-error :form="form" field="status_aktif"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Role User';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'role_users.created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                roles       : {},
                form        : new Form({
                                id          : '',
                                emp_id      : '',
                                type        : '',
                                name        : '',
                                email       : '',
                                hp_emp      : '',
                                password    : '',
                                email_verified_at: '',
                                status_aktif:'1',
                            })
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = 1){
                let textuser = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;
                
                this.$Progress.start();
                axios.get('api/role?page='+page+'&cari='+textuser+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.roles = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            }).catch(function(error) {
                                                        console.log(error)
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                                    }
                                                });
                    
                this.$Progress.finish();
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableRole' ) ) {
                        $('#TableRole').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    var tabledata = $("#TableRole").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,     
                    });  
            },
            deleteRole(emp_id,nm_emp){
                Swal.fire({
                    title: 'Yakin hapus akses '+emp_id+' - '+nm_emp+' ?',
                    text: "setelah dihapus tidak dapat dikembalikan",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, hapus!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/role/'+emp_id)
                                        .then((response)=>{                        
                                                if(response.data.type=='sukses'){
                                                        Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.msg,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                        this.Cari();
                                                }
                                                else{
                                                        Swal.fire({
                                                            icon    : 'error',
                                                            title   : response.data.msg,
                                                        })
                                                }
                                            })
                                        .catch((data)=> {
                                            Swal.fire("Failed!", data.message, "warning");
                                        });
                         }
                    })
            },
          loadroles(){
                    axios.get("api/role").
                            then(({ data }) => {
                                this.CekDatatable();
                                this.roles = data.data;
                                
                                this.$nextTick(() => {
                                    this.MakeDatatable();
                                })
                                this.totalpage = data.data.last_page;
                            }).catch(function(error) {
                                if (error.response && error.response.status === 401) {
                                    window.location.href = "/dashboard";
                                } else {
                                    console.log(error);
                                }
                            });
          },
          

        },
        mounted() {
                //this.$refs.myInput.focus();
        },
        created() {

            this.$Progress.start();
            this.loadroles();
            this.$Progress.finish();
        }
    }
</script>
