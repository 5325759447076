<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">User List</h3>

                <div class="card-tools">
                  
                  <button v-if="akses_create" type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Tambah
                  </button>
                </div>
              </div>
              <div class="row margin-remove">
                    <div class="col-6 p-5px">
                        <div id="TableUser_filter" class="dataTables_filter">
                            <label>Cari :<input ref="myInput" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="nik/nama" aria-controls="TableUser"></label>
                            
                        </div>
                    </div>
                    <div class="col-6 p-5px text-right">
                        <label class="margin-remove">Tampil :</label>
                        <div>
                                <select ref="optampil" @change="Cari" v-model="perrow">
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                        </div>
                    </div>
                </div>
              <!-- /.card-header -->
              <div class="card-body padding-remove">
                <table class="table table-striped table-bordered" id="TableUser">
                  <thead>
                    <tr>
                        <th data-priority="1">NIK <a id="btnsid" href="#" @click="short('id',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Nama <a id="btnsname" href="#" @click="short('name',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Inisial</th>
                        <th>Email</th>
                        <th>No.Hp</th>
                        <th>Aktif</th>
                        <th data-priority="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="users in users.data" :key="users.id">                        
                        <td>{{users.emp_id}}</td>
                        <td class="text-capitalize">{{users.name}}</td>
                        <td>{{users.initial_user}}</td>
                        <td>{{users.email}}</td>
                        <td>{{users.hp_emp}}</td>
                        <td :inner-html.prop="users.status_aktif | isAktif"></td>
                        <td>
                            <a v-if="akses_update" href="#" class="btn-menu" @click="editModal(users)">
                                <i class="fa fa-edit blue"></i>
                            </a>
                            
                            <a v-if="akses_delete" href="#" class="btn-menu" @click="deleteUser(users.id,users.emp_id)">
                                <i class="fa fa-trash red"></i>
                            </a>
                        </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer p-5px">                   
                   <vue-paginate-al :totalPage="totalpage" @btnClick="Cari"></vue-paginate-al>
                   <div>           
                                <router-link to="/dashboard" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>
                    </div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Tambah User</h5>
                    <h5 class="modal-title" v-show="editmode">Edit User</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>NIK  <code>*</code></label>
                            <input v-if="!editmode" v-model="form.emp_id" type="text" name="emp_id"
                                placeholder="input nomor induk karyawan . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('emp_id') }">
                            <has-error :form="form" field="emp_id"></has-error>
                        </div>
                        <div class="form-group" v-if="editmode"><label>{{this.form.emp_id}}</label></div>
                        <div class="form-group">
                            <label>Nama  <code>*</code></label>
                            <input v-model="form.name" type="text" name="name"
                                placeholder="input nama . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Inisial  <code>*</code></label>
                            <input v-model="form.initial_user" type="text" name="initial_user"
                                placeholder="inisial untuk nomor surat pesanan . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('initial_user') }">
                            <has-error :form="form" field="initial_user"></has-error>
                        </div>
                        <div class="form-group">
                            <label>No.HP <code>*</code></label>
                            <input v-model="form.hp_emp" type="number" name="hp_emp"
                                placeholder="input nomor hp . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('hp_emp') }">
                            <has-error :form="form" field="hp_emp"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email <code>*</code></label>
                            <input v-model="form.email" type="text" name="email"
                                placeholder="input email . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Password <code v-show="!editmode">*</code></label>
                            <input v-model="form.password" type="password" name="password"
                                placeholder="input password..."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>                           
                        <div class="form-group">
                            <label>type</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="freelance">freelance</option>
                                <option value="sales">Sales</option>
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>                       
                        <div class="form-group">
                            <label>Status Aktif</label>
                            <select name="status_aktif" v-model="form.status_aktif" id="status_aktif" class="form-control" :class="{ 'is-invalid': form.errors.has('status_aktif') }">
                                <option value="1">Aktif</option>
                                <option value="0">Non-aktif</option>
                            </select>
                            <has-error :form="form" field="status_aktif"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Users';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                users       : {},
                akses_read: 0,
                akses_create: 0,
                akses_update: 0,
                akses_delete: 0,
                form        : new Form({
                                id          : '',
                                emp_id      : '',
                                type        : 'user',
                                name        : '',
                                initial_user: '',
                                email       : '',
                                hp_emp      : '',
                                password    : '',
                                email_verified_at: '',
                                status_aktif:'1',
                            })
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = 1){
                let textuser = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;
                
                this.$Progress.start();
                axios.get('api/user?page='+page+'&cari='+textuser+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.users = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            }).catch(function(error) {
                                                        console.log(error)
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                                    }
                                                });
                    
                this.$Progress.finish();
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableUser' ) ) {
                        $('#TableUser').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    $("#TableUser").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,
                    });  
            },

            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('api/user?page=' + page).then(({ data }) => (this.users = data.data));

                  this.$Progress.finish();
            },
            editModal(user){
                this.editmode = true;
                $(".form-control").removeClass('is-invalid');
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                $(".form-control").removeClass('is-invalid');
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteUser(id,emp_id){
                Swal.fire({
                    title: 'Yakin hapus '+emp_id+' ?',
                    text: "setelah dihapus tidak dapat dikembalikan",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, hapus!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/user/'+emp_id)
                                .then((response)=>{
                                    if(response.data.type=='sukses'){
                                            Toast.fire({
                                                    icon: 'success',
                                                    title: response.data.message
                                            });
                                            this.Cari();
                                    }
                                    else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
                         }
                    })
            },
            loadAkses(){  
                    axios.get("api/getakses", { params: { slug: 'users' }}).then(({ data }) => {
                        this.akses_read     = data.akses.akses_read;
                        this.akses_create   = data.akses.akses_create;
                        this.akses_update   = data.akses.akses_update;
                        this.akses_delete   = data.akses.akses_delete;
                    });
            },
            loadUsers(){
                        axios.get("api/user").
                                then(({ data }) => {
                                    this.CekDatatable();
                                    this.users = data.data;
                                    
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                    })
                                    this.totalpage = data.data.last_page;
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
            },
          
            createUser(){
                this.$Progress.start();
                this.form.post('api/user')
                    .then((response)=>{
                        if(response.data.type=='sukses'){
                                $('#addNew').modal('hide');
                                Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                });
                                this.Cari();
                        }
                        else{
                                Swal.fire({icon    : 'error', title   : response.data.message,})
                                this.$Progress.finish();
                        }
                    })
                    .catch(function(error) {
                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                        });
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response)=>{
                        if(response.data.type=='sukses'){
                                $('#addNew').modal('hide');
                                Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                });
                                this.Cari();
                        }
                        else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                    })
                .catch(function(error) {
                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                        });

            },

        },
        mounted() {
            //this.$refs.myInput.focus();
        },
        created() {

            this.$Progress.start();
            this.loadAkses();
            this.loadUsers();
            this.$Progress.finish();
        }
    }
</script>
