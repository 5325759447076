<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row" v-show="!isBlock">

            <div class="col-12 padding-remove">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Surat Pesanan </h3>
              </div>
              <!-- /.card-header -->
              
                    <form style="padding-top: 10px;" @submit.prevent>
                        <div class="card-body padding-remove minh-65">
                            <div class="row margin-remove ">
                                <div class="col-md-12 form-horizontal">
                                    <div class="form-group row col-md-6">
                                            <div class="col-6">
                                                <input type="radio" id="isfaktur1" v-model="form.is_faktur" @change="getNoSP(1)" value="1" :disabled='form.status_pesanan >=2 && form.status_pesanan!=13'>
                                                <label class="form-check-label" for="isfaktur1"><b> Faktur</b></label>
                                            </div>
                                            <div class="col-6">
                                                <input type="radio" id="isfaktur0" v-model="form.is_faktur" @change="getNoSP(0)" value="0":disabled='form.status_pesanan >=2 && form.status_pesanan!=13'>
                                                <label class="form-check-label" for="isfaktur0"><b> Non-faktur</b></label>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-6 form-horizontal">                                        
                                        <div class="form-group row">
                                            <label class="col-4">Nomor <code>*</code></label>
                                            <div class="col-8">
                                                <input v-model="form.no_sp" type="text" name="no_sp"
                                                    placeholder="nomor surat . . ."
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('no_sp') }" readonly>
                                                <has-error :form="form" field="no_sp"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-4">Pilih Customer  <code>*</code></label>
                                            <div class="col-8">
                                                <Select2 id="customer_id" v-model="form.customer_id" 
                                                        :options="customers" 
                                                        :settings="{ settingOption: customers.id_customer, settingOption: customers.nm_toko }" 
                                                        :class="{ 'is-invalid': form.errors.has('customer_id') }"
                                                        />
                                                    <has-error :form="form" field="customer_id"></has-error>
                                            </div>
                                        </div>
                                </div> 
                                <div class="col-md-6 form-horizontal">
                                        <div class="form-group row margin-bottom-remove">
                                            <label class="col-4">Renc. Kirim  <code>*</code></label>
                                            <div class="col-8" v-show="form.status_pesanan <2 || form.status_pesanan==13">
                                                <date-picker v-model="form.tgl_renckirim" valueType="format" format="DD-MM-YYYY"
                                                    :class="{ 'is-invalid': form.errors.has('tgl_renckirim') }"
                                                    style="width:100%!important">
                                                </date-picker>
                                                <has-error :form="form" field="tgl_renckirim"></has-error>
                                            </div>
                                            
                                            <div class="col-8" v-show="form.status_pesanan >=2 && form.status_pesanan!=13">
                                                : <label :class="BgDate(form.tgl_renckirim)">{{form.tgl_renckirim}}</label>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-4">jatuh tempo  <code>*</code></label>
                                            <div class="col-8" v-show="form.status_pesanan <2 || form.status_pesanan==13">
                                                <date-picker v-model="form.tgl_jatuhtempo" valueType="format" format="DD-MM-YYYY"
                                                    :class="{ 'is-invalid': form.errors.has('tgl_jatuhtempo') }"
                                                    style="width:100%!important">
                                                </date-picker>
                                                <has-error :form="form" field="tgl_jatuhtempo"></has-error>
                                            </div>
                                            
                                            <div class="col-8" v-show="form.status_pesanan >=2 && form.status_pesanan!=13">
                                                : <label :class="BgDatePelunasan(form.tgl_jatuhtempo,form.is_lunas)">{{form.tgl_jatuhtempo}}</label>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="card-header">
                                    <h3 class="card-title">Item</h3>

                                    <div class="card-tools">                
                                        <button v-if="akses_create && (form.status_pesanan <2 || form.status_pesanan==13) " type="button" class="btn btn-sm btn-primary" @click="newModal">
                                            <i class="fa fa-plus-square"></i>
                                            Tambah
                                        </button>
                                    </div>
                            </div>
                            <div class="div-tabel">
                                <table class="table table-striped table-bordered margin-bottom-remove" id="TableDproduk">
                                    <thead>
                                        <tr>
                                            <th data-priority="1">Nama Produk</th>
                                            <th>Qty</th>
                                            <th>UOM</th>
                                            <th>@Harga</th>
                                            <th>@Diskon</th>
                                            <th>Jumlah</th>
                                            <th data-priority="2" v-show="form.status_pesanan <2  || form.status_pesanan==13">Act</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="details in details" :key="details.id_detail">
                                            <td class="text-capitalize">
                                                    {{details.nm_product}}
                                                    <br v-if="details.note_sales !== null">
                                                    <label v-if="details.note_sales !== null" class="text-italic">Catatan : {{details.note_sales}}</label>
                                            </td>
                                            <td>{{details.qty_pesanan}}</td>
                                            <td class="text-uppercase">{{details.nm_uom}}</td>
                                            <td>{{details.hrg_item | formatNumber}}</td>
                                            <td>{{details.diskonrp_item | formatNumber}}</td>
                                            <td>{{details.totalhrg_item | formatNumber}}</td>
                                            <td v-show="form.status_pesanan <2 || form.status_pesanan==13">
                                                <a  @click="deleteItem(details.product_id,details.nm_product)" 
                                                    data-toggle="tooltip" data-placement="top" title="Hapus">
                                                    <i class="fa fa-trash red"></i>
                                                </a>
                                            </td>
                                        
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>
                            <div class="col-md-6 form-horizontal">                                  
                                    <div class="form-group row margin-bottom-remove">
                                        <label class="col-4">SubTotal </label>
                                        <label class="col-8" hidden>: {{ sumTotalItem}}</label>
                                        <label class="col-8">: {{ form.subtotal_pesanans | formatNumber}}</label>
                                    </div>
                                    <div class="form-group row margin-bottom-remove">
                                            <label class="col-4">Diskon Nota</label>   
                                            <div class="col-8 padding-right-remove">
                                                : 
                                                <input v-show="form.status_pesanan <2 || form.status_pesanan==13" v-model="form.diskon_text" type="text" name="diskon_text"
                                                    placeholder="diskon ... "
                                                    :class="{ 'is-invalid': form.errors.has('diskon_text') }"
                                                    autocomplete="off" style="max-width:90%">
                                                <has-error :form="form" field="diskon_text"></has-error>  
                                                <label v-show="form.status_pesanan !== 13 && form.status_pesanan >=2" class="padding-right-remove"> {{form.diskon_text}}</label> 
                                            </div>                                        
                                    </div>                                     
                                    <div class="form-group row margin-bottom-remove">
                                        <label class="col-4">Total </label>
                                        <label class="col-8">: {{ form.total_pesanans | formatNumber}}</label>
                                    </div>   
                                    
                                    <div class="form-group">
                                        <label>Catatan Sales</label>
                                        <input v-model="form.catatan_sales" type="text" name="catatan_sales"
                                            placeholder="tulis catatan . . ."
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('catatan_sales') }">
                                        <has-error :form="form" field="catatan_sales"></has-error>
                                    </div>                                      
                                    <div class="form-group row" v-show="form.catatan_admin">
                                          <label class="col-12 margin-bottom-remove">Catatan Admin :</label>
                                          <label class="col-12 margin-bottom-remove  text-italic">{{form.catatan_admin}}</label>
                                      </div>     
                                </div>
                        </div>
                        <div class="card-footer">      
                                <router-link to="/surat-pesanan" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>                                
                                <button v-show="form.status_pesanan <2 || form.status_pesanan==13" @click="submitItem(0)" class="btn btn-primary" :disabled='isDisabled'><i class="fas fa-save"></i> Simpan</button>
                                <button v-show="form.status_pesanan  <2 || form.status_pesanan==13" @click="submitItem(2)" class="btn btn-primary" :disabled='isDisabled'><i class="fa fa-check"></i> Submit</button>
                                <router-link :to="{path:'/pesanancetak/'+form.id_sp}" target="_blank" type="button" 
                                            v-if="form.status_pesanan  >1 || form.status_pesanan==13" class="btn btn-primary">
                                            <i class="fas fa-print"></i> Cetak
                                </router-link>   
                        </div>
                    </form>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
        
        <div v-show="isBlock">
                    <no-akses></no-akses>
            </div>
        <!-- Modal -->
        <div class="modal fade" id="ModalItem" role="dialog" aria-labelledby="ModalItem" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" >Tambah Item</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="TambahItem()">
                        <div class="modal-body">
                            <div class="form-group row">
                                        <label class="col-sm-4">Pilih Item  <code>*</code></label>
                                        <div class="col-sm-8">
                                            <Select2 id="product_id" class="full-width" v-model="formItem.product_id"
                                                :options="items" 
                                                :settings="{ settingOption: items.id_product, settingOption: items.nm_product, settingOption: items.nm_uom }" 
                                                :class="{ 'is-invalid': form.errors.has('product_id') }" 
                                                @select="SetUom($event)"
                                                required                                                
                                            />
                                            <has-error :form="form" field="product_id"></has-error>
                                        </div>
                            </div>
                            <div class="form-group row margin-bottom-remove">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Qty  <code>*</code></label>
                                        <input v-model="formItem.nm_uom" hidden>
                                        <input v-model="formItem.qty_pesanan" type="text" name="qty_pesanan" required
                                            placeholder="input qty . . ."
                                            v-on:change=HitungTotal
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('qty_pesanan') }"
                                            autocomplete="off">                                           
                                        <has-error :form="form" field="qty_pesanan"></has-error>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Harga  /item <code>*</code></label>
                                        <input v-model="formItem.hrg_item" hidden>
                                        <input v-model="formItem.Thrg_item" type="text" name="hrg_item" required
                                            placeholder="input harga . . ."
                                            v-on:change=changehrg
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('hrg_item') }"
                                            autocomplete="off">
                                        <has-error :form="form" field="hrg_item"></has-error>
                                    </div>
                                </div>
                            </div>  
                            <div class="form-group row margin-bottom-remove">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Diskon /item</label>                                         
                                        <div class="input-group mb-3">
                                            <input v-model="formItem.diskonps_item" type="text" 
                                                    placeholder="input diskon . . ."
                                                    v-on:change=HitungTotal
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('diskonps_item') }"
                                                    autocomplete="off">
                                                <has-error :form="form" field="diskonps_item"></has-error>
                                            <div class="input-group-append"><span class="input-group-text">%</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Total  <code>*</code></label>
                                        <input v-model="formItem.totalhrg_item" hidden>
                                        <input v-model="formItem.Ttotalhrg_item" type="text" name="totalhrg_item"
                                            readonly
                                            placeholder="input harga . . ."
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('totalhrg_item') }">
                                        <has-error :form="form" field="totalhrg_item"></has-error>
                                    </div>
                                </div>
                            </div>  
                            <div class="form-group">
                                        <label>Catatan </label>
                                        <input v-model="formItem.note_sales" type="text" 
                                            placeholder="tulis catatan . . ."
                                            class="form-control">
                            </div>  
                            
                          
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Tambahkan</button>
                        </div>
                  </form>
                </div>
            </div>
        </div>
        <history v-if="editmode && !isBlock" :datahistory="historis"></history>
    </div>
  </section>
</template>

<script>

    import Select2 from 'v-select2-component';
    import VuePaginateAl from 'vue-paginate-al';
    import history from '../history.vue'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import moment from "moment";

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Surat Pesanan';
                }
            },
        },
        components:{
                Select2,
                VuePaginateAl,
                history,
                DatePicker,
        },
        data () {
            return {  
                editmode        : false,
                isDisabled      : false,
                isEdit          : false,
                isBlock         : false,
                akses_read      : 0,
                akses_create    : 0,
                akses_update    : 0,
                akses_delete    : 0,
                typesave        : 0,
                historis        : [],
                customers       : [],
                items           : [],
                nosp_faktur     : '',
                nosp_nonfaktur  : '',
                details         : [],
                form            : new Form({
                                    customer_id         : '',
                                    is_faktur           : '',
                                    no_sp               : '',
                                    id_sp               : '',
                                    diskon_text         : 0,
                                    diskon_pesanans     : 0,
                                    diskonrp_pesanans   : 0,
                                    diskonps_pesanans   : 0,
                                    dppn_pesanans       : 0,
                                    subtotal_pesanans   : 0,
                                    total_pesanans      : 0,
                                    status_pesanan      : 0,
                                    tgl_jatuhtempo      : null,
                                    is_lunas            : 0,
                                    tgl_renckirim       : null,
                                    catatan_admin       : null,
                                }),
                formItem        : new Form({
                                    product_id      : '',
                                    nm_product      : '',
                                    qty_pesanan     : '',
                                    nm_uom          : '',
                                    hrg_item        : '',
                                    Thrg_item       : '',
                                    diskonrp_item   : '0',
                                    diskonps_item   : '0',
                                    totalhrg_item   : '0',
                                    Ttotalhrg_item  : '0',
                                    note_sales      : null,
                                })
            }
        },
        methods: {
            SetUom({id, text,nm_uom}){
                //console.log({id, text, nm_uom})
                this.formItem.nm_uom = nm_uom
            },
            getNoSP(value){
                    if(value == '1'){
                            if (this.nosp_faktur != '') {
                                                this.form.no_sp     = this.nosp_faktur; 
                            }
                            else{ 
                                        axios.get("../api/surat-pesanan/getnosp", { params: { faktur : '1' }}).then(({ data }) => {
                                                this.form.no_sp      = data.data;
                                                this.nosp_faktur     = data.data;                             
                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });
                                }
                    }
                    else{
                            if (this.nosp_nonfaktur != '') {
                                                this.form.no_sp         = this.nosp_nonfaktur; 
                            }
                            else{ 
                                        axios.get("../api/surat-pesanan/getnosp", { params: { faktur : '0' }}).then(({ data }) => {
                                                this.form.no_sp         = data.data;
                                                this.nosp_nonfaktur     = data.data;                             
                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });
                                }
                    }
            },
            loadAkses(){  
                        if (typeof aksessuratpesanan != 'undefined') {
                                        this.akses_read     = aksessuratpesanan.akses_read;
                                        this.akses_create   = aksessuratpesanan.akses_create;
                                        this.akses_update   = aksessuratpesanan.akses_update;
                                        this.akses_delete   = aksessuratpesanan.akses_delete; 
                                        this.akses_approve  = aksessuratpesanan.akses_approve; 
                                        if(this.akses_create==0 && !this.editmode){this.isBlock=true}                        
                                        if(this.akses_update==0 && this.editmode){this.isBlock=true}                        
                                    }
                        else{ 
                                     axios.get("../api/getakses", { params: { slug: 'surat-pesanan' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        this.akses_approve  = data.akses.akses_approve; 
                                        window.aksessuratpesanan = data.akses;  
                                        if(this.akses_create==0 && !this.editmode){this.isBlock=true} 
                                        if(this.akses_update==0 && this.editmode){this.isBlock=true}                    

                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                            }
            },
            loadCustomers(){
                        axios.get("../api/customer/getcustomer").
                                then(({ data }) => {
                                    this.customers = data.data;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });

            },
            loadItems(){
                        axios.get("../api/produk/getproduk").
                                then(({ data }) => {
                                    this.items = data.data;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
            },
            submitItem(types){          
                this.typesave=types
                //console.log(this.typesave)
                if(this.isEdit == true){this.UpdatePesanan() }
                else {this.SavePesanan() }
            },
            SavePesanan() {
                this.isDisabled=true;
                
                                this.$Progress.start();
                                this.form.post('../api/pesanan', { params: { statusp: this.typesave , details : this.details }})
                                .then((response)=>{                        
                                    if(response.data.type=='sukses'){
                                            Swal.fire({
                                                icon    : 'success',
                                                title   : response.data.message
                                            }).then((oke) => {
                                                    this.$router.replace('/surat-pesanan')
                                            });
                                    }
                                    else{
                                            Swal.fire({
                                                icon    : 'error',
                                                title   : response.data.message,
                                            })
                                    }
                                    this.isDisabled=false;
                                    this.$Progress.finish();
                                })
                                .catch(()=>{
                                    Toast.fire({
                                        icon: 'error',
                                        title: 'Gagal! harap isi form dengan benar'
                                    });
                                    this.isDisabled=false;
                                    this.$Progress.finish();
                                })                 
            },
            UpdatePesanan(){
                this.isDisabled=true;
                this.$Progress.start();
                this.form.put('../api/pesanan/'+this.form.id_sp, { params: { statusp: this.typesave , details : this.details }})
                    .then((response)=>{                        
                        if(response.data.type=='sukses'){
                                Swal.fire({
                                    icon    : 'success',
                                    title   : response.data.message
                                }).then((oke) => {
                                        this.$router.replace('/surat-pesanan')
                                });
                        }
                        else{
                                Swal.fire({
                                    icon    : 'error',
                                    title   : response.data.message,
                                })
                        }
                        this.isDisabled=false;
                        this.$Progress.finish();
                    })
                    .catch(()=>{
                        Toast.fire({
                            icon: 'error',
                            title: 'Gagal! harap isi form dengan benar'
                        });
                        this.isDisabled=false;
                        this.$Progress.finish();
                    })

            },  
            newModal(){
                $(".form-control").removeClass('is-invalid');
                this.formItem.reset();
                $('#ModalItem').modal('show');
            },
            TambahItem(){
                var data        = $('#product_id').select2('data')
                var selecttext  = data[0].text;
                var product_id  = this.formItem.product_id;

                for(var i=0; i < this.details.length; i++){
                    if( this.details[i].product_id  == product_id ){
                        Toast.fire({
                                        icon: 'warning',
                                        title: 'Item sudah ada , '+selecttext
                                });
                        return true;
                    }
                }
                this.details.push({
                                    product_id      : product_id ,
                                    nm_product      : selecttext,
                                    qty_pesanan     : this.formItem.qty_pesanan,
                                    nm_uom          : this.formItem.nm_uom,
                                    hrg_item        : this.formItem.hrg_item,  
                                    diskonrp_item   : this.formItem.diskonrp_item,
                                    diskonps_item   : this.formItem.diskonps_item,
                                    totalhrg_item   : this.formItem.totalhrg_item,
                                    note_sales      : this.formItem.note_sales,
                });  
                $('#ModalItem').modal('hide');         
            },
            deleteItem(product_id,nm_product){
                Swal.fire({
                    title: 'Apakah anda Yakin ?',
                    text: "Hapus "+nm_product,
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, Hapus',
                    cancelButtonText: 'Close'
                    }).then((result) => {
                            if (result.value) {
                                let adetails = this.details
                                this.details = adetails.filter(adetails => adetails.product_id != product_id);
                            }
                            else{}
                        })
            },
            changehrg(){
                    let text                = (this.formItem.Thrg_item).replace(/\,/g, '');
                    let tnmber              = text*1
                    this.formItem.Thrg_item = tnmber.toLocaleString('en-US')
                    var harga               = this.formItem.Thrg_item.replace(/\,/g, '');   
                    this.formItem.hrg_item  = harga  
                    this.HitungTotal()    
            },
            HitungTotal(){
                    if(this.formItem.qty_pesanan > 0 )  {var qty_pesanan =this.formItem.qty_pesanan}else{var qty_pesanan = 0}
                    if(this.formItem.hrg_item >0 )      {var hrg_item =(this.formItem.hrg_item).replace(/\./g, '')}else{var hrg_item = 0}
                    var diskonps_item               = this.formItem.diskonps_item
                    var percent1                    = diskonps_item.replace("%", "")
                    var percent                     = percent1.replace(/\,/g, '.')
                    
                    if(percent >= 0)  
                            {                                
                                var diskonrp_item               = (hrg_item*percent)/100
                                this.formItem.diskonrp_item     = diskonrp_item 
                            }
                            else {var diskonrp_item             = '0';this.formItem.diskonrp_item  = 0}
                            
                    this.formItem.diskonrp_item  = diskonrp_item
                    var total                    = qty_pesanan * (hrg_item - diskonrp_item) 
                    this.formItem.totalhrg_item  = total
                    this.formItem.Ttotalhrg_item = total.toLocaleString('en-US')
            },
            GetPesanan(id){
                axios.get('../api/pesanan/'+id).then((res)=>{
                        var resp                    = res.data.data[0];
                        this.form.is_faktur         = resp.is_faktur
                                                        if(resp.is_faktur=='1'){this.nosp_faktur=resp.no_sp}
                                                        else{this.nosp_nonfaktur=resp.no_sp}
                        this.form.no_sp             = resp.no_sp
                        this.form.customer_id       = resp.customer_id
                        this.form.catatan_sales     = resp.catatan_sales
                        this.form.catatan_admin     = resp.catatan_admin
                        this.form.diskonps_pesanans = resp.diskonps_pesanans
                        this.form.diskonrp_pesanans = resp.diskonrp_pesanans
                        this.form.status_pesanan    = resp.status_pesanan
                        this.form.is_lunas          = resp.is_lunas
                        if(resp.tgl_jatuhtempo != null)
                            {this.form.tgl_jatuhtempo    = moment(resp.tgl_jatuhtempo, "YYYY-MM-DD").format("DD-MM-YYYY");}
                        if(resp.tgl_renckirim != null)
                            {this.form.tgl_renckirim    = moment(resp.tgl_renckirim, "YYYY-MM-DD").format("DD-MM-YYYY");}

                        if(resp.diskonps_pesanans>0){this.form.diskon_text=resp.diskonps_pesanans+'%'}
                        else{this.form.diskon_text  = resp.diskonrp_pesanans }
                        this.form.diskon_pesanans   = this.form.diskon_text

                        var ResItems                = res.data.data[1];
                        for(var i=0; i < ResItems.length; i++){
                                this.details.push({
                                                    product_id      : ResItems[i].product_id ,
                                                    nm_product      : ResItems[i].kd_product+' - '+ResItems[i].nm_product,
                                                    qty_pesanan     : ResItems[i].qty_pesanan,
                                                    nm_uom          : ResItems[i].nm_uom,
                                                    hrg_item        : ResItems[i].hrg_item,  
                                                    diskonrp_item   : ResItems[i].diskonrp_item ,
                                                    diskonps_item   : ResItems[i].diskonps_item ,
                                                    totalhrg_item   : ResItems[i].totalhrg_item,
                                                    note_sales      : ResItems[i].note_sales,
                                }); 
                        }
                        this.isEdit = true;
                }).catch((data)=> { Swal.fire("Failed!", data.message, "warning"); });
            }
        },
        computed: {
            sumTotalItem() {
                var subtotal = this.details.reduce((sum, details) => {
                    const tharga_item = Number(details.totalhrg_item)
                    return sum += tharga_item;
                }, 0);

                if(this.form.diskon_text >= 0)  
                    {
                        var diskon_text                 = this.form.diskon_text;
                        //var diskonps_pesanans               = diskon_text / this.form.subtotal * 100
                        //this.form.diskonps_pesanans         = diskonps_pesanans
                        this.form.diskonrp_pesanans         = diskon_text
                        this.form.diskonps_pesanans         = "0"
                        var diskonrp_pesanans               = diskon_text
                    }
                else{
                                    var diskon      = this.form.diskon_text;
                                        
                                    var isPercent   = diskon.substring(diskon.length - 1)
                                        if(isPercent== '%'){
                                                var percent1                = diskon.replace("%", "")
                                                var percent                 = percent1.replace(/\,/g, '.')
                                                this.form.diskonps_pesanans = percent;
                                                var diskonrp_pesanans       = (subtotal*percent)/100
                                                this.form.diskonrp_pesanans = 0 
                                            }
                                        else{
                                                var diskonrp_pesanans       = 0; 
                                                this.form.diskonps_pesanans  = 0
                                            }
                            }
                
                this.form.subtotal_pesanans = subtotal
                this.form.total_pesanans = subtotal - diskonrp_pesanans
                return subtotal;
            }
        },
        mounted() {            
                this.loadItems();
        },
        created() {
            this.$Progress.start();
            this.loadAkses();
            this.loadCustomers();
                    const searchParams = new URLSearchParams(window.location.search);
                    if(searchParams.has('id') == true)
                        { 
                            var id              = searchParams.get('id');
                            this.GetPesanan(id);
                            this.LoadHistory('surat-pesanan',id);
                            this.form.id_sp     = id;
                            this.editmode       = true;
                        }
            this.$Progress.finish();
        }
    }
</script>
