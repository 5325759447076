<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
            <div class="card minh-65" v-show="!isBlock">
              <div class="card-header">
                <h3 class="card-title">Surat Pesanan</h3>

                <div class="card-tools">                    
                        <router-link  v-if="akses_create" to="/surat-pesanan/tambah" type="button" class="btn btn-sm btn-primary">
                                    <i class="fa fa-plus-square"></i> Tambah
                        </router-link>
                </div>
              </div>
              <div class="row margin-remove">
                    <div class="col-6 p-5px">
                        <div id="TableSp_filter" class="dataTables_filter">
                            <label>Cari :<input ref="myInput" v-model="myInputtext" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="nomor/customer/status" aria-controls="TableSp"></label>
                            
                        </div>
                    </div>
                    <div class="col-6 p-5px text-right">
                        <label class="margin-remove">Tampil :</label>
                        <div>
                                <select ref="optampil" @change="Cari" v-model="perrow">
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                        </div>
                    </div>
                </div>
              <!-- /.card-header -->
              <div class="card-body padding-remove">
                <table class="table table-striped table-bordered" id="TableSp">
                  <thead>
                    <tr>
                        <th data-priority="1">Nomor <a href="#" @click="short('no_sp',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th data-priority="3">Toko <a href="#" @click="short('nm_toko',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>PIC</th>
                        <th>Total</th>
                        <th data-priority="4">J.Tempo <a href="#" @click="short('tgl_tempo',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th data-priority="5">Status <a href="#" @click="short('status_pesanan',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th data-priority="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="pesanans in pesanans.data" :key="pesanans.id_sp">
                        <td>{{pesanans.no_sp}}</td>
                        <td class="text-capitalize">{{pesanans.nm_toko}}</td>
                        <td>{{pesanans.nm_customer}}</td>
                        <td>{{pesanans.total_pesanans | formatNumber}}</td>
                        <td :class="BgDatePelunasan(pesanans.tgl_jatuhtempo,pesanans.is_lunas)">{{pesanans.tgl_jatuhtempo}}</td>
                        <td :class="setColor(pesanans.status_pesanan)">{{pesanans.status_sp}}</td>
                        <td>
                            <router-link v-if="akses_update" :to="{path:'/surat-pesanan/tambah',query:{id: pesanans.id_sp}}" class="btn-menu"
                                data-toggle="tooltip" data-placement="top" title="Edit">
                                <i class="fa fa-edit blue"></i>
                            </router-link>                            
                            <router-link v-if="pesanans.status_pesanan>=4 && pesanans.status_pesanan<=9" :to="{path:'/pengiriman/'+pesanans.id_sp}" class="btn-menu"
                                        data-toggle="tooltip" data-placement="top" title="Pengiriman">
                                        <i class="fas fa-truck-moving navy"></i>
                            </router-link>
                            
                            <a v-if="akses_delete==1 && (pesanans.status_pesanan==2 || pesanans.status_pesanan==13)" href="#" class="btn-menu" @click="CancelPesanan(pesanans.id_sp,pesanans.nm_toko)" 
                                data-toggle="tooltip" data-placement="top" title="Cancel Order">
                                <i class="fas fa-times-circle red"></i>
                            </a>
                        </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer p-5px">                   
                   <vue-paginate-al :totalPage="totalpage" :currentPage="PageSp" @btnClick="Cari"></vue-paginate-al>
                   <div>           
                                <router-link to="/dashboard" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>
                        </div>
                </div>
            </div>
            <div v-show="isBlock">
                    <no-akses></no-akses>
                </div>
            <!-- /.card -->
          </div>
        </div>       
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Surat Pesanan';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'pesanans.created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                isBlock     : false,
                pesanans    : {},
                akses_read  : 0,
                akses_create: 0,
                akses_update: 0,
                akses_delete: 0,
                PageSp   : 1,
                myInputtext :"",
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page =  this.PageSp){
                let textuser = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value; 
                
                window.DTPageSp   = page; 
                window.DTrowSp    = perrow; 
                window.DTcariSp   = textuser; 
                
                axios.get('api/pesanan?page='+page+'&cari='+textuser+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.pesanans = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            }).catch(function(error) {
                                                        console.log(error)
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                                    }
                                                });
                    
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableSp' ) ) {
                        $('#TableSp').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    var tabledata = $("#TableSp").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,     
                    });  
            },
            CancelPesanan(id_D,namaD){
                Swal.fire({
                    title: 'Apakah anda Yakin ?',
                    text: "Cancel Pesananan dari "+namaD,
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, Cancel!',
                    cancelButtonText: 'Close'
                    }).then((result) => {
                         if (result.value) {
                                axios.delete('api/pesanan/'+id_D)
                                        .then((response)=>{                        
                                                if(response.data.type=='sukses'){
                                                        Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.message,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                        this.Cari();
                                                }
                                                else{
                                                        Swal.fire({
                                                            icon    : 'error',
                                                            title   : response.data.message,
                                                        })
                                                }
                                            })
                                        .catch((data)=> {
                                            Swal.fire("Failed!", data.message, "warning");
                                        });
                         }
                    })
            },
            loadAkses(){  
                        if (typeof aksessuratpesanan != 'undefined') {
                                        this.akses_read     = aksessuratpesanan.akses_read;
                                        this.akses_create   = aksessuratpesanan.akses_create;
                                        this.akses_update   = aksessuratpesanan.akses_update;
                                        this.akses_delete   = aksessuratpesanan.akses_delete; 
                                        if(this.akses_read == '0'){ this.isBlock=true;}  
                        }
                        else{ 
                                     axios.get("api/getakses", { params: { slug: 'surat-pesanan' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        window.aksessuratpesanan = data.akses;        
                                        if(this.akses_read == '0'){ this.isBlock=true;}                       
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                            }
            },
            loadpesanans(){
                        if (typeof DTPageSp != 'undefined') { if(DTPageSp=="[object KeyboardEvent]"){this.PageSp=1} else{this.PageSp   = DTPageSp;}  }
                        else{ this.PageSp = 1;  }
                        
                        if (typeof DTrowSp != 'undefined') {this.perrow   = DTrowSp; }
                        else{ this.perrow = 10;  }

                        if (typeof DTcariSp != 'undefined') {var textcari   = DTcariSp;this.myInputtext = DTcariSp;}
                        else{ var textcari="";  }
                        
                        axios.get("api/pesanan?page="+this.PageSp+"&limit="+this.perrow+"&cari="+textcari)
                                .then(({ data }) => {
                                    this.CekDatatable();
                                    this.pesanans = data.data;
                                    
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                    })
                                    this.totalpage = data.data.last_page;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
            },
          

        },
        mounted() {
        },
        created() {

            this.$Progress.start();
            this.loadAkses();
            this.loadpesanans();
            this.$Progress.finish();
        },
        computed: {
            
            
        },
    }
</script>
