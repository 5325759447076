<template>
    <div class="padding-left-remove padding-right-remove">
            <h5>History : </h5>        
            <div class="history-box" v-for="datahistory in datahistory" :key="datahistory.id_log">
                <a class="history-date">{{datahistory.tanggal}}</a>
                <a>{{datahistory.name}} {{datahistory.keterangan_log}}</a>
            </div>
    </div>
        
</template>

<script>
    export default {
        name: 'history',
        props: ['datahistory'],
        mounted() {
        }
    }
</script>
