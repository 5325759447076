<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Role User</h3>
              </div>
              <!-- /.card-header -->
              
                    <form ref="form" style="padding-top: 10px;" @submit.prevent="saveRole" id="FormRoleUser">
                        <div class="card-body padding-remove">
                            <div class="col-md-6 form-horizontal">
                                    <div class="form-group row">
                                        <label class="col-sm-4">Pilih Karyawan  <code>*</code></label>
                                        <div class="col-sm-8">
                                            <Select2 id="emp_id" v-model="form.emp_id" 
                                                :options="users" 
                                                :settings="{ settingOption: users.emp_id, settingOption: users.name }" 
                                                :class="{ 'is-invalid': form.errors.has('emp_id') }"
                                                @change="myChangeEvent($event)" @onchange="" @select="ShowRole($event)"
                                                 />
                                            <has-error :form="form" field="emp_id"></has-error>
                                        </div>
                                    </div>
                            </div> 
                            <div class="p-10px">
                                 <h4>Menu :</h4>
                            </div>
                            <div class="row margin-remove">
                                    <div class="col-md-6 form-horizontal" v-for="menus in menus" :key="menus.id_menu" >
                                        <p class="text-uppercase margin-bottom-remove">{{menus.nm_menu}}</p>
                                        
                                        <div class="form-check">
                                            <input type="checkbox" :value="'akses_read-'+menus.id_menu" v-model="form.akses">
                                            <label>Akses Buka</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="checkbox" :value="'akses_create-'+menus.id_menu" v-model="form.akses">
                                            <label>Akses Tambah</label>
                                        </div>  
                                        <div class="form-check">
                                            <input type="checkbox" :value="'akses_update-'+menus.id_menu" v-model="form.akses">
                                            <label>Akses Edit</label>
                                        </div> 
                                        <div class="form-check">
                                            <input type="checkbox" :value="'akses_delete-'+menus.id_menu" v-model="form.akses">
                                            <label>Akses Delete</label>
                                        </div>      
                                        <div class="form-check">
                                            <input type="checkbox" :value="'akses_approve-'+menus.id_menu" v-model="form.akses">
                                            <label>Akses Approve</label>
                                        </div>                                      
                                    </div>
                            </div>
                        </div>
                        <div class="card-footer">      
                                <router-link to="/roles" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>
                                <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i> Simpan</button>
                        </div>
                    </form>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>

    </div>
  </section>
</template>

<script>

    import Select2 from 'v-select2-component';

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Role User';
                }
            },
        },
        components:{
                Select2,
        },
        data () {
            return { 
                editmode    : false,
                roles       : {},
                users       : [],
                menus       : [],
                form        : new Form({
                                emp_id          : '',
                                akses           : [],
                            })
            }
        },
        methods: {
            myChangeEvent(val){
                console.log(val);
            },
            ShowRole({id, text,emp_id}){
                //console.log({id, text, emp_id})

                $('input[type=checkbox]').prop('checked', false);
                this.form.akses = [];
                axios.get('../api/role/'+id).then((response)=>{
                                        //this.form.akses     = response.data.data;
                                        const akses         = response.data.data;                                        
                                        for (let i = 0; i < akses.length; i++) {
                                            if(akses[i].akses_read==1){
                                                $("input[value='akses_read-"+akses[i].id_menu+"']").prop("checked", true);
                                                this.form.akses.push('akses_read-'+akses[i].id_menu);
                                            }
                                            if(akses[i].akses_create==1){
                                                $("input[value='akses_create-"+akses[i].id_menu+"']").prop("checked", true);
                                                this.form.akses.push('akses_create-'+akses[i].id_menu);
                                            }
                                            if(akses[i].akses_update==1){
                                                $("input[value='akses_update-"+akses[i].id_menu+"']").prop("checked", true);
                                                this.form.akses.push('akses_update-'+akses[i].id_menu);
                                            }
                                            if(akses[i].akses_delete==1){
                                                $("input[value='akses_delete-"+akses[i].id_menu+"']").prop("checked", true);
                                                this.form.akses.push('akses_delete-'+akses[i].id_menu);
                                            }
                                            if(akses[i].akses_approve==1){
                                                $("input[value='akses_approve-"+akses[i].id_menu+"']").prop("checked", true);
                                                this.form.akses.push('akses_approve-'+akses[i].id_menu);
                                            }                                 
                                        }
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                                
            },
            loadUsers(){
                        axios.get("../api/user/list").
                                then(({ data }) => {
                                    this.users = data.data;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });

            },
            loadMenu(){
                        axios.get('../api/role/getmenu').then((response)=>{
                                        this.menus      = response.data.data;
                                        //console.log(this.menus);
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                                /*
                                axios.get("api/gallery").then(response => {
                                    this.dataImages = response.data.data.map((obj, index) => {
                                        return {
                                                id: obj.id,
                                                src: obj.path,
                                                alt: `Alt image ${index}`
                                        };
                                    });
                                });
                                */
            },

            saveRole: function(event) {
                //console.log(this.form);
                this.form.post('../api/role')
                    .then((response)=>{                        
                        if(response.data.type=='sukses'){
                                Swal.fire({
                                    icon    : 'success',
                                    title   : response.data.msg,
                                    showConfirmButton: false,
                                    timer   : 1500
                                })
                        }
                        else{
                                Swal.fire({
                                    icon    : 'error',
                                    title   : response.data.msg,
                                })
                        }
                    })
                    .catch(()=>{
                        Toast.fire({
                            icon: 'error',
                            title: 'Gagal! harap isi form dengan benar'
                        });
                    })
                    
            }

        },
        mounted() {
                const searchParams = new URLSearchParams(window.location.search);
                if(searchParams.has('emp') == true)
                    { 
                        var emp = searchParams.get('emp');
                        this.form.emp_id = emp;
                        this.ShowRole({id:emp});
                    }
        },
        created() {
            this.$Progress.start();
            this.loadMenu();
            this.loadUsers();
            this.$Progress.finish();
        }
    }
</script>
