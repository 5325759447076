<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <h1><i class="fas fa-universal-access notfound"></i></h1>
                    <h1 class="text-capitalize">anda tidak memiliki akses kehalaman ini</h1>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        mounted() {
           
        }
    }
</script>
