<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
                <div class="card minh-65" v-show="!isBlock">
                    <div class="card-header">
                        <h3 class="card-title">Akunting 1</h3>
                    </div>
                    <div class="row margin-remove">
                            <div class="col-6 p-5px">
                                <div id="TableSp_filter" class="dataTables_filter">
                                    <label>Cari :<input ref="myInput" v-model="myInputtext" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="no SP/toko/jatuh tempo" aria-controls="TableSp"></label>
                                    
                                </div>
                            </div>
                            <div class="col-6 p-5px text-right">
                                <label class="margin-remove">Tampil :</label>
                                <div>
                                        <select ref="optampil" @change="Cari" v-model="perrow">
                                            <option value="10">10</option>
                                            <option value="25" >25</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                        </select>
                                </div>
                            </div>
                        </div>
                    <!-- /.card-header -->
                    <div class="card-body padding-remove">
                        <table class="table table-striped table-bordered" id="TableSp">
                        <thead>
                            <tr>
                                <th data-priority="1">No. SP <a href="#" @click="short('no_sp',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                <th data-priority="3">Toko <a href="#" @click="short('nm_toko',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                <th>Jatuh tempo <a href="#" @click="short('tgl_tempo',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                <th data-priority="4">Status</th>
                                <th data-priority="2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sppbs in sppbs.data" :key="sppbs.id_sppb">
                                <td>{{sppbs.no_sp}}</td>
                                <td class="text-capitalize">{{sppbs.nm_toko}}</td>
                                <td><label class="margin-bottom-remove" :class="BgDatePelunasan(sppbs.tgl_jatuhtempo,sppbs.is_lunas)">{{sppbs.tgl_jatuhtempo}}</label></td>
                                <td>{{StatusSP(sppbs.status_pesanan)}}</td>
                                <td>
                                    <router-link v-if="akses_approve" :to="{path:'/akt1/'+sppbs.id_sp}" class="btn-menu"
                                        data-toggle="tooltip" data-placement="top" title="SPPB">
                                        <i class="fas fa-file-alt blue"></i>
                                    </router-link>
                                </td>
                            
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer p-5px">                   
                            <vue-paginate-al :totalPage="totalpage" :currentPage="PageAkt1" @btnClick="Cari"></vue-paginate-al>
                            <div>           
                                <a @click="ToDashboard" type="button" class="btn btn-back">
                                                <i class="fas fa-chevron-left"></i> Kembali
                                    </a>
                            </div>
                    </div>
                </div>
                
                <div v-show="isBlock">
                    <no-akses></no-akses>
                </div>
          </div>
        </div>       
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Akunting 1';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'pesanans.created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                sppbs       : {},
                akses_read  : 0,
                akses_create: 0,
                akses_update: 0,
                akses_delete: 0,
                akses_approve: 0,
                PageAkt1: 1,
                myInputtext :"",
                isBlock         : false,
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = this.PageAkt1){
                let textuser = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;      
                
                window.DTPageAkt1   = page; 
                window.DTrowAkt1    = perrow; 
                window.DTcariAkt1   = textuser; 
                
                axios.get('api/akt?page='+page+'&cari='+textuser+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.sppbs = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            }).catch(function(error) {
                                                        console.log(error)
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                                    }
                                                });
                    
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableSp' ) ) {
                        $('#TableSp').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    var tabledata = $("#TableSp").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,     
                    });  
            },
            loadAkses(){  
                        if (typeof aksesakt1 != 'undefined') {
                                        this.akses_read     = aksesakt1.akses_read;
                                        this.akses_create   = aksesakt1.akses_create;
                                        this.akses_update   = aksesakt1.akses_update;
                                        this.akses_delete   = aksesakt1.akses_delete; 
                                        this.akses_approve  = aksesakt1.akses_approve; 
                                        if(this.akses_read == '0'){ this.isBlock=true;}  
                        }
                        else{ 
                                     axios.get("api/getakses", { params: { slug: 'akt1' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        this.akses_approve  = data.akses.akses_approve; 
                                        window.aksesakt1 = data.akses;        
                                        if(this.akses_read == '0'){ this.isBlock=true;}                       
                                    }).catch(function(error) {
                                            console.log(error)
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                            }
            },
            loadsppbs(){
                        if (typeof DTPageAkt1 != 'undefined'){ 
                            if(DTPageAkt1=="[object KeyboardEvent]"){this.PageAkt1=1} else{this.PageAkt1   = DTPageAkt1;}  
                        }
                        else{ this.PageAkt1 = 1;  }

                        if (typeof DTrowAkt1 != 'undefined') {this.perrow   = DTrowAkt1; }
                        else{ this.perrow = 10;  }

                        if (typeof DTcariAkt1 != 'undefined') {var textcari   = DTcariAkt1;this.myInputtext = DTcariAkt1;}
                        else{ var textcari="";  }
                        axios.get("api/akt?page="+this.PageAkt1+"&limit="+this.perrow+"&cari="+textcari).then(({ data }) => {
                                    this.CekDatatable();
                                    this.sppbs = data.data;
                                    
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                    })
                                    this.totalpage = data.data.last_page;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
            },       
            ToDashboard(){
                window.DTPageAkt1   = 1; 
                window.DTrowAkt1    = 10; 
                window.DTcariAkt1   = ""; 
                this.$router.push('/dashboard')
            }   

        },
        mounted() {
        },
        created() {

            this.$Progress.start();
            this.loadAkses();
            this.loadsppbs();
            this.$Progress.finish();
        },
    }
</script>
