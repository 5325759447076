<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
            <div class="card minh-65">
              <div class="card-header">
                <h3 class="card-title">Data Produk</h3>

                <div class="card-tools">
                  
                  <button v-if="akses_create" type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Tambah
                  </button>
                </div>
              </div>
              <div class="row margin-remove">
                    <div class="col-6 p-5px">
                        <div id="TableProduct_filter" class="dataTables_filter">
                            <label>Cari :<input ref="myInput" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="kode/nama" aria-controls="TableProduct"></label>
                            
                        </div>
                    </div>
                    <div class="col-6 p-5px text-right">
                        <label class="margin-remove">Tampil :</label>
                        <div>
                                <select ref="optampil" @change="Cari" v-model="perrow">
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                        </div>
                    </div>
                </div>
              <!-- /.card-header -->
              <div class="card-body padding-remove">
                <table class="table table-striped table-bordered" id="TableProduct">
                  <thead>
                    <tr>
                        <th data-priority="1">Kode <a id="btnsid" href="#" @click="short('kd_product',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Nama Produk<a id="btnsname" href="#" @click="short('nm_product',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>UOM</th>
                        <th data-priority="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="produks in produks.data" :key="produks.kd_product">                        
                        <td>{{produks.kd_product}}</td>
                        <td class="text-capitalize">{{produks.nm_product}}</td>
                        <td>{{produks.uom_product}}</td>
                        <td>
                            <a v-if="akses_update" href="#" class="btn-menu" @click="editModal(produks)">
                                <i class="fa fa-edit blue"></i>
                            </a>
                            
                            <a v-if="akses_delete" href="#" class="btn-menu" @click="deleteProduk(produks.id_product , produks.nm_product)">
                                <i class="fa fa-trash red"></i>
                            </a>
                        </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer p-5px">                   
                   <vue-paginate-al :totalPage="totalpage" @btnClick="Cari"></vue-paginate-al>
                   <div>           
                                <router-link to="/dashboard" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>
                    </div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="ModalProduk" tabindex="-1" role="dialog" aria-labelledby="ModalProduk" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Tambah Produk</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Produk</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createProduct"> -->

                <form @submit.prevent="editmode ? updateProduct() : createProduct()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Kode  <code>*</code></label>
                            <input v-model="form.kd_product" type="text"
                                placeholder="input kode produk . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('kd_product') }">
                            <has-error :form="form" field="kd_product"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Nama Produk <code>*</code></label>
                            <input v-model="form.nm_product" type="text"
                                placeholder="input nama produk . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nm_product') }">
                            <has-error :form="form" field="nm_product"></has-error>
                        </div>

                        <div class="form-group">  
                            <label>UOM <code>*</code></label>
                            <select name="uom_product" v-model="form.uom_product" id="uom_product" class="form-control" 
                                :class="{ 'is-invalid': form.errors.has('uom_product') }">
                                <option v-for="uoms in uoms" :value="uoms.id">{{uoms.text}}</option>
                            </select>
                            <has-error :form="form" field="uom_product"></has-error>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Produk';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                produks     : {},
                uoms        : [],
                akses_read  : 0,
                akses_create: 0,
                akses_update: 0,
                akses_delete: 0,
                form        : new Form({
                                id_product  : '',
                                kd_product  : '',
                                nm_product  : '',
                                uom_product : 'BL',
                            })
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    console.log(shorting+" - "+shortby);
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    console.log(shorting+" - "+shortby);
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = 1){
                let textproduk = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;
                
                this.$Progress.start();
                axios.get('api/produk?page='+page+'&cari='+textproduk+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.produks = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            });
                    
                this.$Progress.finish();
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableProduct' ) ) {
                        $('#TableProduct').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    $("#TableProduct").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,
                    });  
            },
            editModal(product){
                this.editmode = true;
                $(".form-control").removeClass('is-invalid');
                this.form.reset();
                $('#ModalProduk').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                $(".form-control").removeClass('is-invalid');
                this.form.reset();
                $('#ModalProduk').modal('show');
            },
            loadAkses(){  
                    if (typeof aksesproduk != 'undefined') {
                                        this.akses_read     = aksesproduk.akses_read;
                                        this.akses_create   = aksesproduk.akses_create;
                                        this.akses_update   = aksesproduk.akses_update;
                                        this.akses_delete   = aksesproduk.akses_delete; 
                        }
                        else{ 
                                     axios.get("api/getakses", { params: { slug: 'produk' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        window.aksesproduk = data.akses;                             
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                            }
            },
            loadProduk(){
                        axios.get("api/produk").
                                then(({ data }) => {
                                    this.CekDatatable();
                                    this.produks = data.data;
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                        this.getUoms();
                                    })
                                    this.totalpage = data.data.last_page;
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
            },
            getUoms(){
                        axios.get("api/get-uoms").
                                then(({ data }) => {
                                    this.uoms = data.data;
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
            },
          
            createProduct(){
                this.$Progress.start();
                this.form.post('api/produk')
                    .then((response)=>{
                        if(response.data.type=='sukses'){
                                $('#ModalProduk').modal('hide');
                                Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                });
                                this.Cari();
                        }
                        else{
                                Swal.fire({icon    : 'error', title   : response.data.message,})
                                this.$Progress.finish();
                        }
                    })
                    .catch(function(error) {
                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                        });
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/produk/'+this.form.id_product)
                .then((response)=>{
                        if(response.data.type=='sukses'){
                                $('#ModalProduk').modal('hide');
                                Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                });
                                this.Cari();
                        }
                        else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                    })
                .catch(function(error) {
                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                        });

            },            
            deleteProduk(id_product,nm_product){
                Swal.fire({
                    title: 'Yakin hapus '+nm_product+' ?',
                    text: "setelah dihapus tidak dapat dikembalikan",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, hapus!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/produk/'+id_product)
                                .then((response)=>{
                                    if(response.data.type=='sukses'){
                                            Toast.fire({
                                                    icon: 'success',
                                                    title: response.data.message
                                            });
                                            this.Cari();
                                    }
                                    else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
                         }
                    })
            }

        },
        mounted() {
            
        },
        created() {

            this.$Progress.start();
            this.loadAkses();
            this.loadProduk();
            this.$Progress.finish();
        }
    }
</script>
