<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 text-center" style="padding-bottom:25px;padding-top:25px"><h1>Sales Order Shufu</h1></div>
                <div class="col-12 col-sm-6 col-md-4" v-for="menus in menus" :key="menus.id_menu">
                    <router-link :to="{path:'/'+menus.slug_menu}" class="a-class">
                        <div class="info-box">
                            <span :class="'info-box-icon bg-'+menus.color_menu+' elevation-1'"><i :class="menus.icon_menu"></i></span>
                            <div class="info-box-content">
                                <h3 class="info-box-text">{{menus.nm_menu}}</h3>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="clearfix hidden-md-up"></div>
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order';
                }
            },
        },
        data () {
            return {
                menus       : [],
            }
        },
        methods: {
            LoadMenu(){
                        if (typeof dmenus != 'undefined') {this.menus = dmenus}
                        else{ axios.get("api/getmenu").then(({ data }) => {
                                        this.menus = data.menu; 
                                        window.dmenus = data.menu;
                                        windows.ppndefault = "11"                             
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                                }
                          
                },             
        },

        mounted() {
        },

        created() {
            this.$Progress.start();
            this.LoadMenu();
            this.$Progress.finish();
        }
    }
</script>
