<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
            <div class="card minh-65">
              <div class="card-header">
                <h3 class="card-title">Data Customer</h3>

                <div class="card-tools">
                  
                  <button v-if="akses_create" type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Tambah
                  </button>
                </div>
              </div>
              <div class="row margin-remove">
                    <div class="col-6 p-5px">
                        <div id="TableCustomer_filter" class="dataTables_filter">
                            <label>Cari :<input ref="myInput" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="id/nama toko/PIC" aria-controls="TableCustomer"></label>
                            
                        </div>
                    </div>
                    <div class="col-6 p-5px text-right">
                        <label class="margin-remove">Tampil :</label>
                        <div>
                                <select ref="optampil" @change="Cari" v-model="perrow">
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                        </div>
                    </div>
                </div>
              <!-- /.card-header -->
              <div class="card-body padding-remove">
                <table class="table table-striped table-bordered" id="TableCustomer">
                  <thead>
                    <tr>
                        <th data-priority="1">ID <a id="btnsid" href="#" @click="short('id_customer',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Nama Toko<a id="btnsname" href="#" @click="short('nm_toko',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Nama PIC<a id="btnsname" href="#" @click="short('nm_customer',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Alamat</th>
                        <th>No.Hp</th>
                        <th data-priority="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="customers in customers.data" :key="customers.id_customer">                        
                        <td>{{customers.id_customer}}</td>
                        <td class="text-capitalize">{{customers.nm_toko}}</td>
                        <td class="text-capitalize">{{customers.nm_customer}}</td>
                        <td>{{customers.alamat_toko}}</td>
                        <td>{{customers.hp_customer}}</td>
                        <td>
                            <a v-if="akses_update" href="#" class="btn-menu" @click="editModal(customers)">
                                <i class="fa fa-edit blue"></i>
                            </a>
                            
                            <a v-if="akses_delete" href="#" class="btn-menu" @click="deleteCustomer(customers.id_customer , customers.nm_toko)">
                                <i class="fa fa-trash red"></i>
                            </a>
                        </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer p-5px">                   
                   <vue-paginate-al :totalPage="totalpage" @btnClick="Cari"></vue-paginate-al>
                   <div>           
                                <router-link to="/dashboard" type="button" class="btn btn-back">
                                            <i class="fas fa-chevron-left"></i> Kembali
                                </router-link>
                    </div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Tambah Customer</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Customer</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createCustomer"> -->

                <form @submit.prevent="editmode ? updateCustomer() : createCustomer()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>ID  <code>*</code></label>
                            <input v-model="form.id_customer" type="text" name="id_customer"
                                :readonly="editmode"
                                placeholder="input id customer . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('id_customer') }">
                            <has-error :form="form" field="id_customer"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Nama Toko <code>*</code></label>
                            <input v-model="form.nm_toko" type="text" name="nm_toko"
                                placeholder="input nama toko . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nm_toko') }">
                            <has-error :form="form" field="nm_toko"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Nama PIC <code>*</code></label>
                            <input v-model="form.nm_customer" type="text" name="nm_customer"
                                placeholder="input nama PIC . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nm_customer') }">
                            <has-error :form="form" field="nm_customer"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Alamat <code>*</code></label>
                            <input v-model="form.alamat_toko" type="text" name="alamat_toko"
                                placeholder="input alamat toko . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('alamat_toko') }">
                            <has-error :form="form" field="alamat_toko"></has-error>
                        </div>
                        <div class="form-group">
                            <label>No.HP <code>*</code></label>
                            <input v-model="form.hp_customer" type="number" name="hp_customer"
                                placeholder="input nomor hp . . ."
                                class="form-control" :class="{ 'is-invalid': form.errors.has('hp_customer') }">
                            <has-error :form="form" field="hp_customer"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Customer';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                customers   : [],
                akses_read: 0,
                akses_create: 0,
                akses_update: 0,
                akses_delete: 0,
                form        : new Form({
                                id_customer : '',
                                nm_toko     : '',
                                nm_customer : '',
                                alamat_toko : '',
                                hp_customer : '',
                            })
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    console.log(shorting+" - "+shortby);
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    console.log(shorting+" - "+shortby);
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = 1){
                let textcustomer = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;
                
                this.$Progress.start();
                axios.get('api/customer?page='+page+'&cari='+textcustomer+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.customers = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            });
                    
                this.$Progress.finish();
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableCustomer' ) ) {
                        $('#TableCustomer').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    $("#TableCustomer").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,
                    });  
            },
            editModal(customer){
                this.editmode = true;
                $(".form-control").removeClass('is-invalid');
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(customer);
            },
            newModal(){
                this.editmode = false;
                $(".form-control").removeClass('is-invalid');
                this.form.reset();
                $('#addNew').modal('show');
            },
            loadAkses(){  
                    if (typeof aksescustomer != 'undefined') {
                                        this.akses_read     = aksescustomer.akses_read;
                                        this.akses_create   = aksescustomer.akses_create;
                                        this.akses_update   = aksescustomer.akses_update;
                                        this.akses_delete   = aksescustomer.akses_delete; 
                        }
                        else{ 
                                     axios.get("api/getakses", { params: { slug: 'customer' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        window.aksescustomer = data.akses;                             
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                            }
            },
            loadCustomer(){
                        axios.get("api/customer").
                                then(({ data }) => {
                                    this.CekDatatable();
                                    this.customers = data.data;
                                    console.log(this.customers)
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                    })
                                    this.totalpage = data.data.last_page;
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
            },
          
            createCustomer(){
                this.$Progress.start();
                this.form.post('api/customer')
                    .then((response)=>{
                        if(response.data.type=='sukses'){
                                $('#addNew').modal('hide');
                                Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                });
                                this.Cari();
                        }
                        else{
                                Swal.fire({icon    : 'error', title   : response.data.message,})
                                this.$Progress.finish();
                        }
                    })
                    .catch(function(error) {
                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                        });
            },
            updateCustomer(){
                this.$Progress.start();
                this.form.put('api/customer/'+this.form.id_customer)
                .then((response)=>{
                        if(response.data.type=='sukses'){
                                $('#addNew').modal('hide');
                                Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                });
                                this.Cari();
                        }
                        else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                    })
                .catch(function(error) {
                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                        });

            },            
            deleteCustomer(id_customer,nm_customer){
                Swal.fire({
                    title: 'Yakin hapus '+nm_customer+' ?',
                    text: "setelah dihapus tidak dapat dikembalikan",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, hapus!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/customer/'+id_customer)
                                .then((response)=>{
                                    if(response.data.type=='sukses'){
                                            Toast.fire({
                                                    icon: 'success',
                                                    title: response.data.message
                                            });
                                            this.Cari();
                                    }
                                    else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                                })
                                .catch(function(error) {
                                    if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                    else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                });
                         }
                    })
            }

        },
        mounted() {
            //this.$refs.myInput.focus();
        },
        created() {

            this.$Progress.start();
            this.loadAkses();
            this.loadCustomer();
            this.$Progress.finish();
        }
    }
</script>
