<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row" v-show="!isBlock">
  
              <div class="col-12 padding-remove">          
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" v-show="!editmode">Tambah Pengiriman </h3>
                            <h3 class="card-title" v-show="editmode">Edit Pengiriman </h3>
                        </div>
                        <!-- /.card-header -->
                        
                            <form style="padding-top: 10px;" @submit.prevent="editmode ? UpdateKirim() : SaveKirim()">
                                <div class="card-body padding-remove minh-65">
                                    <div class="row margin-remove">
                                            <div class="col-md-6 form-horizontal">                                            
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">No. SPPB</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.no_sppb}}</label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">No. Surat Pesanan</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.no_sp}}</label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Customer</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.nm_toko}}</label>
                                                        <input v-model="form.customer_id" hidden>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Tanggal Pesanan</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.tgl_pesanan}}</label>
                                                    </div>
                                            </div> 
                                            <div class="col-md-6 form-horizontal">  
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Renc.Kirim</label>
                                                        <label class="col-7 margin-bottom-remove">: <a :class="form.status_pesanan==3 ? BgDate(form.tgl_renckirim) : 'a-text'">{{form.tgl_renckirim}}</a></label>
                                                    </div> 
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Catatan Sales</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.catatan_sales}}</label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Catatan Admin</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.catatan_admin}}</label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Catatan Akunting 1</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.catatan_akt1}}</label>
                                                    </div>
                                            </div>
                                    </div>
                                    <br>
                                    <div class="row margin-remove border-top">
                                            <div class="col-md-6 form-horizontal">   
                                                <div class="form-group row">
                                                    <label class="col-5" >Nomor <code>*</code></label>
                                                    <div class="col-7">
                                                        <input v-model="form.no_pengiriman" type="text" name="no_pengiriman"
                                                            placeholder="nomor surat jalan . . ."
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('no_pengiriman') }">
                                                        <has-error :form="form" field="no_pengiriman"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-5" >Catatan Gudang</label>
                                                    <div class="col-7">
                                                        <input v-model="form.catatan_gudang" type="text" name="catatan_gudang"
                                                            placeholder="catatan . . ."
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('catatan_gudang') }">
                                                        <has-error :form="form" field="catatan_gudang"></has-error>
                                                    </div>
                                                </div>                                                
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5">Tgl Pengiriman  <code>*</code></label>
                                                    <div class="col-7" >
                                                        <date-picker v-model="form.tgl_pengiriman" valueType="format" format="DD-MM-YYYY"
                                                            :class="{ 'is-invalid': form.errors.has('tgl_pengiriman') }"
                                                            style="width:100%!important">
                                                        </date-picker>
                                                        <has-error :form="form" field="tgl_pengiriman"></has-error>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="col-md-6 form-horizontal">  
                                                    <div class="form-group row">
                                                        <label class="col-5">Nama Supir <code>*</code></label>
                                                        <div class="col-7">
                                                            <input v-model="form.supir_kirim" type="text" name="supir_kirim"
                                                                placeholder="nama supir . . ."
                                                                class="form-control" :class="{ 'is-invalid': form.errors.has('supir_kirim') }">
                                                            <has-error :form="form" field="supir_kirim"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-5">Nomor Plat <code>*</code></label>
                                                        <div class="col-7">
                                                            <input v-model="form.vehicle_number" type="text" name="vehicle_number"
                                                                placeholder="nomor plat mobil . . ."
                                                                class="form-control" :class="{ 'is-invalid': form.errors.has('vehicle_number') }">
                                                            <has-error :form="form" field="vehicle_number"></has-error>
                                                        </div>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="card-header">
                                            <h3 class="card-title">Item</h3>
                                            <div class="card-tools">                
                                                <button v-if="akses_create" type="button" class="btn btn-sm btn-primary" @click="newModal">
                                                    <i class="fa fa-plus-square"></i>
                                                    Tambah
                                                </button>
                                            </div>
                                    </div>
                                    <div class="div-tabel">
                                        <table class="table table-striped table-bordered margin-bottom-remove" id="TableDproduk">
                                            <thead>
                                                <tr>
                                                    <th data-priority="1">Nama Produk</th>
                                                    <th>Qty kirim</th>
                                                    <th>UOM</th>
                                                    <th data-priority="2">Act</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(details,index) in details" :key="index">
                                                    <td class="text-capitalize">
                                                            {{details.nm_product}}
                                                    </td>
                                                    <td>{{details.qty_kirim}}</td>
                                                    <td>{{details.nm_uom}}</td>
                                                    <td>
                                                        <a v-if="akses_update" class="btn-menu" @click="editModal(index,details)"
                                                            data-toggle="tooltip" data-placement="top" title="Edit">
                                                            <i class="fa fa-edit blue"></i>
                                                        </a>
                                                        <a v-if="akses_update" class="btn-menu" @click="deleteItem(details.product_id,details.nm_product)" 
                                                                data-toggle="tooltip" data-placement="top" title="Hapus">
                                                                <i class="fa fa-trash red"></i>
                                                        </a>
                                                    </td>
                                                
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                                <div class="card-footer">      
                                        <router-link :to="prevRoutePath" type="button" class="btn btn-back">
                                                    <i class="fas fa-chevron-left"></i> Kembali
                                        </router-link>                                
                                        <button v-show="form.status_pesanan==3 || form.status_pesanan==4 || form.status_pesanan==15 && (akses_create || akses_update)" type="submit" class="btn btn-primary" :disabled='isDisabled'><i class="fas fa-save"></i> Simpan</button>
                                        <router-link :to="{path:'/pengirimancetak/'+id_pengiriman}" target="_blank" type="button" v-if="editmode && (akses_create || akses_update)" class="btn btn-primary">
                                            <i class="fas fa-print"></i> Cetak
                                        </router-link>   
                                </div>
                            </form>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
          </div>
          <div v-show="isBlock">
                    <no-akses></no-akses>
            </div>
      </div>
          <!-- Modal -->
          <div class="modal fade" id="ModalItems" role="dialog" aria-labelledby="ModalItems" aria-hidden="true" data-keyboard="false" data-backdrop="static">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" >Item</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>  
                        <form @submit.prevent="editDetail ? UpdateItem() : TambahItem()">
                            <div class="modal-body">
                                    <div class="form-group row">
                                                <label class="col-sm-4">Pilih Item  <code>*</code></label>
                                                <div class="col-sm-8">
                                                    <Select2 id="product_id" class="full-width" v-model="ItemKirim.product_id"
                                                        :options="items" 
                                                        :settings="{    settingOption: items.id_product, 
                                                                        settingOption: items.nm_product, 
                                                                        settingOption: items.nm_uom, 
                                                                        settingOption: items.qty_open, 
                                                                        settingOption: items.qty_kirim, 
                                                                        }" 
                                                        :class="{ 'is-invalid': form.errors.has('product_id') }" 
                                                        :disabled=editDetail
                                                        @select="SetUom($event)"
                                                        required                                                
                                                    />
                                                    <has-error :form="form" field="product_id"></has-error>
                                                </div>
                                    </div>
                                    <div class="form-group row margin-bottom-remove">                                        
                                                <label class="col-sm-4">Qty Kirim <code>*</code></label>
                                                <div class="col-sm-8">
                                                        <input v-model="ItemKirim.qty_kirim" type="number" name="qty_kirim" required
                                                            placeholder="input qty . . ."
                                                            class="form-control" :class="{ 'is-invalid': ItemKirim.errors.has('qty_kirim') }"
                                                            autocomplete="off"
                                                            :min=1 :max=maxvalue>                                           
                                                        <has-error :form="form" field="qty_kirim"></has-error> 
                                                </div>                                                        
                                    </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Tambah</button>
                            </div>
                        </form>
                  </div>
              </div>
          </div>
    </section>
  </template>
  
  <script>
  
        import Select2 from 'v-select2-component';
        import history from '../history.vue';
        import DatePicker from 'vue2-datepicker';
        import 'vue2-datepicker/index.css';
        import moment from "moment";
  
      export default {
          watch: {
              $route: {
                  immediate: true,
                  handler(to, from) {
                      document.title = to.meta.title || 'Sales Order - Pengiriman';
                  }
              },
          },
          components:{
                Select2,
                history,
                DatePicker,
          },
          data () {
              return {  
                  editmode        : false,
                  editDetail      : false,
                  isDisabled      : false,
                  akses_read      : 0,
                  akses_create    : 0,
                  akses_update    : 0,
                  akses_delete    : 0,
                  akses_approve   : 0,
                  prevRoute       : '/dashboard',
                  isBlock         : false,
                  historis        : [],
                  pesanans        : [],
                  items           : [],
                  details         : [],
                  maxopen         : 0,
                  maxkirim        : 0,
                  maxvalue        : 0,
                  id_pengiriman   : null,
                  form            : new Form({
                                        id_sp               : '',
                                        sp_id               : '',
                                        customer_id         : '',
                                        no_sp               : '',
                                        no_sppb             : '',
                                        nm_toko             : '',
                                        tgl_pesanan         : '',
                                        catatan_sales       : '',
                                        catatan_admin       : null,
                                        status_pesanan      : 3,
                                        id_pengiriman       : null,
                                        no_pengiriman       : '',
                                        sppb_id             : '',
                                        supir_kirim         : '',
                                        vehicle_number      : '',
                                        tgl_pengiriman      : null,
                                        tgl_renckirim       : null,
                                        catatan_gudang      : null,
                                    }),
                  ItemKirim        : new Form({
                                        index           : '',
                                        sp_id           : '',
                                        product_id      : '',
                                        kd_product      : '',
                                        nm_product      : '',
                                        qty_pesanan     : '0',
                                        qty_kirim       : '0',
                                        qty_open        : '0',
                                        nm_uom          : '',
                                  })
              }
          },
          
         beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },          
        computed: {
            prevRoutePath() {
                        if(this.prevRoute.path =='/'){return '/dashboard'}
                        else{return this.prevRoute}
                    },
        },
        methods: {
                loadAkses(){  
                            if (typeof aksespengiriman != 'undefined') {
                                            this.akses_read     = aksespengiriman.akses_read;
                                            this.akses_create   = aksespengiriman.akses_create;
                                            this.akses_update   = aksespengiriman.akses_update;
                                            this.akses_delete   = aksespengiriman.akses_delete; 
                                            this.akses_approve  = aksespengiriman.akses_approve;
                            }
                            else{ 
                                        axios.get("../../api/getakses", { params: { slug: 'pengiriman' }}).then(({ data }) => {
                                            this.akses_read     = data.akses.akses_read;
                                            this.akses_create   = data.akses.akses_create;
                                            this.akses_update   = data.akses.akses_update;
                                            this.akses_delete   = data.akses.akses_delete; 
                                            this.akses_approve  = data.akses.akses_approve; 
                                            window.aksespengiriman = data.akses;                                                                    
                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });
                                }
                },
                LoadHeader(id){
                    var id_sp   = id;
                    //var no_sp   = text;
                    axios.get("../../api/pengirimanheader", { params: { id_sp: id_sp }}).then(({ data }) => {
                                                var ResDetails                = data.data;
                                                this.form.sp_id               = id_sp;
                                                this.form.sppb_id             = ResDetails.id_sppb;
                                                this.form.no_sppb             = ResDetails.no_sppb;
                                                this.form.no_sp               = ResDetails.no_sp;
                                                this.form.customer_id         = ResDetails.customer_id;
                                                this.form.nm_toko             = ResDetails.nm_toko;
                                                this.form.tgl_pesanan         = ResDetails.tgl_pesanan;
                                                this.form.catatan_sales       = ResDetails.catatan_sales;
                                                this.form.catatan_admin       = ResDetails.catatan_admin;
                                                this.form.catatan_akt1        = ResDetails.catatan_akt1;
                                                this.form.status_pesanan      = ResDetails.status_pesanan ;
                                                
                                                if(ResDetails.tgl_renckirim != null)
                                                    { this.form.tgl_renckirim    = moment(ResDetails.tgl_renckirim, "YYYY-MM-DD").format("DD-MM-YYYY"); }

                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });                
                },
                SetUom({id, text,nm_uom,qty_open,qty_kirim,kd_product}){
                    //console.log({id, text, nm_uom,qty_open,qty_kirim,kd_product})
                    this.ItemKirim.nm_uom           = nm_uom    
                    this.ItemKirim.qty_kirim        = qty_open
                    this.ItemKirim.kd_product       = kd_product
                    for(var x=0; x < this.items.length; x++){
                            if( this.items[x].product_id  == id ){
                                    var qty_open            = parseInt(this.items[x].qty_open)
                                    this.maxvalue           = qty_open
                            }
                    }
                },
                LoadProduct(id_sp){
                    axios.get("../../api/pengirimanproduk", { params: { id_sp: id_sp }}).then(({ data }) => {
                                                this.items = data.data;
                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });                
                },
                LoadPengiriman(id_pengiriman){
                    this.editmode   = true;
                    axios.get("../../api/apipengiriman/"+id_pengiriman).then(({ data }) => {
                                                var ResKirim                = data.data[0]; 
                                                this.form.no_pengiriman     = ResKirim.no_pengiriman
                                                this.form.supir_kirim       = ResKirim.supir_kirim
                                                this.form.vehicle_number    = ResKirim.vehicle_number
                                                this.form.catatan_gudang    = ResKirim.catatan_gudang
                                               
                                                if(ResKirim.tgl_pengiriman != null)
                                                    { this.form.tgl_pengiriman    = moment(ResKirim.tgl_pengiriman, "YYYY-MM-DD").format("DD-MM-YYYY"); }

                                                var ResItems        = data.data[1]; 
                                                this.details        = ResItems;
                                                for(var i=0; i < ResItems.length; i++){
                                                    //console.log(ResItems[i].kd_product+" "+ResItems[i].qty_kirim);
                                                    for(var x=0; x < this.items.length; x++){
                                                        if( this.items[x].product_id  == ResItems[i].product_id ){
                                                            var qty_open            = parseInt(this.items[x].qty_open)
                                                            var qty_kirim           = parseInt(ResItems[i].qty_kirim)
                                                            this.items[x].qty_open  = qty_open +  qty_kirim     
                                                        }
                                                    }
                                                }
                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });                
                },
                TambahItem(){
                    var data        = $('#product_id').select2('data')
                    var selecttext  = data[0].text;
                    var product_id  = this.ItemKirim.product_id;

                    for(var i=0; i < this.details.length; i++){
                        if( this.details[i].product_id  == product_id ){
                            Toast.fire({
                                            icon: 'warning',
                                            title: 'Item sudah ada , '+selecttext
                                    });
                            return true;
                        }
                    }
                    this.details.push({
                                        product_id      : product_id ,
                                        nm_product      : selecttext,
                                        kd_product      : this.ItemKirim.kd_product,
                                        qty_kirim       : this.ItemKirim.qty_kirim,
                                        nm_uom          : this.ItemKirim.nm_uom,
                    });  
                    $('#ModalItems').modal('hide');         
                },
                UpdateItem(){
                            //console.log(this.items[1])
                            this.details[this.ItemKirim.index].qty_kirim = this.ItemKirim.qty_kirim
                            $('#ModalItems').modal('hide');  
                        
                },
                newModal(){
                        $(".form-control").removeClass('is-invalid');
                        this.ItemKirim.reset();
                        this.editDetail = false;
                        $('#ModalItems').modal('show');
                },
                editModal(index,details){
                        $(".form-control").removeClass('is-invalid');
                        this.ItemKirim.reset();
                        for(var x=0; x < this.items.length; x++){
                                if( this.items[x].product_id  == details.product_id ){
                                        var qty_open            = parseInt(this.items[x].qty_open)
                                        this.maxvalue           = qty_open
                                }
                        }
                        this.ItemKirim.fill(details);
                        this.ItemKirim.index             = index;
                        this.editDetail = true;
                        $('#ModalItems').modal('show');
                },
                
                deleteItem(product_id,nm_product){
                    Swal.fire({
                        title: 'Apakah anda Yakin ?',
                        text: "Hapus "+nm_product,
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Hapus',
                        cancelButtonText: 'Close'
                        }).then((result) => {
                                if (result.value) {
                                    let adetails = this.details
                                    this.details = adetails.filter(adetails => adetails.product_id != product_id);
                                }
                                else{}
                            })
                },
                    
                SaveKirim() {
                                this.isDisabled=true;
                                
                                    this.$Progress.start();
                                    this.form.post('../../api/apipengiriman', { params: { details : this.details }})
                                    .then((response)=>{                        
                                        if(response.data.type=='sukses'){
                                                Swal.fire({
                                                    icon    : 'success',
                                                    title   : response.data.message
                                                }).then((oke) => {
                                                        this.$router.replace('/pengiriman/'+this.form.sp_id)
                                                });
                                        }
                                        else{
                                                Swal.fire({
                                                    icon    : 'error',
                                                    title   : response.data.message,
                                                })
                                        }
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })
                                    .catch(()=>{
                                        Toast.fire({
                                            icon: 'error',
                                            title: 'Gagal! harap isi form dengan benar'
                                        });
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })                 
                },
                UpdateKirim() {
                                this.isDisabled=true;
                                    this.$Progress.start();
                                    this.form.put('../../api/apipengiriman/'+this.form.id_pengiriman, { params: { details : this.details }})
                                    .then((response)=>{                        
                                        if(response.data.type=='sukses'){
                                                Swal.fire({
                                                    icon    : 'success',
                                                    title   : response.data.message
                                                }).then((oke) => {
                                                        this.$router.replace('/pengiriman/'+this.form.sp_id)
                                                });
                                        }
                                        else{
                                                Swal.fire({
                                                    icon    : 'error',
                                                    title   : response.data.message,
                                                })
                                        }
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })
                                    .catch(()=>{
                                        Toast.fire({
                                            icon: 'error',
                                            title: 'Gagal! harap isi form dengan benar'
                                        });
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })  
                                                  
                },

          },
        mounted() {            
                  //this.loadItems();
          },
        created() {
              this.$Progress.start();
              this.loadAkses();
              var id              = this.$route.params.id_sp;
              this.id_pengiriman   = this.$route.params.id_pengiriman;
              this.LoadHeader(id)              
              this.LoadProduct(id);
              this.$nextTick(() => {
                                
                    if(this.id_pengiriman != undefined)
                    { this.LoadPengiriman(this.id_pengiriman); this.form.id_pengiriman = this.id_pengiriman }
                })
              this.$Progress.finish();
          }
      }
  </script>
  