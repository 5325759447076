<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row" v-show="!isBlock">
  
              <div class="col-12 padding-remove">          
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title text-uppercase margin-bottom-remove">Pengiriman</h3>
                            <div class="card-tools">               
                                <router-link  v-if="akses_create && (form.status_pesanan==3 || form.status_pesanan==4) " :to="{path:'/pengiriman/'+form.sp_id+'/tambah'}" type="button" class="btn btn-sm btn-primary">
                                    <i class="fa fa-plus-square"></i> Tambah
                                </router-link>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        
                                <div class="card-body padding-remove minh-65">
                                    <div class="row margin-remove">
                                            <div class="col-md-6 form-horizontal">                                            
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">No. SPPB</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.no_sppb}}</label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">No. Surat Pesanan</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.no_sp}}</label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Customer</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.nm_toko}}</label>
                                                        <input v-model="form.customer_id" hidden>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Tanggal Pesanan</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.tgl_pesanan}}</label>
                                                    </div>
                                            </div> 
                                            <div class="col-md-6 form-horizontal">   
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Renc.Kirim</label>
                                                        <label class="col-7 margin-bottom-remove">: <a :class="form.status_pesanan==3 ? BgDate(form.tgl_renckirim) : 'a-text'">{{form.tgl_renckirim}}</a></label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Catatan Sales</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.catatan_sales}}</label>
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Catatan Admin</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.catatan_admin}}</label>
                                                    </div>                                                    
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Catatan Akunting 1</label>
                                                        <label class="col-7 margin-bottom-remove">: {{form.catatan_akt1}}</label>
                                                    </div>
                                            </div>
                                    </div>
                                    <br>
                                    <div class="card-header">
                                            <h3 class="card-title text-uppercase margin-bottom-remove">Item</h3>
                                    </div>
                                    <div class="div-tabel">
                                        <table class="table margin-bottom-remove" id="TableDproduk">
                                            <thead>
                                                <tr>
                                                    <th data-priority="1">Nama Produk</th>
                                                    <th>Qty</th>
                                                    <th>UOM</th>
                                                    <th>Qty Open</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(details,index) in details" :key="index">
                                                    <td class="text-capitalize">
                                                            {{details.nm_product}}
                                                            <br v-if="details.note_sales !== null">
                                                            <label v-if="details.note_sales !== null" class="text-italic">Catatan : {{details.note_sales}}</label>
                                                    </td>
                                                    <td>{{details.qty_pesanan}}</td>
                                                    <td>{{details.nm_uom}}</td>
                                                    <td :class="isRed(details.qty_open)">{{details.qty_open}}</td>
                                                
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>     
                                              
                                    <tracking :datahistory.sync="historykirim" 
                                            :akses_create="this.akses_create"
                                            :akses_update="this.akses_update"
                                            :akses_delete="this.akses_delete" 
                                            :status_pesanan="this.form.status_pesanan">
                                    </tracking>
                                    
                                </div>
                                <div class="card-footer">      
                                        <router-link :to="prevRoutePath" type="button" class="btn btn-back">
                                                    <i class="fas fa-chevron-left"></i> Kembali
                                        </router-link>                                
                                </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
          </div>
          <div v-show="isBlock">
                    <no-akses></no-akses>
            </div>
            
      </div>
    </section>
  </template>
  
  <script>
  
    import Select2 from 'v-select2-component';
    import VuePaginateAl from 'vue-paginate-al';
    import history from '../history.vue'
    import tracking from '../tracking.vue'
    import moment from "moment";
  
      export default {
          watch: {
              $route: {
                  immediate: true,
                  handler(to, from) {
                      document.title = to.meta.title || 'Sales Order - Pengiriman';
                  }
              },
          },
          components:{
                  Select2,
                  VuePaginateAl,
                  history,
                  tracking,
          },
          data () {
              return {  
                  editmode        : false,
                  isDisabled      : false,
                  isSpin          : false,
                  prevRoute       : '/dashboard',
                  akses_read      : 0,
                  akses_create    : 0,
                  akses_update    : 0,
                  akses_delete    : 0,
                  akses_approve   : 0,
                  isBlock         : false,
                  historis        : [],
                  historykirim    : [],
                  pesanans        : [],
                  items           : [],
                  details         : [],
                  form            : new Form({
                                        sp_id               : '',
                                        id_sppb             : '',
                                        customer_id         : '',
                                        no_sp               : '',
                                        no_sppb             : '',
                                        nm_toko             : '',
                                        tgl_pesanan         : '',
                                        tgl_renckirim       : '',
                                        catatan_sales       : '',
                                        catatan_admin       : null,
                                        status_pesanan      : 3,
                                  }),
                  formItem        : new Form({
                                        index               : '',
                                        sp_id               : '',
                                        product_id      : '',
                                        kd_product      : '',
                                        nm_product      : '',
                                        qty_pesanan     : '0',
                                        qty_kirim       : '0',
                                        qty_open        : '0',
                                        nm_uom          : '',
                                        note_sales      : null,
                                  })
              }
          },
          
          beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevRoutePath() {
                        if(this.prevRoute.path =='/' || this.prevRoute.path==undefined ){return '/dashboard'}
                        else{
                            var string      = this.prevRoute.path
                            var substring   = "pengiriman"
                            var isinclude   = string.includes(substring)
                            if(isinclude ==true){
                                    return '/sppb-gudang'
                            }
                            else{
                                    return this.prevRoute
                            }
                        }
                    },
        },
 
        methods: {
              loadAkses(){  
                          if (typeof aksespengiriman != 'undefined') {
                                          this.akses_read     = aksespengiriman.akses_read;
                                          this.akses_create   = aksespengiriman.akses_create;
                                          this.akses_update   = aksespengiriman.akses_update;
                                          this.akses_delete   = aksespengiriman.akses_delete; 
                                          this.akses_approve  = aksespengiriman.akses_approve;
                                          if(this.akses_read==0){this.isBlock=true}
                          }
                          else{ 
                                       axios.get("../api/getakses", { params: { slug: 'pengiriman' }}).then(({ data }) => {
                                          this.akses_read     = data.akses.akses_read;
                                          this.akses_create   = data.akses.akses_create;
                                          this.akses_update   = data.akses.akses_update;
                                          this.akses_delete   = data.akses.akses_delete; 
                                          this.akses_approve  = data.akses.akses_approve; 
                                          window.aksespengiriman = data.akses;   
                                          if(this.akses_read==0){this.isBlock=true}                                                                 
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });
                              }
              },
              LoadDetails(id){
                  var id_sp   = id;
                  //var no_sp   = text;
                  axios.get("../api/getsppbdetails", { params: { id_sp: id_sp }}).then(({ data }) => {
                                            var ResDetails                = data.data[0];
                                            this.form.sp_id               = id_sp;
                                            this.form.id_sppb             = ResDetails.id_sppb;
                                            this.form.no_sppb             = ResDetails.no_sppb;
                                            this.form.no_sp               = ResDetails.no_sp;
                                            this.form.customer_id         = ResDetails.customer_id;
                                            this.form.nm_toko             = ResDetails.nm_toko;
                                            this.form.tgl_pesanan         = ResDetails.tgl_pesanan;
                                            this.form.catatan_sales       = ResDetails.catatan_sales;
                                            this.form.catatan_admin       = ResDetails.catatan_admin;
                                            this.form.catatan_akt1        = ResDetails.catatan_akt1;
                                            this.form.status_pesanan      = ResDetails.status_pesanan;
                                            if(ResDetails.tgl_renckirim  != null)
                                                {this.form.tgl_renckirim      = moment(ResDetails.tgl_renckirim, "YYYY-MM-DD").format("DD-MM-YYYY");}

                                            var ResItems                = data.data[1];                 
                                            this.details                = [];
                                                  for(var i=0; i < ResItems.length; i++){
                                                      let qty_open  = ResItems[i].qty_pesanan - ResItems[i].qty_kirim
                                                      this.details.push({
                                                                          sp_id             : ResItems[i].sp_id ,
                                                                          product_id        : ResItems[i].product_id ,
                                                                          kd_product        : ResItems[i].kd_product ,
                                                                          nm_product        : ResItems[i].kd_product+' - '+ResItems[i].nm_product,
                                                                          note_sales        : ResItems[i].note_sales,
                                                                          qty_pesanan       : ResItems[i].qty_pesanan,
                                                                          nm_uom            : ResItems[i].nm_uom,
                                                                          qty_kirim         : ResItems[i].qty_kirim,
                                                                          qty_open          : qty_open,  
                                                                          hrg_item          : ResItems[i].hrg_item,  
                                                                          Thrg_item          : ResItems[i].hrg_item,  
                                                                          diskonrp_item     : ResItems[i].diskonrp_item,  
                                                                          diskonps_item     : ResItems[i].diskonps_item,  
                                                                          totalhrg_item     : ResItems[i].totalhrg_item,  
                                                                          Ttotalhrg_item     : ResItems[i].totalhrg_item,  
                                                      })
                                                  } 
                                            
                                            this.HistoryPengiriman(this.form.id_sp, this.form.id_sppb);
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });                
              },

          },
        mounted() {            
          },
        created() {
              this.$Progress.start();
              this.loadAkses();
              this.$nextTick(() => {
                            var id              = this.$route.params.id_sp;
                            this.LoadDetails(id)
                           // this.LoadHistory('surat-pesanan',id);                            
                            this.form.id_sp     = id;
                            this.$Progress.finish();
                })
          }
      }
  </script>
  