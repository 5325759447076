<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
            <div class="card minh-65" v-show="!isBlock">
              <div class="card-header">
                    <h3 class="card-title">SPPB Gudang</h3>
                    <div class="card-tools">
                        <a type="button" class="btn btn-sm" data-toggle="modal" data-target="#ModalFilters" :class="GDfilters.status_pesanan>1 ? 'blue' : 'a-text'">
                                Filter <i class="fas fa-filter"></i>
                        </a>
                    </div>
              </div>
              <div class="row margin-remove">
                    <div class="col-6 p-5px">
                        <div id="TableSp_filter" class="dataTables_filter">
                            <label>Cari :<input ref="myInput" v-model="myInputtext" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="no SPPB/no SP/toko" aria-controls="TableSp"></label>
                            
                        </div>
                    </div>
                    <div class="col-6 p-5px text-right">
                        <label class="margin-remove">Tampil :</label>
                        <div>
                                <select ref="optampil" @change="Cari" v-model="perrow">
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                </select>
                        </div>
                    </div>
                </div>
              <!-- /.card-header -->
              <div class="card-body padding-remove">
                <table class="table table-striped table-bordered" id="TableSp">
                  <thead>
                    <tr>
                        <th data-priority="1">No. SP <a href="#" @click="short('no_sp',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th data-priority="4">Toko <a href="#" @click="short('nm_toko',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th data-priority="3">No. SPPB <a href="#" @click="short('no_sppb',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                        <th>Tgl.SP</th>
                        <th>Tgl.SPPB</th>
                        <th>Renc.Kirim</th>
                        <th>Surat Jalan</th>
                        <th>Status</th>
                        <th data-priority="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="sppbs in sppbs.data" :key="sppbs.id_sppb">
                        <td>{{sppbs.no_sp}}</td>
                        <td class="text-capitalize">{{sppbs.nm_toko}}</td>
                        <td>{{sppbs.no_sppb}}</td>
                        <td>{{sppbs.tgl_pesanan}}</td>
                        <td>{{sppbs.tgl_sppb}}</td>
                        <td :class="sppbs.status_pesanan==3 ? BgDate(sppbs.tgl_renckirim) : ''" >{{sppbs.tgl_renckirim}}</td>
                        <td>{{sppbs.no_pengiriman}}</td>
                        <td>{{StatusSP(sppbs.status_pesanan)}}</td>
                        <td>
                            <router-link v-if="akses_create || akses_update" :to="{path:'/pengiriman/'+sppbs.id_sp}" class="btn-menu"
                                data-toggle="tooltip" data-placement="top" title="Pengiriman">
                                <i class="fas fa-truck-moving navy"></i>
                            </router-link>
                            <a v-if="akses_approve && sppbs.status_pesanan==3 " class="btn-menu" @click="TolakPesanan(sppbs.id_sppb,sppbs.nm_toko)" 
                                data-toggle="tooltip" data-placement="top" title="Cancel Order">
                                <i class="fas fa-times-circle red"></i>
                            </a>
                        </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer p-5px">                   
                   <vue-paginate-al :totalPage="totalpage" :currentPage="PageGudang" @btnClick="Cari"></vue-paginate-al>
                   <div>           
                                <a @click="ToDashboard" type="button" class="btn btn-back">
                                                <i class="fas fa-chevron-left"></i> Kembali
                                    </a>
                        </div>
                </div>
            </div>
            <!-- /.card -->
            <div v-show="isBlock">
                <no-akses></no-akses>
            </div>
          </div>
        </div>       
    </div>

    <!-- Modal Filter-->
    <div class="modal modal-primary fade" id="ModalFilters" tabindex="-1" role="dialog" aria-labelledby="ModalFilters" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Filter</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="SubmitFilter()">
                    <div class="modal-body">                        
                        <div class="form-group">
                            <label>Status</label>
                            <select name="type" v-model="GDfilters.status_pesanan" id="type" class="form-control" :class="{ 'is-invalid': GDfilters.errors.has('type') }">
                                <option value="">Semua Status</option>
                                <option value="3">Diproses Admin</option>
                                <option value="4">Diproses Gudang</option>
                                <option value="5">Menunggu Akunting 1</option>
                                <option value="13">Ditolak Admin</option>
                                <option value="14">Ditolak Gudang</option>
                                <option value="15">Ditolak Akunting 1</option>
                            </select>
                            <has-error :form="GDfilters" field="type"></has-error>
                        </div>  
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success">Submit</button>
                    </div>
                  </form>
                </div>
            </div>
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - SPPB';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby         : 'pesanans.created_at',
                shorting        : 'desc',
                shortc          : 'desc',
                totalpage       : 1,
                perrow          : 10,   
                editmode        : false,
                sppbs           : {},
                akses_read      : 0,
                akses_create    : 0,
                akses_update    : 0,
                akses_delete    : 0,
                akses_approve   : 0,                
                PageGudang      : 1,
                myInputtext     :"",
                isBlock         : false,
                GDfilters       : new Form({
                                    status_pesanan      : "",
                                })
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = this.PageGudang){
                let textuser = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;         
                
                window.DTPageGudang   = page; 
                window.DTrowGudang    = perrow; 
                window.DTcariGudang   = textuser;              
                
                axios.get('api/sppbgudang?page='+page+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc+'&cari='+textuser+'&status_pesanan='+this.GDfilters.status_pesanan)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.sppbs = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            }).catch(function(error) {
                                                        console.log(error)
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                                    }
                                                });
                    
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#TableSp' ) ) {
                        $('#TableSp').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    var tabledata = $("#TableSp").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,     
                    });  
            },
            loadAkses(){  
                        if (typeof id_sppaksessppbgudang != 'undefined') {
                                        this.akses_read     = id_sppaksessppbgudang.akses_read;
                                        this.akses_create   = id_sppaksessppbgudang.akses_create;
                                        this.akses_update   = id_sppaksessppbgudang.akses_update;
                                        this.akses_delete   = id_sppaksessppbgudang.akses_delete; 
                                        this.akses_approve  = id_sppaksessppbgudang.akses_approve; 
                                        if(this.akses_read == '0'){ this.isBlock=true;}
                        }
                        else{ 
                                     axios.get("api/getakses", { params: { slug: 'sppb-gudang' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        this.akses_approve  = data.akses.akses_approve; 
                                        window.id_sppaksessppbgudang = data.akses;
                                        if(this.akses_read == '0'){ this.isBlock=true;}                             
                                    }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                    });
                            }
            },
            loadsppbs(){
                        if (typeof DTPageGudang != 'undefined') { 
                            if(DTPageGudang=="[object KeyboardEvent]"){this.PageGudang=1} else{this.PageGudang   = DTPageGudang;}  
                        }
                        else{ this.PageGudang = 1; }

                        if (typeof DTrowGudang != 'undefined') {this.perrow   = DTrowGudang; }
                        else{ this.perrow = 10;  }

                        if (typeof DTcariGudang != 'undefined') {var textcari   = DTcariGudang;this.myInputtext = DTcariGudang;}
                        else{ var textcari="";  }

                        axios.get("api/sppbgudang?page="+this.PageGudang+"&limit="+this.perrow+"&cari="+textcari).then(({ data }) => {
                                    this.CekDatatable();
                                    this.sppbs = data.data;
                                    
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                    })
                                    this.totalpage = data.data.last_page;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
            },        
            TolakPesanan(id_D,namaD){
                Swal.fire({
                    title: "Tolak Pesananan dari "+namaD,
                    text: "Tulis alasan menolak pesanan ",
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Tolak',
                    cancelButtonText: 'Close',
                    customClass: {
                        validationMessage: 'my-validation-message'
                    },
                    preConfirm: (value) => {
                        if (!value) {
                        Swal.showValidationMessage(
                            '<i class="fa fa-info-circle"></i>Alasan harus diisi'
                        )
                        }
                    }
                    }).then((result) => {
                           // console.log(result.value)
                         if (result.value) {
                                axios.post('api/sppb-gudangtolak',{  id_sppb: id_D, alasan : result.value })
                                        .then((response)=>{                        
                                                if(response.data.type=='sukses'){
                                                        Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.message,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                        this.Cari();
                                                }
                                                else{
                                                        Swal.fire({
                                                            icon    : 'error',
                                                            title   : response.data.message,
                                                        })
                                                }
                                            })
                                        .catch((data)=> {
                                            Swal.fire("Failed!", data.message, "warning");
                                        });
                         }
                    })
            },  
                 
            ToDashboard(){
                window.DTPageGudang   = 1; 
                window.DTrowGudang    = 10; 
                window.DTcariGudang   = ""; 
                this.$router.push('/dashboard')
            },
            
            SubmitFilter(){
                this.PageApproval                   = 1;
                this.Cari();
                window.DTGDstatus_pesanan     = this.GDfilters.status_pesanan;
                $('#ModalFilters').modal('hide');
            } 

        },
        mounted() {
                this.loadsppbs();            
                this.$Progress.finish()
        },
        created() {
                this.$Progress.start();
                this.loadAkses();
        },
    }
</script>
