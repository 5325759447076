export default [
    { path: '/akt1', component: require('./components/akunting/Akunting1.vue').default },    
    { path: '/akt1/:id_sp', component: require('./components/akunting/Detailakt1.vue').default },
    { path: '/akt2', component: require('./components/akunting/Akunting2.vue').default },    
    { path: '/akt2/:id_sp', component: require('./components/akunting/Detailakt2.vue').default },
    { path: '/approval', component: require('./components/akunting/Approval.vue').default },    
    { path: '/approval/:id_sp', component: require('./components/akunting/DetailApproval.vue').default },
    { path: '/cpayment', component: require('./components/payment/Cpayment.vue').default },
    { path: '/cpayment/tambah', component: require('./components/payment/CpaymentTambah.vue').default },
    { path: '/cpayment/:id_sp', component: require('./components/payment/CpaymentTambah.vue').default },
    { path: '/customer', component: require('./components/Customers.vue').default },
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/komisipayment', component: require('./components/payment/KomisiPayment.vue').default },
    { path: '/komisipayment/tambah', component: require('./components/payment/KomisiPayTambah.vue').default },
    { path: '/komisipayment/:id_kpayment', component: require('./components/payment/KomisiPayTambah.vue').default },
    { path: '/pegawai', component: require('./components/Pegawai.vue').default },
    //{ path: '/pengiriman', component: require('./components/pengiriman/Pengiriman.vue').default },
    //{ path: '/pengiriman/tambah', component: require('./components/pengiriman/PengirimanTambah.vue').default },
    { path: '/pengiriman/:id_sp', component: require('./components/pengiriman/Pengiriman.vue').default },
    { path: '/pengiriman/:id_sp/tambah', component: require('./components/pengiriman/PengirimanTambah.vue').default },
    { path: '/pengiriman/:id_sp/:id_pengiriman', component: require('./components/pengiriman/PengirimanTambah.vue').default },
    //{ path: '/pengirimancetak/:id_pengiriman', component: require('./components/pengiriman/CetakPengiriman.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/products2', component: require('./components/product/Products2.vue').default },
    { path: '/products3', component: require('./components/product/Products3.vue').default },
    { path: '/produk', component: require('./components/product/Produk.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '/roles', component: require('./components/Roleuser.vue').default },
    { path: '/roles/tambah', component: require('./components/RoleTambah.vue').default },    
    { path: '/sppb-admin', component: require('./components/suratpesanan/SppbAdmin.vue').default },
    { path: '/sppbtambah', component: require('./components/suratpesanan/Sppbtambah.vue').default },
    { path: '/sppb-gudang', component: require('./components/suratpesanan/SppbGudang.vue').default },
    { path: '/surat-pesanan', component: require('./components/suratpesanan/Suratpesanan.vue').default },
    { path: '/surat-pesanan/tambah', component: require('./components/suratpesanan/Supetambah.vue').default },   
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/no-akses', component: require('./components/NoAkses.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
