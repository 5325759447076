/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import moment from 'moment';

import { Form, HasError, AlertError } from 'vform';
window.Form = Form;

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

import Swal from 'sweetalert2';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
  });

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)


/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes
});
// Routes End


//import $ from "jquery";
import "datatables.net-bs4";
import 'datatables.net-responsive';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

 
// Components
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('dashboard', require('./components/Dashboard.vue'));

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);
Vue.component(
    'no-akses',
    require('./components/NoAkses.vue').default
);
// Filter Section

Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));
Vue.filter('isAktif', value => (value=='1' ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));
Vue.filter('isLunas', value => (value=='1' ? 'Lunas' : 'Belum Lunas'));
Vue.filter('status_pesanan', value => (value=='0' ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));
Vue.filter('formatNumber', (value) => {
    return new Intl.NumberFormat('en-US').format(value);
})

// end Filter

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//Vue.component('history', require('./components/history.vue').default);

Vue.mixin({
    methods: {
        setColor(status) {
            if(status == '1')       {return 'bg-red'; } 
            else                    {return ''; }
        },
        isRed(value) {
            if(value > '0')       {return 'red text-bold'; } 
            else                    {return ''; }
        },
        BgDate(date) {
            if(date != null){
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();
    
                today       = mm + '-' + dd + '-' + yyyy;
                let jatuhtempo  = moment(date, "DD-MM-YYYY").format("MM-DD-YYYY")
                var tanggal1 = new moment(today, "MM-DD-YYYY"); 
                var tanggal2 = new moment(jatuhtempo, "MM-DD-YYYY"); 
                var selisih = tanggal2.diff(tanggal1, 'days');
                //console.log("Selisih : "+selisih)
                if(selisih < 7 && selisih >3)
                    {return "bg-warning"}
                else if(selisih <=3 && selisih >=0)
                    {return "bg-danger"}
                else{return ""}
            }
        },        
        BgDatePelunasan(date,is_lunas) {         
            if(is_lunas == 1){
                return "";
            }
            else{
                if(date != null){
                        var today = new Date();
                        var dd = String(today.getDate()).padStart(2, '0');
                        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                        var yyyy = today.getFullYear();
            
                        today       = mm + '-' + dd + '-' + yyyy;
                        let jatuhtempo  = moment(date, "DD-MM-YYYY").format("MM-DD-YYYY")
                        var tanggal1 = new moment(today, "MM-DD-YYYY"); 
                        var tanggal2 = new moment(jatuhtempo, "MM-DD-YYYY"); 
                        var selisih = tanggal2.diff(tanggal1, 'days');
                        //console.log("Selisih : "+selisih)
                        if(selisih < 7 && selisih >3)
                            {return "bg-warning"}
                        else if(selisih <=3)
                            {return "bg-danger"}
                        else{return ""}                
                }
            }
        },
        StatusSP(status) {
            if(status == '0')       {return 'Draft'; } 
            else if(status == '1')  {return 'Cancel'; }
            else if(status == '2')  {return 'Submit'; }
            else if(status == '3')  {return 'Diproses Admin'; }
            else if(status == '4')  {return 'Diproses Gudang'; }
            else if(status == '5')  {return 'Menunggu Akunting 1'; }
            else if(status == '6')  {return 'Menunggu Akunting 2'; }
            else if(status == '7')  {return 'Menunggu Approval'; }
            else if(status == '8')  {return 'Approved'; }
            else if(status == '13')  {return 'Ditolak Admin'; }
            else if(status == '14')  {return 'Ditolak Gudang'; }
            else if(status == '15')  {return 'Ditolak Akunting 1'; }
            else if(status == '16')  {return 'Ditolak Akunting 2'; }
            else if(status == '17')  {return 'Reject'; }
            else                     {return '-';}
        },
        LoadHistory(slug,id) {
            axios.get("../api/user/gethistory", { params: { slug : slug , id :id}}).
                                then(({ data }) => {
                                    this.historis = data.data;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
        },
        HistoryPengiriman(id_sp,id_sppb) {
            axios.get("../api/pengiriman/historypengiriman", { params: { id_sp :id_sp, id_sppb:id_sppb}}).
                                then(({ data }) => {
                                    this.historykirim = data.data;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
        },
        Loadsettingppn() {
            axios.get("../api/getppndefault").
                                then(({ data }) => {
                                    window.ppndefault = data.data.ppn
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
        },
    }
  });
  

const app = new Vue({
    el: '#app',
    router
});
