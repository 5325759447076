<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row" v-show="!isBlock">
  
              <div class="col-12 padding-remove">          
                    <div class="card">
                        <div class="card-header"><h3 class="card-title">SPPB </h3></div>
                        <!-- /.card-header -->
                        
                            <form style="padding-top: 10px;" @submit.prevent="editmode ? UpdateSppb() : SaveSppb()">
                                <div class="card-body padding-remove minh-65">
                                    <div class="row margin-remove">
                                        <div class="col-md-6 form-horizontal">
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Nomor SPPB<code>*</code></label>
                                                    <div class="col-7  input-group margin-bottom-remove">
                                                        : 
                                                            <input v-model="form.no_sppb" type="text"
                                                                    placeholder="nomor surat . . ."
                                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('no_sppb') }">
                                                            <has-error :form="form" field="no_sppb"></has-error>
                                                            <div v-if="(form.status_pesanan==2 || form.status_pesanan==14) && (akses_create || akses_update)" class="input-group-append">
                                                                <a type="button" class="btn btn-success" @click="GetNoSppb"><i class="fas fa-sync-alt" :class="{ 'fa-spin': isSpin }"></i></a>
                                                            </div>
                                                    </div>                                         
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">No. Surat Pesanan</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.no_sp}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Customer</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.nm_toko}}</label>
                                                    <input v-model="form.customer_id" hidden>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Tanggal Pesanan</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.tgl_pesanan}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Sales</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_sales}}</label>
                                                </div>
                                        </div> 
                                        <div class="col-md-6 form-horizontal">
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Renc.Kirim  <code>*</code></label>
                                                    <div class="col-7" v-show="form.status_pesanan==2 || form.status_pesanan==14 || form.status_pesanan==15">
                                                        <date-picker v-model="form.tgl_renckirim" valueType="format" format="DD-MM-YYYY"
                                                            :class="{ 'is-invalid': form.errors.has('tgl_renckirim') }"
                                                            style="width:100%!important">
                                                        </date-picker>
                                                        <has-error :form="form" field="tgl_renckirim"></has-error>
                                                    </div>
                                                    <div class="col-7" v-show="form.status_pesanan>2 && form.status_pesanan!=14 && form.status_pesanan!=15 ">
                                                        : <label class="margin-bottom-remove" :class="BgDate(form.tgl_renckirim)">{{form.tgl_renckirim}}</label>
                                                    </div>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">jatuh tempo  <code>*</code></label>
                                                    <div class="col-7" v-show="form.status_pesanan==2 || form.status_pesanan==14 || form.status_pesanan==15">
                                                        <date-picker v-model="form.tgl_jatuhtempo" valueType="format" format="DD-MM-YYYY"
                                                            :class="{ 'is-invalid': form.errors.has('tgl_jatuhtempo') }"
                                                            style="width:100%!important">
                                                        </date-picker>
                                                        <has-error :form="form" field="tgl_jatuhtempo"></has-error>
                                                    </div>
                                                    <div class="col-7" v-show="form.status_pesanan>2 && form.status_pesanan!=14 && form.status_pesanan!=15 ">
                                                        : <label class="margin-bottom-remove" :class="BgDatePelunasan(form.tgl_jatuhtempo,form.is_lunas)">{{form.tgl_jatuhtempo}}</label>
                                                    </div>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Gudang</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_gdg}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Akunting 1</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_akt1}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Akunting 2</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_akt2}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-5 margin-bottom-remove">Catatan Approval</label>
                                                    <label class="col-7 margin-bottom-remove">: {{form.catatan_bos}}</label>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="card-header">
                                            <h3 class="card-title">Item</h3>
                                    </div>
                                    <div class="div-tabel">
                                        <table class="table table-bordered margin-bottom-remove" id="TableDproduk">
                                            <thead>
                                                <tr>
                                                    <th data-priority="1" class="min-200px">Nama Produk</th>
                                                    <th>Qty</th>
                                                    <th>Qty Open</th>
                                                    <th>UOM</th>
                                                    <th>@Harga</th>
                                                    <th>@Diskon</th>
                                                    <th>Jumlah</th>
                                                    <th data-priority="2" v-show="form.status_pesanan==2 || form.status_pesanan==14">Act</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(details,index) in details" :key="index">
                                                    <td class="text-capitalize">
                                                            {{details.nm_product}}
                                                            <br v-if="details.note_sales !== null">
                                                            <label v-if="details.note_sales !== null" class="text-italic">Catatan : {{details.note_sales}}</label>
                                                    </td>
                                                    <td>{{details.qty_pesanan}}</td>
                                                    <td>{{details.qty_open}}</td>
                                                    <td>{{details.nm_uom}}</td>
                                                    <td>{{details.hrg_item | formatNumber}}</td>
                                                    <td>{{details.diskonrp_item | formatNumber}}</td>
                                                    <td>{{details.totalhrg_item | formatNumber}}</td>
                                                    <td v-if="form.status_pesanan==2 || form.status_pesanan==14">
                                                        <a v-if="akses_update" class="btn-menu" @click="editModal(index,details)"
                                                            data-toggle="tooltip" data-placement="top" title="Edit">
                                                            <i class="fa fa-edit blue"></i>
                                                        </a>
                                                    </td>
                                                
                                                </tr>
                                            </tbody>                                            
                                            <tfoot>
                                                <tr>
                                                    <td class="text-center">TOTAL</td>
                                                    <td>{{sum_qty}}</td>
                                                    <td>{{sum_qtyopen}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><strong>Rp {{form.subtotal_pesanans | formatNumber}}</strong></td>
                                                    <td v-show="form.status_pesanan==2 || form.status_pesanan==14"></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>    
                                    <div class="col-md-6 form-horizontal padding-top-10">                                  
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-4">SubTotal </label>
                                                    <label class="col-8" hidden>: {{ sumTotalItem}}</label>
                                                    <label class="col-8">: {{ form.subtotal_pesanans | formatNumber}}</label>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                        <label class="col-4">Diskon Nota</label>   
                                                        <div class="col-8 padding-right-remove">
                                                            <div class="input-group input-group-sm" v-if="isEditableK">
                                                                <input v-model="form.diskon_text" type="text" 
                                                                        placeholder="input diskon . . ."
                                                                        v-on:change=HitungTotal
                                                                        class="form-control" :class="{ 'is-invalid': form.errors.has('diskon_text') }"
                                                                        autocomplete="off" style="max-width:90%"
                                                                        :readonly="!isSavediskon">
                                                                <has-error :form="form" field="diskon_text"></has-error>
                                                                <span class="input-group-append" v-if="!isSavediskon">
                                                                    <button type="button" class="btn btn-primary btn-flat" @click="BtnDiskon(1)">Edit</button>
                                                                </span>
                                                                <span class="input-group-append" v-if="isSavediskon">
                                                                    <button type="button" class="btn btn-primary btn-flat" @click="BtnDiskon(2)">Save</button>
                                                                </span>
                                                                <span class="input-group-append" v-if="isSavediskon">
                                                                    <button type="button" class="btn btn-danger btn-flat" @click="BtnDiskon(0)">Cancel</button>
                                                                </span>
                                                            </div>
                                                            <label v-if="!isEditableK">: {{form.diskon_text }}</label> 
                                                        </div>                                        
                                                </div>                                     
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-4">Total </label>
                                                    <label class="col-8">: {{ form.total_pesanans | formatNumber}}</label>
                                                </div>   
                                                
                                    </div>
                                    <komisi v-if="this.form.sp_id" 
                                            :id_sp="this.form.sp_id" 
                                            :isEditable=this.isEditableK 
                                            :total_pesanans="this.form.total_pesanans">
                                    </komisi>
                                    <div class="col-md-6 form-horizontal">                          
                                            <div class="form-group">
                                                    <br>
                                                    <label>Catatan Admin</label>
                                                    <input v-model="form.catatan_admin" type="text" name="catatan_admin"
                                                        placeholder="tulis catatan . . ."
                                                        class="form-control" :class="{ 'is-invalid': form.errors.has('catatan_admin') }">
                                                    <has-error :form="form" field="catatan_admin"></has-error>
                                            </div>
                                        </div>
                                </div>
                                <div class="card-footer">      
                                        <router-link to="/sppb-admin" type="button" class="btn btn-back">
                                                    <i class="fas fa-chevron-left"></i> Kembali
                                        </router-link>                                
                                        <button v-if="(form.status_pesanan==2 || form.status_pesanan==14 || form.status_pesanan==15) && (akses_create || akses_update)" type="submit" class="btn btn-primary" :disabled='isDisabled'><i class="fas fa-save"></i> Simpan</button>
                                        <router-link :to="{path:'/pesanancetakadm/'+form.id_sp}" target="_blank" type="button" 
                                            v-if="form.status_pesanan  >2 && form.status_pesanan<13" class="btn btn-primary">
                                            <i class="fas fa-print"></i> Cetak
                                </router-link>  
                                </div>
                            </form>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
          </div>
          <div v-show="isBlock">
                    <no-akses></no-akses>
            </div>
          <!-- Modal -->
          <div class="modal fade" id="ModalItem" role="dialog" aria-labelledby="ModalItem" aria-hidden="true" data-keyboard="false" data-backdrop="static">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" >Edit QTY</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <form @submit.prevent="UpdateItem()">
                          <div class="modal-body">
                                <div class="form-group row">
                                            <label class="col-sm-12 margin-bottom-remove">Items <code>*</code></label>
                                            <label class="col-sm-12 margin-bottom-remove">{{formItem.nm_product}}</label>
                                </div>
                                <div class="form-group row margin-bottom-remove">
                                    <div class="col-6">
                                        <div class="form-group">
                                                    <label>Qty  <code>*</code></label>
                                                    <input v-model="formItem.qty_pesanan" type="number" name="qty_pesanan" required
                                                        placeholder="input qty . . ."
                                                        class="form-control" :class="{ 'is-invalid': formItem.errors.has('qty_pesanan') }"
                                                        v-on:change=HitungTotal
                                                        autocomplete="off">                                           
                                                    <has-error :form="form" field="qty_pesanan"></has-error>
                                        </div>     
                                    </div>                              
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Harga  /item <code>*</code></label>
                                            <input v-model="formItem.hrg_item" hidden>
                                            <input v-model="formItem.Thrg_item" type="text" name="hrg_item" required
                                                placeholder="input harga . . ."
                                                v-on:change=changehrg
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('hrg_item') }"
                                                autocomplete="off">
                                            <has-error :form="form" field="hrg_item"></has-error>
                                        </div>
                                  </div> 
                                </div>
                                <div class="form-group row margin-bottom-remove">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Diskon /item</label>
                                            <div class="input-group">                                                
                                                    <input v-model="formItem.diskonps_item" type="text" 
                                                        placeholder="input diskon . . ."
                                                        v-on:change=HitungTotal
                                                        class="form-control" :class="{ 'is-invalid': form.errors.has('diskonps_item') }"
                                                        autocomplete="off">
                                                    <has-error :form="form" field="diskonps_item"></has-error>
                                                    <div class="input-group-append" style="padding-left:5px;margin:auto"> %</div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Total  <code>*</code></label>
                                            <input v-model="formItem.totalhrg_item"hidden >
                                            <input v-model="formItem.Ttotalhrg_item" type="text" name="totalhrg_item"
                                                readonly
                                                placeholder="input harga . . ."
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('totalhrg_item') }">
                                            <has-error :form="form" field="totalhrg_item"></has-error>
                                        </div>
                                    </div>
                                </div> 
                          </div>
                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                              <button type="submit" class="btn btn-primary">Update</button>
                          </div>
                    </form>
                  </div>
              </div>
          </div>  
                  
          <!-- <history v-if="editmode" :datahistory="historis"></history> -->
      </div>
    </section>
  </template>
  
  <script>
  
        import Select2 from 'v-select2-component';
        import VuePaginateAl from 'vue-paginate-al';
        import history from '../history.vue'
        import komisi from '../komisi.vue'
        import DatePicker from 'vue2-datepicker';
        import 'vue2-datepicker/index.css';
        import moment from "moment";
  
      export default {
          watch: {
              $route: {
                  immediate: true,
                  handler(to, from) {
                      document.title = to.meta.title || 'Sales Order - SPPB';
                  }
              },
          },
          components:{
                    Select2,
                    VuePaginateAl,
                    history,
                    komisi,
                    DatePicker,
          },
          data () {
              return {  
                  editmode        : false,
                  isDisabled      : false,
                  isEditableK     : false,
                  isSpin          : false,
                  isSavediskon    : false,
                  akses_read      : 0,
                  akses_create    : 0,
                  akses_update    : 0,
                  akses_delete    : 0,
                  akses_approve   : 0,
                  isBlock         : false,
                  historis        : [],
                  pesanans        : [],
                  items           : [],
                  details         : [],
                  sum_qty         : 0,
                  sum_qtyopen     : 0,
                  form            : new Form({
                                        sp_id               : '',
                                        id_sppb             : '',
                                        customer_id         : '',
                                        no_sp               : '',
                                        no_sppb             : '',
                                        nm_toko             : '',
                                        tgl_pesanan         : '',
                                        catatan_sales       : '',
                                        catatan_admin       : null,
                                        catatan_gdg         : null,
                                        catatan_akt1        : null,
                                        catatan_akt2        : null,
                                        catatan_bos         : null,
                                        diskon_text         : 0,
                                        diskon_pesanans     : 0,
                                        diskonps_pesanans   : 0,
                                        diskonrp_pesanans   : 0,
                                        dppn_pesanans       : 0,
                                        subtotal_pesanans   : 0,
                                        total_pesanans      : 0,
                                        status_pesanan      : 0, //2
                                        tgl_jatuhtempo      : null,
                                        is_lunas            : 0,
                                        tgl_renckirim       : null,
                                  }),
                  formItem        : new Form({
                                        index               : '',
                                        sp_id               : '',
                                        product_id      : '',
                                        kd_product      : '',
                                        nm_product      : '',
                                        qty_pesanan     : '0',
                                        qty_kirim       : '0',
                                        qty_open        : '0',
                                        nm_uom          : '',
                                        hrg_item        : '0',
                                        Thrg_item       : '0',
                                        diskonrp_item   : '0',
                                        diskonps_item   : '0',
                                        totalhrg_item   : '0',
                                        Ttotalhrg_item  : '0',
                                        note_sales      : null,
                                  })
              }
          },          
        computed: {
            sumTotalItem() {
                var subtotal = this.details.reduce((sum, details) => {
                    const tharga_item = Number(details.totalhrg_item)
                    return sum += tharga_item;
                }, 0);

                var sumQ = this.details.reduce((sumqty, details) => {
                    const qty = Number(details.qty_pesanan)
                    return sumqty += qty;
                }, 0);
                this.sum_qty = sumQ 

                var sumQopen = this.details.reduce((sumqtyopen, details) => {
                    const qtyopen = Number(details.qty_open)
                    return sumqtyopen += qtyopen;
                }, 0);
                this.sum_qtyopen = sumQopen
                
                if(this.form.diskon_text >= 0)  
                    {
                        var diskon_text                 = this.form.diskon_text;
                        //var diskonps_pesanans               = diskon_text / this.form.subtotal * 100
                        //this.form.diskonps_pesanans         = diskonps_pesanans
                        this.form.diskonrp_pesanans         = diskon_text
                        var diskonrp_pesanans               = diskon_text
                    }
                            else{
                                    var diskon      = this.form.diskon_text;
                                        
                                    var isPercent   = diskon.substring(diskon.length - 1)
                                        if(isPercent== '%'){
                                                var percent1                = diskon.replace("%", "")
                                                var percent                 = percent1.replace(/\,/g, '.')
                                                this.form.diskonps_pesanans = percent;
                                                var diskonrp_pesanans       = (subtotal*percent)/100}
                                        else{var diskonrp_pesanans = 0; this.form.diskonps_pesanans  = 0}
                            }
                                
                this.form.subtotal_pesanans = subtotal
                this.form.total_pesanans    = subtotal - diskonrp_pesanans 
                return subtotal;
            }
        },
        methods: {
              loadAkses(){  
                          if (typeof aksessppbadmin != 'undefined') {
                                          this.akses_read     = aksessppbadmin.akses_read;
                                          this.akses_create   = aksessppbadmin.akses_create;
                                          this.akses_update   = aksessppbadmin.akses_update;
                                          this.akses_delete   = aksessppbadmin.akses_delete; 
                                          this.akses_approve  = aksessppbadmin.akses_approve;
                          }
                          else{ 
                                       axios.get("../api/getakses", { params: { slug: 'sppb-admin' }}).then(({ data }) => {
                                          this.akses_read     = data.akses.akses_read;
                                          this.akses_create   = data.akses.akses_create;
                                          this.akses_update   = data.akses.akses_update;
                                          this.akses_delete   = data.akses.akses_delete; 
                                          this.akses_approve  = data.akses.akses_approve; 
                                          window.aksessppbadmin = data.akses;                                                                    
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });
                              }
              },
              BtnDiskon(x){
                    if(x=="1"){
                        this.isSavediskon           = true;
                    }
                    else if(x=="2"){
                            var diskon_text     = this.form.diskon_text;
                            var vardiskon           = diskon_text.replace(/\,/g, '.')
                            if(this.form.diskon_pesanans == vardiskon){
                                Toast.fire({
                                    icon: 'warning',
                                    title: 'Tidak ada perubahan dikson'
                                });
                                this.isSavediskon           = false;
                            }
                            else{
                                this.isDisabled=true;
                                this.$Progress.start();
                                
                                axios.post('../api/updatediskonnota', { sp_id: this.form.sp_id, diskon: vardiskon,total: this.form.total_pesanans})
                                .then((response)=>{                        
                                    if(response.data.type=='sukses'){
                                            Toast.fire({
                                                icon    : 'success',
                                                title   : response.data.message
                                            });
                                            this.form.diskon_pesanans   = vardiskon
                                    }
                                    else{
                                            Swal.fire({
                                                icon    : 'error',
                                                title   : response.data.message,
                                            })
                                            
                                            this.form.diskon_text   = this.form.diskon_pesanans 
                                    }
                                    this.isDisabled=false;
                                    this.$Progress.finish();
                                })
                                .catch(function(error) {
                                        this.isDisabled=false;
                                        this.$Progress.finish();                                        
                                        this.form.diskon_text   = this.form.diskon_pesanans 
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");                                            
                                                    }
                                                    
                                }); 
                                this.isSavediskon           = false;
                            }
                            
                    }
                    else{
                        this.isSavediskon           = false;
                        this.form.diskon_text   = this.form.diskon_pesanans
                    }
              },
              LoadDetails(id){
                  var id_sp   = id;
                  //var no_sp   = text;
                  axios.get("../api/getsppbdetails", { params: { id_sp: id_sp }}).then(({ data }) => {
                                            var ResDetails                = data.data[0];
                                            this.form.sp_id               = id_sp;
                                            this.form.id_sppb             = ResDetails.id_sppb;
                                            this.form.no_sppb             = ResDetails.no_sppb;
                                            this.form.no_sp               = ResDetails.no_sp;
                                            this.form.customer_id         = ResDetails.customer_id;
                                            this.form.nm_toko             = ResDetails.nm_toko;
                                            this.form.tgl_pesanan         = ResDetails.tgl_pesanan;
                                            this.form.catatan_sales       = ResDetails.catatan_sales;
                                            this.form.catatan_admin       = ResDetails.catatan_admin;
                                            this.form.catatan_gdg         = ResDetails.catatan_gdg;
                                            this.form.catatan_akt1        = ResDetails.catatan_akt1;
                                            this.form.catatan_akt2        = ResDetails.catatan_akt2;
                                            this.form.catatan_bos         = ResDetails.catatan_bos;
                                            this.form.diskonps_pesanans   = ResDetails.diskonps_pesanans;
                                            this.form.diskonrp_pesanans   = ResDetails.diskonrp_pesanans;
                                            this.form.status_pesanan      = ResDetails.status_pesanan;
                                            this.form.is_lunas            = ResDetails.is_lunas;
                                            if(ResDetails.tgl_jatuhtempo != null)
                                                {this.form.tgl_jatuhtempo      = moment(ResDetails.tgl_jatuhtempo, "YYYY-MM-DD").format("DD-MM-YYYY");}
                                            if(ResDetails.tgl_renckirim  != null)
                                                {this.form.tgl_renckirim      = moment(ResDetails.tgl_renckirim, "YYYY-MM-DD").format("DD-MM-YYYY");}

                                            if(ResDetails.diskonps_pesanans>0){this.form.diskon_text=ResDetails.diskonps_pesanans+'%'}
                                            else{this.form.diskon_text=ResDetails.diskonrp_pesanans}
                                            this.form.diskon_pesanans    = this.form.diskon_text

                                            var ResItems                = data.data[1];                 
                                            this.details                = [];
                                                  for(var i=0; i < ResItems.length; i++){
                                                      let qty_open  = ResItems[i].qty_pesanan - ResItems[i].qty_kirim
                                                      this.details.push({
                                                                          sp_id             : ResItems[i].sp_id ,
                                                                          product_id        : ResItems[i].product_id ,
                                                                          kd_product        : ResItems[i].kd_product ,
                                                                          nm_product        : ResItems[i].kd_product+' - '+ResItems[i].nm_product,
                                                                          note_sales        : ResItems[i].note_sales,
                                                                          qty_pesanan       : ResItems[i].qty_pesanan,
                                                                          nm_uom            : ResItems[i].nm_uom,
                                                                          qty_kirim         : ResItems[i].qty_kirim,
                                                                          qty_open          : qty_open,  
                                                                          hrg_item          : ResItems[i].hrg_item,  
                                                                          Thrg_item          : ResItems[i].hrg_item,  
                                                                          diskonrp_item     : ResItems[i].diskonrp_item,  
                                                                          diskonps_item     : ResItems[i].diskonps_item,  
                                                                          totalhrg_item     : ResItems[i].totalhrg_item,  
                                                                          Ttotalhrg_item     : ResItems[i].totalhrg_item,  
                                                      })
                                                  }
                                            if(ResDetails.no_sppb != null){
                                                this.editmode = true;
                                                if(this.akses_update==0){this.isBlock=true}
                                            }    
                                            else{ if(this.akses_create==0){this.isBlock=true}}  
                                            if((ResDetails.status_pesanan>=2 && ResDetails.status_pesanan<=5) || ResDetails.status_pesanan==14 || ResDetails.status_pesanan==15)
                                                {this.isEditableK = true;}
                                      }).catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                          }
                                      });                
              },
              SaveSppb: function(event) {
                  this.isDisabled=true;
                  this.$Progress.start();
                  this.form.post('../api/sppbadmin', { params: { statusp: '3' , details : this.details }})
                      .then((response)=>{                        
                          if(response.data.type=='sukses'){
                                  Swal.fire({
                                      icon    : 'success',
                                      title   : response.data.message
                                  }).then((oke) => {
                                          this.$router.replace('/sppb-admin')
                                  });
                          }
                          else{
                                  Swal.fire({
                                      icon    : 'error',
                                      title   : response.data.message,
                                  })
                          }
                          this.isDisabled=false;
                          this.$Progress.finish();
                      })
                      .catch(function(error) {
                                          if (error.response && error.response.status === 401) {                                            
                                              window.location.href = "/dashboard";
                                          } else {
                                              Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");                                            
                                          }
                                          
                      }); 
  
                      this.isDisabled=false;
                      this.$Progress.finish();                  
              },
              editModal(index,details){
                    $(".form-control").removeClass('is-invalid');
                    this.formItem.reset();
                    this.formItem.fill(details);
                    this.formItem.index             = index;
                    $('#ModalItem').modal('show');
              },
              UpdateItem(){
                    var index           = this.formItem.index;
                    var sp_id           = this.formItem.sp_id;
                    var product_id      = this.formItem.product_id;
                    var qty_pesanan     = this.formItem.qty_pesanan;
                    var qty_kirim       = this.formItem.qty_kirim;
                    var qty_open        = qty_pesanan-qty_kirim;

                    if(qty_pesanan>=qty_kirim){
                            this.details[index].qty_pesanan     = this.formItem.qty_pesanan;
                            this.details[index].qty_open        = qty_open;
                            this.details[index].hrg_item        = this.formItem.hrg_item;
                            this.details[index].Thrg_item       = this.formItem.Thrg_item;
                            this.details[index].diskonrp_item   = this.formItem.diskonrp_item;
                            this.details[index].diskonps_item   = this.formItem.diskonps_item;
                            this.details[index].totalhrg_item   = this.formItem.totalhrg_item;
                            this.details[index].Ttotalhrg_item  = this.formItem.Ttotalhrg_item;
                            $('#ModalItem').modal('hide'); 
                        }
                    else{
                        Toast.fire({
                              icon: 'error',
                              title: 'Qty tidak boleh dibawah Qty terkirim :'+qty_kirim
                          });
                    }
                            
              },
              UpdateSppb(){
                  this.isDisabled=true;
                  this.$Progress.start();
                  
                  this.form.put('../api/sppbadmin/'+this.form.sp_id, { params: {  details : this.details }})
                      .then((response)=>{                        
                          if(response.data.type=='sukses'){
                                  Swal.fire({
                                      icon    : 'success',
                                      title   : response.data.message
                                  }).then((oke) => {
                                          this.$router.replace('/sppb-admin')
                                  });
                          }
                          else{
                                  Swal.fire({
                                      icon    : 'error',
                                      title   : response.data.message,
                                  })
                          }
                          this.isDisabled=false;
                          this.$Progress.finish();
                      })
                      .catch(()=>{
                          Toast.fire({
                              icon: 'error',
                              title: 'Gagal! harap isi form dengan benar'
                          });
                          this.isDisabled=false;
                          this.$Progress.finish();
                      })
  
              },  
              GetNoSppb(){
                    this.isSpin = true;
                    axios.get("../api/sppb/getnosppb").
                                then(({ data }) => {
                                    this.form.no_sppb = data.data.no_sppb
                                    this.isSpin = false;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
              },
                changehrg(){
                            let text                = (this.formItem.Thrg_item).replace(/\,/g, '');
                            let tnmber              = text*1
                            this.formItem.Thrg_item = tnmber.toLocaleString('en-US')
                            var harga               = this.formItem.Thrg_item.replace(/\,/g, '');   
                            this.formItem.hrg_item  = harga  
                            this.HitungTotal()    
                    },
              HitungTotal(){
                      if(this.formItem.qty_pesanan > 0 )  {var qty_pesanan =this.formItem.qty_pesanan}else{var qty_pesanan = 0}
                      if(this.formItem.hrg_item >0 )      {var hrg_item =(this.formItem.hrg_item).replace(/\./g, '')}else{var hrg_item = 0}
                      let diskonps_item               = this.formItem.diskonps_item
                      
                      if(diskonps_item >= 0)  
                              {                                
                                  var diskonrp_item               = (hrg_item*diskonps_item )/100
                                  this.formItem.diskonrp_item     = diskonrp_item 
                              }
                              else {var diskonrp_item             = '0';this.formItem.diskonrp_item  = 0}
                              
                      this.formItem.diskonrp_item  = diskonrp_item
                      var total                    = qty_pesanan * (hrg_item - diskonrp_item) 
                      this.formItem.totalhrg_item  = total
                      this.formItem.Ttotalhrg_item = total.toLocaleString('en-US')
              },

          },
        mounted() {            
                  //this.loadItems();
          },
        created() {
              this.$Progress.start();
              this.loadAkses();
              this.$nextTick(() => {
                      const searchParams = new URLSearchParams(window.location.search);
                      if(searchParams.has('id') == true)
                          { 
                              var id              = searchParams.get('id');
                              this.LoadDetails(id)
                              this.form.id_sp     = id;
                          }
                })
              this.$Progress.finish();
          }
      }
  </script>
  