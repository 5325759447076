<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 padding-remove">
        
                <div class="card minh-65" v-show="!isBlock">
                    <div class="card-header">
                        <h3 class="card-title">Komisi Sales</h3>
                        <div class="card-tools">                    
                                <router-link  v-if="akses_create" to="/komisipayment/tambah" type="button" class="btn btn-sm btn-primary">
                                            <i class="fa fa-plus-square"></i> Tambah
                                </router-link>
                        </div>
                    </div>
                    <div class="row margin-remove">
                            <div class="col-6 p-5px">
                                <div id="Tablepayments_filter" class="dataTables_filter">
                                    <label>Cari :<input ref="myInput" @keyup.enter="Cari" type="search" class="form-control form-control-sm" placeholder="nama sales" aria-controls="Tablepayments"></label>
                                    
                                </div>
                            </div>
                            <div class="col-6 p-5px text-right">
                                <label class="margin-remove">Tampil :</label>
                                <div>
                                        <select ref="optampil" @change="Cari" v-model="perrow">
                                            <option value="10">10</option>
                                            <option value="25" >25</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                        </select>
                                </div>
                            </div>
                        </div>
                    <!-- /.card-header -->
                    <div class="card-body padding-remove">
                        <table class="table table-striped table-bordered" id="Tablepayments">
                            <thead>
                                <tr>
                                    <th data-priority="1">Sales <a href="#" @click="short('name',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                    <th data-priority="2">Tgl Payment <a href="#" @click="short('tgl_kpayment',shorting)"> <i class="fas fa-caret-up"></i></a></th>
                                    <th>Awal Periode</th>
                                    <th>Akhir Periode</th>
                                    <th>Nominal</th>
                                    <th data-priority="3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="komisipayment in komisipayment.data" :key="komisipayment.id_kpayment">
                                    <td class="text-capitalize">{{komisipayment.name}}</td>
                                    <td>{{komisipayment.tgl_kpaymentdf}}</td>
                                    <td>{{komisipayment.awalp_kpayment}}</td>
                                    <td>{{komisipayment.akhirp_kpayment}}</td>
                                    <td>{{komisipayment.nominal_kpayment | formatNumber}}</td>
                                    <td>
                                        <router-link :to="{path:'/komisipayment/'+komisipayment.id_kpayment}" class="btn-menu"
                                            data-toggle="tooltip" data-placement="top" title="payment">
                                            <i class="fas fa-file-alt blue"></i>
                                        </router-link>
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer p-5px">                   
                            <vue-paginate-al :totalPage="totalpage" @btnClick="Cari"></vue-paginate-al>
                            <div>           
                                    <router-link to="/dashboard" type="button" class="btn btn-back">
                                                <i class="fas fa-chevron-left"></i> Kembali
                                    </router-link>
                            </div>
                    </div>
                </div>
                
                <div v-show="isBlock">
                    <no-akses></no-akses>
                </div>
          </div>
        </div>       
    </div>
  </section>
</template>

<script>

    import VuePaginateAl from 'vue-paginate-al'

    export default {
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Sales Order - Komisi Sales';
                }
            },
        },
        components: {           
            VuePaginateAl,
          },
        data () {
            return {
                shortby     : 'komisi_payments.created_at',
                shorting    : 'desc',
                shortc      : 'desc',
                totalpage   : 1,
                perrow      : 10,   
                editmode    : false,
                komisipayment    : {},
                akses_read  : 0,
                akses_create: 0,
                akses_update: 0,
                akses_delete: 0,
                akses_approve: 0,
                isBlock         : false,
            }
        },
        methods: {
            short(shortby,shorting)
                        {
                            if(shorting=='asc') {                                
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "desc";
                                    this.page       = 1;

                                }
                             else{                                 
                                    this.shortby    = shortby;
                                    this.shortc     = shorting;
                                    this.Cari();
                                    this.shorting   = "asc";
                                    this.page       = 1;
                            }
                        }, 
            Cari(page = 1){
                let textuser = this.$refs.myInput.value;
                let perrow      = this.$refs.optampil.value;                
                
                axios.get('api/komisipayment?page='+page+'&cari='+textuser+'&limit='+perrow+'&shortby='+this.shortby+'&shorting='+this.shortc)
                                            .then(({ data }) => {
                                                this.CekDatatable();
                                                this.komisipayment = data.data;
                                                
                                                this.$nextTick(() => {
                                                    this.MakeDatatable();
                                                })
                                                this.totalpage = data.data.last_page;
                                            }).catch(function(error) {
                                                        console.log(error)
                                                    if (error.response && error.response.status === 401) {                                            
                                                        window.location.href = "/dashboard";
                                                    } else {
                                                        Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                                    }
                                                });
                    
            },
            CekDatatable(){
                    if ( $.fn.DataTable.isDataTable( '#Tablepayments' ) ) {
                        $('#Tablepayments').dataTable().fnDestroy();
                    }  
            },
            MakeDatatable(){
                    var tabledata = $("#Tablepayments").DataTable({
                        destroy     : true,
                        dom         : "",
                        ordering    : false,
                        paging      : false,
                        responsive  : true,     
                    });  
            },
            loadAkses(){  
                        if (typeof akseskomisipayment != 'undefined') {
                                        this.akses_read     = akseskomisipayment.akses_read;
                                        this.akses_create   = akseskomisipayment.akses_create;
                                        this.akses_update   = akseskomisipayment.akses_update;
                                        this.akses_delete   = akseskomisipayment.akses_delete; 
                                        this.akses_approve  = akseskomisipayment.akses_approve; 
                                        if(this.akses_read == '0'){ this.isBlock=true;}  
                        }
                        else{ 
                                     axios.get("api/getakses", { params: { slug: 'komisipayment' }}).then(({ data }) => {
                                        this.akses_read     = data.akses.akses_read;
                                        this.akses_create   = data.akses.akses_create;
                                        this.akses_update   = data.akses.akses_update;
                                        this.akses_delete   = data.akses.akses_delete; 
                                        this.akses_approve  = data.akses.akses_approve; 
                                        window.akseskomisipayment = data.akses;        
                                        if(this.akses_read == '0'){ this.isBlock=true;}                       
                                    }).catch(function(error) {
                                            console.log(error)
                                        if (error.response && error.response.status === 401) {                                            
                                            window.location.href = "/dashboard";
                                        } else {
                                            Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                        }
                                    });
                            }
            },
            loadkomisipayment(){
                        axios.get("api/komisipayment").then(({ data }) => {
                                    this.CekDatatable();
                                    this.komisipayment = data.data;
                                    
                                    this.$nextTick(() => {
                                        this.MakeDatatable();
                                    })
                                    this.totalpage = data.data.last_page;
                                }).catch(function(error) {
                                    if (error.response && error.response.status === 401) {
                                        window.location.href = "/dashboard";
                                    } else {
                                        console.log(error);
                                    }
                                });
            },    

        },
        mounted() {
        },
        created() {

            this.$Progress.start();
            this.loadAkses();
            this.loadkomisipayment();
            this.$Progress.finish();
        },
    }
</script>
