<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row" v-show="!isBlock">
  
              <div class="col-12 padding-remove">          
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" v-show="!editmode">Tambah Payment </h3>
                            <h3 class="card-title" v-show="editmode">Edit Payment </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body padding-remove minh-65">                        
                            <form style="padding-top: 10px;" @submit.prevent="editmode ? UpdatePayment() : SavePayment()">
                                    <div class="row margin-remove">
                                            <div class="col-md-6 form-horizontal">  
                                                <div class="form-group row">
                                                    <label class="col-4">Pilih Customer  <code>*</code></label>
                                                    <div class="col-8">
                                                        <Select2 id="customer_id" v-model="pesanans.customer_id" 
                                                                :options="customers" 
                                                                :settings="{ settingOption: customers.id_customer, settingOption: customers.nm_toko }" 
                                                                :class="{ 'is-invalid': pesanans.errors.has('customer_id') }"
                                                                @select="ShowSP(pesanans.customer_id)"
                                                                required                   
                                                                />
                                                            <has-error :form="pesanans" field="customer_id"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-4">Pilih SP  <code>*</code></label>
                                                    <div class="col-8">
                                                        <Select2 id="sp_id" v-model="pesanans.sp_id" 
                                                                :options="Listpesanans" 
                                                                :settings="{ settingOption: Listpesanans.id_sp, settingOption: Listpesanans.customer_id }" 
                                                                :class="{ 'is-invalid': pesanans.errors.has('sp_id') }"
                                                                @select="getSPid(pesanans.sp_id)"            
                                                                />
                                                            <has-error :form="pesanans" field="sp_id"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-4" >Nominal bayar <code>*</code></label>
                                                    <div class="col-8">
                                                        <input v-model="pesanans.nominal_payment" type="number" hidden>
                                                        <input v-model="pesanans.Tnominal_payment" type="text" name="Tnominal_payment" required
                                                            placeholder="nominal pembayaran"
                                                            v-on:change=changeNominal
                                                            class="form-control" :class="{ 'is-invalid': pesanans.errors.has('nominal_payment') }">
                                                        <has-error :form="pesanans" field="nominal_payment"></has-error>
                                                    </div>
                                                </div> 
                                                <div class="form-group row">
                                                    <label class="col-4">Akun Bayar  <code>*</code></label>
                                                    <div class="col-8">
                                                        <Select2 id="akun_id" v-model="pesanans.akun_id" 
                                                                :options="ListAkun" 
                                                                :settings="{ settingOption: ListAkun.id_akun}" 
                                                                :class="{ 'is-invalid': pesanans.errors.has('akun_id') }"
                                                                required                   
                                                                />
                                                            <has-error :form="pesanans" field="akun_id"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row margin-bottom-remove">
                                                    <label class="col-4">Tanggal Bayar <code>*</code></label>
                                                    <div class="col-8">
                                                        <date-picker v-model="pesanans.tgl_payment" valueType="format" format="DD-MM-YYYY"
                                                            :class="{ 'is-invalid': pesanans.errors.has('tgl_payment') }"
                                                            style="width:100%!important">
                                                        </date-picker>
                                                        <has-error :form="pesanans" field="tgl_payment"></has-error>
                                                    </div>                                                    
                                                </div>
                                            </div> 
                                            <div class="col-md-6 form-horizontal">  
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Jatuh tempo</label>
                                                        <label class="col-7 margin-bottom-remove">: <a :class="pesanans.status_pesanan>3&&pesanans.status_pesanan<8 ? BgDate(pesanans.tgl_jatuhtempo) : 'a-text'">{{pesanans.tgl_jatuhtempo}}</a></label>
                                                    </div>  
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Total Pesanan</label>
                                                        <label class="col-7 margin-bottom-remove">: Rp {{pesanans.total_pesanans  | formatNumber}}</label>
                                                    </div> 
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Sisa Tagihan</label>
                                                        <label class="col-7 margin-bottom-remove">: Rp {{sisa_tagihan  | formatNumber}}</label>
                                                    </div>  
                                            </div>
                                            <div class="col-md-12 form-horizontal" v-if="akses_create || akses_update">  
                                                <button type="submit" class="btn btn-primary" :disabled='isDisabled'><a v-show=!editmode>Simpan</a><a v-show=editmode>Update</a></button>
                                                <button type="button" class="btn btn-danger" v-show=editmode @click="CancelEdit">Cancel</button>
                                            </div>
                                    </div>                                 
                            </form>
                            <br>
                            <table class="table table-striped table-bordered" id="TableCpayment">
                                                    <thead>
                                                        <tr>
                                                            <th data-priority="1">Customer</th>
                                                            <th data-priority="2">No.SP</th>
                                                            <th>Nominal</th>
                                                            <th>Akun Bayar</th>
                                                            <th data-priority="4">Tanggal</th>
                                                            <th>User</th>
                                                            <th data-priority="3" v-show="pesanans.status_pesanan!=8">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="payments in payments.data" :key="payments.id_payment">
                                                            <td>{{payments.nm_toko}}</td>
                                                            <td class="text-capitalize">{{payments.no_sp}}</td>
                                                            <td>{{payments.nominal_payment | formatNumber }}</td>
                                                            <td>{{payments.nm_akun}}</td>
                                                            <td>{{payments.tgl_payment}}</td>
                                                            <td>{{payments.name}}</td>
                                                            <td v-show="pesanans.status_pesanan!=8">
                                                                <a v-if="akses_update&&pesanans.status_pesanan!=8" href="#" class="btn-menu" @click="editPayment(payments)">
                                                                    <i class="fa fa-edit blue"></i>
                                                                </a>
                                                                <a v-if="akses_update&&pesanans.status_pesanan!=8" class="btn-menu" @click="DeletePayment(payments)" 
                                                                        data-toggle="tooltip" data-placement="top" title="Hapus">
                                                                        <i class="fa fa-trash red"></i>
                                                                </a>
                                                            </td>
                                                        
                                                        </tr>
                                                    </tbody>
                                </table>                               
                        </div>                            
                            
                                <div class="card-footer">      
                                        <router-link :to="prevRoutePath" type="button" class="btn btn-back">
                                                    <i class="fas fa-chevron-left"></i> Kembali
                                        </router-link>    
                                </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
          </div>
          <div v-show="isBlock">
                    <no-akses></no-akses>
            </div>
      </div>
    </section>
  </template>
  
  <script>
  
        import Select2 from 'v-select2-component';
        import history from '../history.vue';
        import DatePicker from 'vue2-datepicker';
        import 'vue2-datepicker/index.css';
        import moment from "moment";
  
      export default {
          watch: {
              $route: {
                  immediate: true,
                  handler(to, from) {
                      document.title = to.meta.title || 'Sales Order - Tambah Payment';
                  }
              },
          },
          components:{
                Select2,
                history,
                DatePicker,
          },
          data () {
              return {  
                  editmode          : false,
                  editDetail        : false,
                  isDisabled        : false,
                  akses_read        : 0,
                  akses_create      : 0,
                  akses_update      : 0,
                  akses_delete      : 0,
                  akses_approve     : 0,
                  prevRoute         : '/dashboard',
                  isBlock           : false,
                  customers         : [],
                  Listpesanans      : [],
                  ListAkun          : [],
                  payments          : {},
                  terbayar          : 0,
                  sisa_tagihan      : 0,
                  pesanans          : new Form({
                                        id_payment          : '',
                                        tgl_pesanan         : '',
                                        tgl_jatuhtempo      : '',
                                        tgl_renckirim       : '',
                                        subtotal_pesanans   : '0',
                                        total_pesanans      : '0',
                                        status_pesanan      : '',
                                        nominal_payment     : '0',
                                        Tnominal_payment    : '0',
                                        tgl_payment         : '',
                                        akun_id             : '',
                                        customer_id         : '',
                                        sp_id               : '',
                                        is_lunas            : '0',
                                    }),
                  form              : new Form({
                                    }),
              }
          },
          
         beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },          
        computed: {
            prevRoutePath() {
                        if(this.prevRoute.path =='/'){return '/dashboard'}
                        else{return this.prevRoute}
                    },
        },
        methods: {
                loadAkses(){  
                            if (typeof aksescpayment != 'undefined') {
                                            this.akses_read     = aksescpayment.akses_read;
                                            this.akses_create   = aksescpayment.akses_create;
                                            this.akses_update   = aksescpayment.akses_update;
                                            this.akses_delete   = aksescpayment.akses_delete; 
                                            this.akses_approve  = aksescpayment.akses_approve;
                            }
                            else{ 
                                        axios.get("../../api/getakses", { params: { slug: 'cpayment' }}).then(({ data }) => {
                                            this.akses_read     = data.akses.akses_read;
                                            this.akses_create   = data.akses.akses_create;
                                            this.akses_update   = data.akses.akses_update;
                                            this.akses_delete   = data.akses.akses_delete; 
                                            this.akses_approve  = data.akses.akses_approve; 
                                            window.aksescpayment = data.akses;                                                                    
                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });
                                }
                },
                loadCustomers(){
                            axios.get("../api/customer/getcustomer").
                                    then(({ data }) => {
                                        this.customers = data.data;
                                        this.loadAkun()
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {
                                            window.location.href = "/dashboard";
                                        } else {
                                            console.log(error);
                                        }
                                    });

                },
                loadAkun(){
                            axios.get("../api/getakunbayar").
                                    then(({ data }) => {
                                        this.ListAkun = data.data;
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {
                                            window.location.href = "/dashboard";
                                        } else {
                                            console.log(error);
                                        }
                                    });

                },
                ShowSP(id,sp_id){
                    //console.log(id)
                    axios.get('../api/getspfiltered', { params: { filterby: 'customer_id',filtervalue:id, filter2:'is_lunas',filter2value:'0' }}).then((response)=>{
                                        const resp          = response.data.data;  
                                        this.Listpesanans   = resp  ; 
                                        if(this.editmode==true){this.pesanans.sp_id=sp_id  }   
                                        else{}                           
                                        
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });                                
                }, 
                getSPid(id_sp){ 
                    this.editmode=false;
                    axios.get('../api/getspid', { params: { id_sp: id_sp }}).then((response)=>{
                                        const resp                  = response.data.data; 
                                        this.pesanans.status_pesanan= resp.status_pesanan; 
                                        this.pesanans.total_pesanans= parseInt(resp.total_pesanans); 
                                        this.pesanans.tgl_jatuhtempo= moment(resp.tgl_jatuhtempo, "YYYY-MM-DD").format("DD-MM-YYYY");                                   
                                        this.pesanans.tgl_renckirim = moment(resp.tgl_renckirim, "YYYY-MM-DD").format("DD-MM-YYYY"); 
                                        this.pesanans.is_lunas      = resp.is_lunas
                                        this.clearform()  
                                        
                                        this.$nextTick(() => {
                                            this.loadpayments(id_sp);  
                                        })                                                          
                                        
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });                         
                },  
                changeNominal(){
                        let text                        = (this.pesanans.Tnominal_payment).replace(/\,/g, '');
                        let tnmber                      = text*1
                        this.pesanans.Tnominal_payment  = tnmber.toLocaleString('en-US')
                        var harga                       = this.pesanans.Tnominal_payment.replace(/\,/g, '');   
                        this.pesanans.nominal_payment   = harga  
                },  
                SavePayment() {
                    this.isDisabled=true;
                    //this.pesanans.reset();
                                    this.$Progress.start();
                                    this.pesanans.post('../api/cpayment')
                                    .then((response)=>{                        
                                        if(response.data.type=='sukses'){
                                                Swal.fire({
                                                    icon    : 'success',
                                                    title   : response.data.message,
                                                    showConfirmButton: false,
                                                    timer   : 1500
                                                })
                                                this.loadpayments(this.pesanans.sp_id); 
                                                this.clearform()                                               
                                        }
                                        else{
                                                Swal.fire({
                                                    icon    : 'error',
                                                    title   : response.data.message,
                                                })
                                        }
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })
                                    .catch(()=>{
                                        Toast.fire({
                                            icon: 'error',
                                            title: 'Gagal! harap isi form dengan benar'
                                        });
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })               
                },  
                UpdatePayment() {
                    this.isDisabled=true;
                    Swal.fire({
                        title: 'Apakah yakin ingin Update Payment ?',
                        text: "pastikan data sudah benar",
                        showCancelButton: true,
                        confirmButtonColor: '#38c172',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Update!',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$Progress.start();
                                this.pesanans.put('../api/cpayment/'+this.pesanans.id_payment)
                                    .then((response)=>{                        
                                        if(response.data.type=='sukses'){
                                                Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.message,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                this.loadpayments(this.pesanans.sp_id); 
                                                this.clearform()  
                                                this.editmode = false;
                                        }
                                        else{
                                                Swal.fire({
                                                    icon    : 'error',
                                                    title   : response.data.message,
                                                })
                                        }
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })
                                    .catch(()=>{
                                        Toast.fire({
                                            icon: 'error',
                                            title: 'Gagal! harap isi form dengan benar'
                                        });
                                        this.isDisabled=false;
                                        this.$Progress.finish();  
                                    }) 
                            }
                            else{this.isDisabled=false;}
                        })
                },       
                CekDatatable(){
                        if ( $.fn.DataTable.isDataTable( '#TableCpayment' ) ) {
                            $('#TableCpayment').dataTable().fnDestroy();
                        }  
                },
                MakeDatatable(){
                        var tabledata = $("#TableCpayment").DataTable({
                            destroy     : true,
                            dom         : "",
                            ordering    : false,
                            paging      : false,
                            responsive  : true,     
                        });  
                },
                loadpayments(id_sp){
                            axios.get("../api/getcpayment", { params: { id_sp: id_sp}}).
                                    then(({ data }) => {
                                        this.CekDatatable();
                                        this.payments       = data.data;
                                        var array           = this.payments.data;
                                        this.terbayar       = 0;
                                        this.sisa_tagihan   = 0;
                                        
                                        if(array.length>0){
                                            for(var i=0; i < array.length; i++){
                                                const nominal_payment = parseInt(array[i].nominal_payment);
                                                this.terbayar += nominal_payment
                                            }
                                        }
                                        this.sisa_tagihan  = this.pesanans.total_pesanans - this.terbayar;


                                        this.$nextTick(() => {
                                            this.MakeDatatable();
                                        })
                                        this.totalpage = data.data.last_page;
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {
                                            window.location.href = "/dashboard";
                                        } else {
                                            console.log(error);
                                        }
                                    });
                    },
                loadheader(id_sp){
                            axios.get("../api/getspheader", { params: { id_sp: id_sp}}).
                                    then(({ data }) => {
                                        var header                  = data.data;
                                        this.pesanans.status_pesanan= header.status_pesanan; 
                                        this.pesanans.is_lunas      = header.is_lunas; 
                                        this.pesanans.total_pesanans= header.total_pesanans; 
                                        this.pesanans.tgl_jatuhtempo= moment(header.tgl_jatuhtempo, "YYYY-MM-DD").format("DD-MM-YYYY");                                   
                                        this.pesanans.tgl_renckirim = moment(header.tgl_renckirim, "YYYY-MM-DD").format("DD-MM-YYYY"); 
                                        this.loadpayments(id_sp);   
                                        
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {
                                            window.location.href = "/dashboard";
                                        } else {
                                            console.log(error);
                                        }
                                    });
                    },
                editPayment(payments){
                    this.editmode = true;
                    console.log("is lunas : "+this.pesanans.is_lunas)
                    $(".form-control").removeClass('is-invalid');
                    this.ShowSP(payments.customer_id,payments.sp_id)
                    //this.pesanans.reset();
                    //this.pesanans.fill(payments);
                    this.pesanans.customer_id       = payments.customer_id
                    this.$nextTick(() => {
                        this.pesanans.sp_id             = payments.sp_id
                        console.log("SP_ID : "+this.pesanans.sp_id);
                        this.pesanans.nominal_payment   = payments.nominal_payment
                        this.pesanans.Tnominal_payment  = payments.nominal_payment.toLocaleString('en-US')
                        this.pesanans.akun_id           = payments.akun_id
                        this.pesanans.tgl_payment       = payments.tgl_payment
                        this.pesanans.id_payment        = payments.id_payment
                    })
                    
                },
                CancelEdit(){
                    this.editmode = false;
                    this.clearform()  
                },
                clearform(){                    
                    this.pesanans.nominal_payment   = "0"
                    this.pesanans.Tnominal_payment  = "0"
                    this.pesanans.akun_id           = ""
                    this.pesanans.tgl_payment       = ""
                },
                DeletePayment(payment){
                    var Tnominal_payment = payment.nominal_payment.toLocaleString('en-US')
                    Swal.fire({
                        title: 'Apakah anda Yakin ?',
                        text: "Hapus pembayaran ke "+payment.nm_akun+" senilai "+Tnominal_payment+". Setelah hapus tidak dapat dikembalikan",
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Hapus',
                        cancelButtonText: 'Close'
                        }).then((result) => {
                                if (result.value) {
                                        this.form.delete('../api/cpayment/'+payment.id_payment, { params: { payment: payment, Tnominal_payment:Tnominal_payment }})
                                        .then((response)=>{
                                            if(response.data.type=='sukses'){
                                                    Toast.fire({
                                                            icon: 'success',
                                                            title: response.data.message
                                                    });
                                                    this.loadpayments(this.pesanans.sp_id);
                                            }
                                            else{Swal.fire({icon    : 'error', title   : response.data.message,});this.$Progress.finish();   }
                                        })
                                        .catch(function(error) {
                                            console.log(error)
                                            if (error.response && error.response.status === 401) { window.location.href = "/dashboard"; } 
                                            else { Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning"); this.$Progress.finish();}                                    
                                        });
                                }
                                else{}
                            })
                },

          },
        mounted() {            
                  //this.loadItems();
          },
        created() {
              this.$Progress.start();
              this.loadAkses();
              this.loadCustomers();
              this.$nextTick(() => {
                    if(this.$route.params.id_sp != undefined){
                            var id_sp              = this.$route.params.id_sp;
                            this.loadheader(id_sp)
                    }
                })
              this.$Progress.finish();
          }
      }
  </script>
  