<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row" v-show="!isBlock">
  
              <div class="col-12 padding-remove">          
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" v-show="!editmode">Tambah Pembayaran Komisi</h3>
                            <h3 class="card-title" v-show="editmode">Pembayaran Komisi </h3>
                        </div>
                        <!-- /.card-header -->                        
                            <form style="padding-top: 10px;" @submit.prevent="editmode ? UpdateKpayment() : SaveKpayment()">
                                <div class="card-body padding-remove minh-65">
                                    <div class="row margin-remove">
                                            <div class="col-md-6 form-horizontal">  
                                                <div class="form-group row">
                                                    <label class="col-4">Pilih Sales  <code>*</code></label>
                                                    <div class="col-8">
                                                        <Select2 id="sales_id" v-model="form.sales_id" 
                                                                :options="sales" 
                                                                :settings="{ settingOption: sales.id, settingOption: sales.text, settingOption: sales.emp_id }" 
                                                                :class="{ 'is-invalid': form.errors.has('sales_id') }"
                                                                @select="LoadSp($event)"
                                                                required               
                                                                />
                                                            <has-error :form="form" field="sales_id"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-4" >Nominal bayar <code>*</code></label>
                                                    <div class="col-8">
                                                        <input v-model="form.nominal_kpayment" type="number" hidden>
                                                        <input v-model="form.Tnominal_kpayment" type="text" name="Tnominal_kpayment" required
                                                            placeholder="nominal pembayaran"
                                                            v-on:change=changeNominal
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('nominal_kpayment') }">
                                                        <has-error :form="form" field="nominal_kpayment"></has-error>
                                                    </div>
                                                </div>   
                                                    <div class="form-group row">
                                                        <label class="col-4">Awal Periode</label>
                                                        <div class="col-8">
                                                            <date-picker v-model="form.awalp_kpayment" valueType="format" format="DD-MM-YYYY"
                                                                :class="{ 'is-invalid': form.errors.has('awalp_kpayment') }"
                                                                style="width:100%!important" required>
                                                            </date-picker>
                                                            <has-error :form="form" field="awalp_kpayment"></has-error>
                                                        </div>                                                    
                                                    </div> 
                                                    <div class="form-group row">
                                                        <label class="col-4">Akhir Periode</label>
                                                        <div class="col-8">
                                                            <date-picker v-model="form.akhirp_kpayment" valueType="format" format="DD-MM-YYYY"
                                                                :class="{ 'is-invalid': form.errors.has('akhirp_kpayment') }"
                                                                style="width:100%!important" required>
                                                            </date-picker>
                                                            <has-error :form="form" field="akhirp_kpayment"></has-error>
                                                        </div>                                                    
                                                    </div> 
                                            </div> 
                                            <div class="col-md-6 form-horizontal">
                                                    <div class="form-group row">
                                                        <label class="col-5">Tanggal Bayar <code>*</code></label>
                                                        <div class="col-7">
                                                            <date-picker v-model="form.tgl_kpayment" valueType="format" format="DD-MM-YYYY"
                                                                :class="{ 'is-invalid': form.errors.has('tgl_kpayment') }"
                                                                style="width:100%!important" required>
                                                            </date-picker>
                                                            <has-error :form="form" field="tgl_kpayment"></has-error>
                                                        </div>                                                    
                                                    </div>
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Total Komisi</label>
                                                        <label class="col-7 margin-bottom-remove">: Rp {{total_komisi  | formatNumber}}</label>
                                                    </div>   
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Total Potongan</label>
                                                        <label class="col-7 margin-bottom-remove">: Rp {{total_potongan  | formatNumber}}</label>
                                                    </div>  
                                                    <div class="form-group row margin-bottom-remove">
                                                        <label class="col-5 margin-bottom-remove">Total Bayar</label>
                                                        <label class="col-7 margin-bottom-remove">: <strong>Rp {{TotalBayar | formatNumber}}</strong></label>
                                                    </div>  
                                            </div>                                                    

                                            <div class="col-md-12 form-horizontal" v-if="akses_create || akses_update">  
                                                <button type="button" @click="PilihSP" class="btn btn-primary" v-if="!editmode">Pilih SP</button>
                                                <button type="button" @click="OpenPotongan" class="btn btn-primary" v-if="!editmode">Tambah Potongan</button>
                                            </div>
                                            <br><br>
                                            <div class="col-lg-8 padding-remove" style="height: 500px;border-top: solid #c9c9c9 1px;border-bottom: solid #c9c9c9 1px; overflow: auto;">
                                                    <label style="padding-left:5px">Pembayaran :</label>
                                                    <table class="table table-striped table-bordered" id="TableKpayment">
                                                        <thead>
                                                            <tr>
                                                                <th data-priority="1">No.SP</th>
                                                                <th>Tgl SP</th>
                                                                <th>Total SP</th>
                                                                <th>PPN</th>
                                                                <th>Komisi %</th>
                                                                <th data-priority="2">Komisi Rp</th>
                                                                <th data-priority="3" v-show="form.status_pesanan!=8 && !editmode">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="details in details" :key="details.id_kpaymentd">
                                                                <td class="text-capitalize">{{details.no_sp}}</td>
                                                                <td>{{details.tgl_pesanan }}</td>
                                                                <td>{{details.total_ps | formatNumber }}</td>
                                                                <td>{{details.kpaymentd_ppn }}</td>
                                                                <td>{{details.kpaymentd_ps }}</td>
                                                                <td>{{details.kpaymentd_nominal | formatNumber }}</td>
                                                                <td v-show="form.status_pesanan!=8 && !editmode">
                                                                    <a class="btn-menu" @click="DeleteKPayment(details)" 
                                                                            data-toggle="tooltip" data-placement="top" title="Hapus">
                                                                            <i class="fa fa-trash red"></i>
                                                                    </a>
                                                                </td>
                                                            
                                                            </tr>
                                                        </tbody>                                            
                                                        <tfoot>
                                                            <tr>
                                                                <td class="text-center">TOTAL</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><strong>Rp {{total_komisi | formatNumber}}</strong></td>
                                                                <td v-show="form.status_pesanan!=8 && !editmode"></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                            </div>
                                            
                                            <div class="col-lg-4" style="height: 500px;border-top: solid #c9c9c9 1px;border-bottom: solid #c9c9c9 1px; overflow: auto;">
                                                    <label>Potongan :</label>
                                                    <table class="table table-striped table-bordered" id="TablePotongan">
                                                        <thead>
                                                            <tr>
                                                                <th>Nama Potongan</th>
                                                                <th>Nominal</th>
                                                                <th data-priority="3" v-show="form.status_pesanan!=8 && !editmode">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="potongans in potongans" :key="potongans.id_kpaymentp">
                                                                <td>{{potongans.nm_potongan }}</td>
                                                                <td>{{potongans.nominal_potongan | formatNumber }}</td>
                                                                <td v-show="form.status_pesanan!=8 && !editmode">
                                                                    <a class="btn-menu" @click="DeletePotongan(potongans)" 
                                                                            data-toggle="tooltip" data-placement="top" title="Hapus">
                                                                            <i class="fa fa-trash red"></i>
                                                                    </a>
                                                                </td>
                                                            
                                                            </tr>
                                                        </tbody>                                            
                                                        <tfoot>
                                                            <tr>
                                                                <td class="text-center">TOTAL</td>
                                                                <td><strong>Rp {{total_potongan | formatNumber}}</strong></td>
                                                                <td v-show="form.status_pesanan!=8 && !editmode"></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                            </div>
                                    </div> 
                                </div>
                                <div class="card-footer">      
                                        <router-link :to="prevRoutePath" type="button" class="btn btn-back">
                                                    <i class="fas fa-chevron-left"></i> Kembali
                                        </router-link>                                            
                                        <button type="submit" class="btn btn-primary" :disabled='isDisabled' v-if="!editmode"><i class="fas fa-save"></i> Submit</button>

                                </div>
                            </form>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>

                <!-- Modal Pilih SP-->
                    <div class="modal fade" id="ModalSP" tabindex="-1" role="dialog" aria-labelledby="ModalSP" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" >Pilih Sp</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form @submit.prevent="SubmitSP()">
                                <div class="modal-body" v-if="sp.length>0" style="height: 500px; overflow: auto;">                                       
                                    <table class="table table-striped table-bordered" id="TableSP">
                                                <thead>
                                                    <tr>
                                                        <th data-priority="1">No.SP</th>
                                                        <th>Tgl SP</th>
                                                        <th>Total SP</th>
                                                        <th>PPN</th>
                                                        <th>Komisi %</th>
                                                        <th>Komisi Rp</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="sp in sp" :key="sp.id_sp">
                                                        <td><input type="checkbox" :value="sp.id_sp" v-model="form.sp_id" > {{sp.no_sp}}</td>
                                                        <td class="text-center">{{sp.tgl_pesanan}}</td> 
                                                        <td class="text-right">{{sp.total_pesanans | formatNumber}}</td>
                                                        <td class="text-center">{{sp.pajak_pesanan}} %</td>  
                                                        <td class="text-center">{{sp.komisi_ps}} %</td>                                                  
                                                        <td class="text-right">{{NominalKomisi(sp.komisi_ps,sp.pajak_pesanan,sp.total_pesanans,sp.rupiah_fee)  | formatNumber}}</td>                                                    
                                                    </tr>
                                                </tbody>
                                            </table>       
                                </div>
                                <div class="modal-body" v-else>                                        
                                    <div class="form-group margin-bottom-remove">
                                        <label>Belum ada komisi yang perlu dibayar </label>
                                    </div>  
                                </div>       
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-success">Pilih</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                <!--End modal-->
                <!-- Modal Potongan-->
                <div class="modal fade" id="ModalPotongan" tabindex="-1" role="dialog" aria-labelledby="ModalPotongan" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" >Potongan</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form @submit.prevent="SubmitPotongan()">
                                <div class="modal-body">                                              
                                    <div class="form-group row">
                                            <label class="col-4">Nama Potongan <code>*</code></label>
                                            <div class="col-8">
                                                <input v-model="formP.nm_potongan" type="text" name="nm_potongan" required
                                                    placeholder="nama potongan . . ."
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('nm_potongan') }">
                                                <has-error :form="form" field="nm_potongan"></has-error>
                                            </div>
                                        </div>
                                    <div class="form-group row">
                                                    <label class="col-4" >Nominal Potongan <code>*</code></label>
                                                    <div class="col-8">
                                                        <input v-model="formP.nominal_potongan" type="number" hidden>
                                                        <input v-model="formP.Tnominal_potongan" type="text" name="Tnominal_potongan" required
                                                            placeholder="nominal potongan"
                                                            v-on:change=changeNominalP
                                                            class="form-control" :class="{ 'is-invalid': formP.errors.has('nominal_potongan') }">
                                                        <has-error :form="formP" field="nominal_potongan"></has-error>
                                                    </div>
                                                </div>       
                                </div>      
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-success">Simpan</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                <!--End modal-->
          </div>
          <div v-show="isBlock">
                    <no-akses></no-akses>
            </div>
      </div>
    </section>
  </template>
  
  <script>
  
        import Select2 from 'v-select2-component';
        import history from '../history.vue';
        import DatePicker from 'vue2-datepicker';
        import 'vue2-datepicker/index.css';
        import moment from "moment";
  
      export default {
          watch: {
              $route: {
                  immediate: true,
                  handler(to, from) {
                      document.title = to.meta.title || 'Sales Order - Tambah Komisi';
                  }
              },
          },
          components:{
                Select2,
                history,
                DatePicker,
          },
          data () {
              return {  
                  editmode          : false,
                  editDetail        : false,
                  isDisabled        : false,
                  akses_read        : 0,
                  akses_create      : 0,
                  akses_update      : 0,
                  akses_delete      : 0,
                  akses_approve     : 0,
                  prevRoute         : '/dashboard',
                  isBlock           : false,
                  sales             : [],
                  sp                : [],
                  details           : [],
                  potongans         : [],
                  total_komisi      : 0,
                  total_potongan    : 0,
                  total_bayar       : 0,
                  form              : new Form({
                                        total_form          : '0',
                                        status_pesanan      : '',
                                        nominal_kpayment    : '0',
                                        Tnominal_kpayment   : '0',
                                        tgl_kpayment        : '',
                                        awalp_kpayment      : '',
                                        akhirp_kpayment     : '',
                                        sales_id            : '',
                                        sp_id               : [],
                                    }),
                  formP             : new Form({
                                        id_kpaymentp        : '',
                                        nm_potongan         : '',
                                        nominal_potongan    : '',
                                    }),
              }
          },
          
         beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },          
        computed: {
            prevRoutePath() {
                        if(this.prevRoute.path =='/'){return '/dashboard'}
                        else{return this.prevRoute}
                    },
            TotalBayar(){
                var SumK = this.details.reduce((SumCom, details) => {
                    const Commision = Number(details.kpaymentd_nominal)
                    return SumCom += Commision;
                }, 0);
                this.total_komisi = SumK 

                var SumP = this.potongans.reduce((SumPot, potongans) => {
                    const Potong    = Number(potongans.nominal_potongan)
                    return SumPot += Potong;
                }, 0);
                this.total_potongan = SumP 
                
                var total_bayar     = this.total_komisi-this.total_potongan
                this.total_bayar    = total_bayar
                return total_bayar;
            },
        },
        methods: {
                loadAkses(){  
                            if (typeof komisipayment != 'undefined') {
                                            this.akses_read     = komisipayment.akses_read;
                                            this.akses_create   = komisipayment.akses_create;
                                            this.akses_update   = komisipayment.akses_update;
                                            this.akses_delete   = komisipayment.akses_delete; 
                                            this.akses_approve  = komisipayment.akses_approve;
                                            if(this.akses_read==0){this.isBlock=true}
                            }
                            else{ 
                                        axios.get("../../api/getakses", { params: { slug: 'komisipayment' }}).then(({ data }) => {
                                            this.akses_read     = data.akses.akses_read;
                                            this.akses_create   = data.akses.akses_create;
                                            this.akses_update   = data.akses.akses_update;
                                            this.akses_delete   = data.akses.akses_delete; 
                                            this.akses_approve  = data.akses.akses_approve;  
                                            if(this.akses_read==0){this.isBlock=true}                                           
                                            window.komisipayment = data.akses;                                                                    
                                        }).catch(function(error) {
                                            if (error.response && error.response.status === 401) {                                            
                                                window.location.href = "/dashboard";
                                            } else {
                                                Swal.fire("Gagal! " +error.response.status, error.response.data.message, "warning");
                                            }
                                        });
                                }
                },
                LoadSales(){
                            axios.get("../api/user/getsales").
                                    then(({ data }) => {
                                        this.sales = data.data;
                                    }).catch(function(error) {
                                        if (error.response && error.response.status === 401) {
                                            window.location.href = "/dashboard";
                                        } else {
                                            console.log(error);
                                        }
                                    });

                },
                LoadSp(sales){
                    //console.log(sales)                    
                    axios.get('../api/getspkomisi', { params: { filterby: 'user_id',filtervalue:sales.id,
                                                                filter2: 'is_komisipaid',filter2value:'0',
                                                                filter3: 'status_pesanan',filter3value:'8',  
                                                                filter4: 'is_lunas',filter4value:'1',  
                                                            }})
                                .then((response)=>{
                                        const resp              = response.data.data;  
                                        this.sp                 = resp  ;
                                        this.total_komisi       = 0;  
                                        this.form.nominal_kpayment   = 0;                  
                                        this.form.Tnominal_kpayment  = 0;                  
                                        this.details            = [];
                                        this.potongans          = [];
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });                                
                }, 
                PilihSP(){
                        if(this.form.sales_id == ""){
                            Swal.fire({ icon    : 'error',title   : "Pilih Sales terlebih dahulu",})
                        }
                        else{
                            this.form.sp_id =[];
                            $(".form-control").removeClass('is-invalid');
                            $('#ModalSP').modal('show');
                        }
                },  
                OpenPotongan(){
                        if(this.details.length>0){
                            $(".form-control").removeClass('is-invalid');
                            this.formP.reset();
                            $('#ModalPotongan').modal('show');
                            var urut                = (this.potongans.length)+1
                            this.formP.id_kpaymentp = 'Temp'+urut
                        }
                        else{
                            Swal.fire({ icon    : 'error',title   : "Pilih SP terlebih dahulu",})
                        }
                }, 
                SubmitSP(){
                       if(this.form.sp_id.length>0){
                            var sp_id  = this.form.sp_id;
                            for(var i=0; i < sp_id.length; i++){ 
                                for(var x=0; x < this.sp.length; x++){
                                    if( this.sp[x].id_sp  == sp_id[i] ){                                                       
                                        this.details.push({
                                            id_kpaymentd        : this.sp[x].id_sp,
                                            no_sp               : this.sp[x].no_sp,
                                            sp_id               : this.sp[x].id_sp ,
                                            tgl_pesanan         : this.sp[x].tgl_pesanan ,
                                            total_ps            : this.sp[x].total_pesanans,
                                            kpaymentd_ps        : this.sp[x].komisi_ps,
                                            kpaymentd_ppn       : this.sp[x].pajak_pesanan,
                                            kpaymentd_nominal   : this.NominalKomisi(this.sp[x].komisi_ps, this.sp[x].pajak_pesanan, this.sp[x].total_pesanans,this.sp[x].rupiah_fee),  
                                        }); 
                                        this.sp.splice(x, 1);
                                    }
                                    else{}
                                }                            
                            }
                            $('#ModalSP').modal('hide');
                            Toast.fire({
                                        icon: 'success',
                                        title: 'SP ditambahkan'
                            });                              
                       }
                       else{
                            Toast.fire({ icon    : 'error',title   : "Belum ada SP yang dipilih",})
                       }
                },  
                SubmitPotongan(){ 
                            this.potongans.push({
                                        id_kpaymentp        : this.formP.id_kpaymentp,
                                        nm_potongan         : this.formP.nm_potongan,
                                        nominal_potongan    : this.formP.nominal_potongan,
                            }); 
                            Toast.fire({
                                        icon: 'success',
                                        title: 'Input Potongan'
                            });  
                            $('#ModalPotongan').modal('hide');   
                },                
                NominalKomisi(komisi,ppn,total_pesanans,rupiah_fee) {
                    if(rupiah_fee>0){
                        return rupiah_fee;
                    }
                    else{
                            var nppn            = ( (100+Number(ppn))/100)
                            var dpp             = (total_pesanans/nppn)
                            var rupiah_komisi   = Math.round(dpp*(komisi/100))
                            return rupiah_komisi;
                    }
                },
                changeNominal(){
                        let text                        = (this.form.Tnominal_kpayment).replace(/\,/g, '');
                        let tnmber                      = text*1
                        this.form.Tnominal_kpayment      = tnmber.toLocaleString('en-US')
                        var harga                       = this.form.Tnominal_kpayment.replace(/\,/g, '');   
                        this.form.nominal_kpayment       = harga  
                },
                changeNominalP(){
                        let text                        = (this.formP.Tnominal_potongan).replace(/\,/g, '');
                        let tnmber                      = text*1
                        this.formP.Tnominal_potongan    = tnmber.toLocaleString('en-US')
                        var harga                       = this.formP.Tnominal_potongan.replace(/\,/g, '');   
                        this.formP.nominal_potongan     = harga  
                },  
                SaveKpayment() {
                    if(this.total_bayar == this.form.nominal_kpayment){
                        Swal.fire({
                            title: 'Apakah yakin ingin Submit ?',
                            text: "Setelah submit tidak dapat diubah lagi",
                            showCancelButton: true,
                            confirmButtonColor: '#38c172',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: 'Ya, Submit!',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                        this.isDisabled=true;
                                        this.$Progress.start();
                                        
                                        axios.post('../api/komisipayment', {
                                            sales_id    : this.form.sales_id,
                                            sp_id       : this.form.sp_id,
                                            tgl_kpayment : this.form.tgl_kpayment, 
                                            nominal_kpayment : this.form.nominal_kpayment, 
                                            potongans : this.potongans , 
                                            awalp_kpayment  : this.form.awalp_kpayment, 
                                            akhirp_kpayment  : this.form.akhirp_kpayment, 
                                            details : this.details})
                                        //this.form.post('../api/komisipayment', { params: { potongans : this.potongans , details : this.details }})
                                        .then((response)=>{                     
                                            if(response.data.type=='sukses'){
                                                    Swal.fire({
                                                        icon    : 'success',
                                                        title   : response.data.message
                                                    }).then((oke) => {
                                                            this.$router.replace('/komisipayment')
                                                    });                                                                                           
                                            }
                                            else{
                                                    Swal.fire({
                                                        icon    : 'error',
                                                        title   : response.data.message,
                                                    })
                                            }
                                            this.isDisabled=false;
                                            this.$Progress.finish();
                                        })
                                        .catch(()=>{
                                            Toast.fire({
                                                icon: 'error',
                                                title: 'Gagal! harap isi form dengan benar'
                                            });
                                            this.isDisabled=false;
                                            this.$Progress.finish();
                                        })
                                    }
                                else{}
                            })
                    }
                    else{
                            Swal.fire({
                                        icon    : 'error',
                                        title   : 'Gagal!',
                                        text    : 'Nominal Bayar tidak sesuai dengan Total Bayar',
                            })
                    }               
                },  
                UpdateKpayment() {
                    this.isDisabled=true;
                    Swal.fire({
                        title: 'Apakah yakin ingin Update Payment ?',
                        text: "pastikan data sudah benar",
                        showCancelButton: true,
                        confirmButtonColor: '#38c172',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Update!',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$Progress.start();
                                this.pesanans.put('../api/komisipayment/'+this.pesanans.id_payment)
                                    .then((response)=>{                        
                                        if(response.data.type=='sukses'){
                                                Swal.fire({
                                                            icon    : 'success',
                                                            title   : response.data.message,
                                                            showConfirmButton: false,
                                                            timer   : 1500
                                                        })
                                                this.loaddetails(this.pesanans.sp_id); 
                                                this.clearform()  
                                                this.editmode = false;
                                        }
                                        else{
                                                Swal.fire({
                                                    icon    : 'error',
                                                    title   : response.data.message,
                                                })
                                        }
                                        this.isDisabled=false;
                                        this.$Progress.finish();
                                    })
                                    .catch(()=>{
                                        Toast.fire({
                                            icon: 'error',
                                            title: 'Gagal! harap isi form dengan benar'
                                        });
                                        this.isDisabled=false;
                                        this.$Progress.finish();  
                                    }) 
                            }
                            else{this.isDisabled=false;}
                        })
                },        
                CekDatatable(){
                        if ( $.fn.DataTable.isDataTable( '#TableKpayment' ) ) {
                            $('#TableKpayment').dataTable().fnDestroy();
                        }  
                },
                MakeDatatable(){
                        var tabledata = $("#TableKpayment").DataTable({
                            destroy     : true,
                            dom         : "",
                            ordering    : false,
                            paging      : false,
                            responsive  : false,     
                        });  
                },                 
                DeleteKPayment(details){
                    var Tnominal_kpayment = details.kpaymentd_nominal.toLocaleString('en-US')
                    Swal.fire({
                        title: 'Apakah anda Yakin ?',
                        text: "Hapus No.SP "+details.no_sp,
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Hapus',
                        cancelButtonText: 'Close'
                        }).then((result) => {
                                if (result.value) {
                                    let adetails = this.details
                                    console.log(details)
                                    this.details = adetails.filter(adetails => adetails.sp_id != details.sp_id);
                                    this.sp.push({
                                            id_sp               : details.sp_id ,
                                            komisi_ps           : details.kpaymentd_ps,
                                            no_sp               : details.no_sp,
                                            pajak_pesanan       : details.kpaymentd_ppn,
                                            tgl_pesanan         : details.tgl_pesanan,
                                            total_pesanans      : details.total_ps,  
                                            rupiah_fee          : details.kpaymentd_nominal

                                        });
                                    Toast.fire({
                                        icon: 'success',
                                        title: details.no_sp+' Berhasil dihapus'
                                    });
                                }
                                else{}
                            })
                },                 
                DeletePotongan(potongans){
                    Swal.fire({
                        title: 'Apakah anda Yakin ?',
                        text: "Hapus "+potongans.nm_potongan+". Setelah hapus tidak dapat dikembalikan",
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: 'Ya, Hapus',
                        cancelButtonText: 'Close'
                        }).then((result) => {
                                if (result.value) {
                                    let apotongans = this.potongans
                                    this.potongans = apotongans.filter(apotongans => apotongans.id_kpaymentp != potongans.id_kpaymentp);
                                    Toast.fire({
                                        icon: 'success',
                                        title: potongans.nm_potongan+' Berhasil dihapus'
                                    });
                                }
                                else{}
                            })
                },
                LoadPayments(id_kpayment){
                    axios.get('../api/komisipayment/'+id_kpayment).then((res)=>{
                        var header                  = res.data.data[0];
                        this.form.sales_id          = header.sales_id;
                        this.form.nominal_kpayment  = header.nominal_kpayment;
                        let tnmber                  = header.nominal_kpayment*1
                        this.form.Tnominal_kpayment = tnmber.toLocaleString('en-US');
                        this.form.tgl_kpayment      = header.tgl_kpaymentdf;
                        this.form.awalp_kpayment    = header.awalp_kpayment;
                        this.form.akhirp_kpayment   = header.akhirp_kpayment;

                        var dtl                     = res.data.data[1];
                        for(var i=0; i < dtl.length; i++){
                                    this.details.push({
                                                    id_kpaymentd        : dtl[i].id_sp,
                                                    no_sp               : dtl[i].no_sp,
                                                    sp_id               : dtl[i].id_sp ,
                                                    tgl_pesanan         : dtl[i].tgl_pesanan ,
                                                    total_ps            : dtl[i].total_ps,
                                                    kpaymentd_ps        : dtl[i].kpaymentd_ps,
                                                    kpaymentd_ppn       : dtl[i].kpaymentd_ppn,
                                                    rupiah_fee          : dtl[i].rupiah_fee,
                                                    kpaymentd_nominal   : this.NominalKomisi(dtl[i].kpaymentd_ps, dtl[i].kpaymentd_ppn, dtl[i].total_ps, dtl[i].kpaymentd_nominal),  
                                                });
                                    }

                        var pot                     = res.data.data[2];
                        if(pot.length>0){
                                for(var i=0; i < pot.length; i++){
                                    this.potongans.push({
                                                id_kpaymentp        : pot[i].id_kpaymentp,
                                                nm_potongan         : pot[i].nm_potongan,
                                                nominal_potongan    : pot[i].nominal_potongan,
                                    }); 
                                }
                        }
                }).catch((data)=> { Swal.fire("Failed!", data.message, "warning"); });
                },
          },
        mounted() {            
                  //this.loadItems();
          },
        created() {
              this.$Progress.start();
              this.loadAkses();
              this.LoadSales();
              this.$nextTick(() => {
                    if(this.$route.params.id_kpayment != undefined){
                            var id_kpayment           = this.$route.params.id_kpayment;
                            this.editmode = true;
                            this.LoadPayments(id_kpayment);
                    }
                })
              this.$Progress.finish();
          }
      }
  </script>
  